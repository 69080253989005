import * as ActionTypes from '../constants/ActionTypes';
import moment from 'moment';
import _assign from 'lodash/assign';

const initialState = {
	clientSearchResult: null,
	clientDetail: null,
	shouldRefreshClientDetail: false,
	newAddedClient: {
		timestamp: null
	},
	retrivedClient: null,
};

const user = (state = initialState, action) => {
	switch (action.type) {
	case ActionTypes.SEARCH_CLIENT_SUCCESS:
	case ActionTypes.SEARCH_CLIENT_FAILURE:
		return Object.assign({}, state, {
			clientSearchResult: action.payload,
		});
	case ActionTypes.GET_CLIENT_DETAIL_SUCCESS:
	case ActionTypes.GET_CLIENT_DETAIL_FAILURE:
		return Object.assign({}, state, {
			clientDetail: action.payload,
			shouldRefreshClientDetail: false
		});
	case ActionTypes.EDIT_CLIENT_SUCCESS:
		return Object.assign({}, state, {
			shouldRefreshClientDetail: true,
		});
	case ActionTypes.ADD_CLIENT_SUCCESS:
		return Object.assign({}, state, {
			newAddedClient: _assign({timestamp: moment().valueOf()}, action.payload),
			retrivedClient: null,
		});
	case ActionTypes.CLEAR_CLIENT_DETAIL:
		return Object.assign({}, state, {
			clientDetail: null,
		});
	case ActionTypes.CLEAR_SEARCH_CLIENT:
		return Object.assign({}, state, {
			clientSearchResult: null,
		});
	case ActionTypes.RETRIVE_CLIENT_SUCCESS:
	case ActionTypes.RETRIVE_CLIENT_FAILURE:
		return Object.assign({}, state, {
			retrivedClient: action.payload,
		});
	case ActionTypes.CLEAR_RETRIVED_CLIENT:
		return Object.assign({}, state, {
			retrivedClient: null,
		});
	default:
		return state;
	}
};

export default user;
