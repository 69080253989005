import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus} from '@fortawesome/free-solid-svg-icons';
import {Button, Tab, Tabs, Row, Col, Form, Alert} from 'react-bootstrap';
import toaster from 'toasted-notes' ;
import 'toasted-notes/src/styles.css';

import SelectInput from '../components/SelectInput';
import ConfirmNavigationModal from '../components/ConfirmNavigationModal';

import _extend from 'lodash/extend';
import _map from 'lodash/map';

import {
  getClientGroup,
  editClientGroup,
  clearClientGroupDetail,
  addNewClientGroup,
} from '../actions/clientgroup';
import {getAllClientGroup} from '../actions/common';

class ClientGroupMaintenance extends Component {
  constructor(props) {
    super(props);

    this.onAddNewClick = this.onAddNewClick.bind(this);
    this.onFieldChange = this.onFieldChange.bind(this);
    this.onSelectRoles = this.onSelectRoles.bind(this);
    this.onSelectGroups = this.onSelectGroups.bind(this);
    this.onSelectClientGroupId = this.onSelectClientGroupId.bind(this);
    this.onSubmitEditClick = this.onSubmitEditClick.bind(this);
    this.changeTab = this.changeTab.bind(this);
    this.onGetNewClientGroupDetail = this.onGetNewClientGroupDetail.bind(this);

    this.state = {
      currentTab: 'edit',
      selectedClientGroupId: '',
      isFillingForm: false,
      editForm: {
        clientGroupID: '',
        clientGroupName: '',
        clientGroupStatus: ''
      },
      newForm: {
        clientGroupID: '',
        clientGroupName: '',
        clientGroupStatus: 'E'
      }
    };
  }

  componentWillUnmount() {
    this.props.clearClientGroupDetail()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedClientGroupId !== this.state.selectedClientGroupId) {
      if (this.state.selectedClientGroupId) {
        this.props.getClientGroup(this.state.selectedClientGroupId)
      } else {
        this.props.clearClientGroupDetail()
        this.setClientGroupDetail()
        this.setFillingForm(false)
      }
    }

    if (this.props.clientGroupDetail) {
      if(prevProps.clientGroupDetail !== this.props.clientGroupDetail) {
        this.setClientGroupDetail()
      }
    }

    if (prevProps.newAddedClientGroup.timestamp !== this.props.newAddedClientGroup.timestamp) {
      this.props.getAllClientGroup();
      this.clearNewForm()

      toaster.notify(({ onClose }) => (
        <Alert
          variant="success"
          className="alert-msg mb-0"
          dismissible
          onClose={onClose}
        >
          <Alert.Link onClick={this.onGetNewClientGroupDetail}>Click here to review or edit.</Alert.Link>
        </Alert>
      ), {duration: 3000})
    }

    if (!prevProps.shouldRefreshClientGroupDetail && this.props.shouldRefreshClientGroupDetail) {
      this.props.getClientGroup(this.state.selectedClientGroupId)
      this.setFillingForm(false)
    }
  }


  onFieldChange(e) {
    let formName = this.state.currentTab === 'edit' ? 'editForm' : 'newForm'

    this.setState({
      isFillingForm: true,
      [formName]: _extend(this.state[formName], {
        [e.target.name]: e.target.value,
      })
    })
  }

  changeTab(isChangeTab, tabName) {
    if (isChangeTab) {
      const formName = tabName === 'edit' ? 'newForm' : 'editForm'
      this.setState({
        currentTab: tabName,
        isFillingForm: false,
        warningPopUp: false,
        selectedClientGroupId: '',
        [formName]: {
          clientGroupID: '',
          clientGroupName: '',
          clientGroupStatus: 'E'
        },
      })
    } else {
      this.setState({
        warningPopUp: false
      })
    }
  }

  onTabClick(tabName) {
    if(tabName !== this.state.currentTab) {
      if (this.state.isFillingForm) {
        this.setState({
          warningPopUp: true,
        })
      } else {
        const formName = tabName === 'edit' ? 'newForm' : 'editForm'
        this.setState({
          currentTab: tabName,
          selectedClientGroupId: '',
          [formName]: {
            clientGroupID: '',
            clientGroupName: '',
            clientGroupStatus: ''
          },
        })
      }
    }

  }

  onAddNewClick() {
    this.props.addNewClientGroup(this.state.newForm)
  }

  onSubmitEditClick() {
    this.props.editClientGroup(this.state.editForm)
  }

  onSelectRoles(array) {
    let formName = this.state.currentTab === 'edit' ? 'editForm' : 'newForm'
    this.setState({
      isFillingForm: true,
      [formName]: _extend(this.state[formName], {
        roles: _map(array, (r) => r.key),
      })
    })
  }

  onSelectGroups(array) {
    let formName = this.state.currentTab === 'edit' ? 'editForm' : 'newForm'
    this.setState({
      isFillingForm: true,
      [formName]: _extend(this.state[formName], {
        groups: _map(array, (r) => r.key),
      })
    })
  }

  onGetNewClientGroupDetail() {
    this.setState({
      selectedClientGroupId: this.props.newAddedClientGroup.clientGroupID,
      currentTab: 'edit',
      isFillingForm: false
    })
  }

  setFillingForm(bool) {
    this.setState({
      isFillingForm: bool
    })
  }

  clearNewForm() {
    this.setState({
      newForm: {
        clientGroupID: '',
        clientGroupName: '',
        clientGroupStatus: 'E'
      },
      isFillingForm: false,
    })
  }

  onSelectClientGroupId(id) {
    this.setState({
      selectedClientGroupId: id
    })
  }


  setClientGroupDetail() {
    this.setState({
      editForm: {
        "clientGroupID": this.state.selectedClientGroupId ? this.props.clientGroupDetail.clientGroupID : '',
        "clientGroupName": this.state.selectedClientGroupId ? this.props.clientGroupDetail.clientGroupName : '',
        "clientGroupStatus": this.state.selectedClientGroupId ? this.props.clientGroupDetail.clientGroupStatus : ''
      }
    })
  }

  getEditForm() {
    let formName = this.state.currentTab === 'edit' ? 'editForm' : 'newForm'
    let isEdit = this.state.currentTab === 'edit'
    return (
      <div className="pt-3">
        <Row className="form-group">
          <Col xs="3" className="text-right">
            <Form.Label className="col-form-label">Client Group ID</Form.Label>
          </Col>
          <Col xs="7">
            {isEdit ?
              <p className="col-form-label">{this.state.editForm.clientGroupID}</p>:
              <Form.Control
                type="text"
                value={this.state.newForm.clientGroupID}
                name="clientGroupID"
                onChange={(e) => this.onFieldChange(e)}
                maxLength={100}
              />
            }
          </Col>
        </Row>
        <Row className="form-group">
          <Col xs="3" className="text-right">
            <Form.Label className="col-form-label">Client Group Name*</Form.Label>
          </Col>
          <Col xs="7">
            <Form.Control
              type="text"
              value={this.state[formName].clientGroupName}
              name="clientGroupName"
              onChange={(e) => this.onFieldChange(e)}
              maxLength={100}
              autoComplete="off"
            />
          </Col>
        </Row>
        <Row className="form-group">
          <Col xs="3" className="text-right">
            <Form.Label className="col-form-label">Status</Form.Label>
          </Col>
          <Col xs="7">
            <Form.Control
              as="select"
              name="clientGroupStatus"
              value={this.state[formName].clientGroupStatus}
              onChange={(e) => this.onFieldChange(e)}
              disabled={isEdit && !this.props.clientGroupDetail.canChangeStatus}
            >
              <option value="E">Enabled</option>
              <option value="D">Disabled</option>
            </Form.Control>
          </Col>
        </Row>

        <div className="pt-3 text-center">
          <Button
            size="lg"
            onClick={isEdit ? this.onSubmitEditClick : this.onAddNewClick}
            disabled={!this.state[formName].clientGroupName}
          >
            {isEdit ? 'Submit Edit' : 'Add New Client Group'}
          </Button>
        </div>
      </div>
    )
  }

  render () {
    return (
      <div>
        <div className="text-center">
          <h3>Client Group maintenance</h3>
        </div>

        <Row className="justify-content-center mb-5">
          <Col lg="10">
            <Tabs activeKey={this.state.currentTab} id="user-maintenance-tab" onSelect={(k) => this.onTabClick(k)}>
              <Tab eventKey="edit" title="Edit" className="p-3">
                <Row className="border-bottom pb-3">
                  <Col xs="3" className="text-right">
                    <Form.Label className="font-weight-bold col-form-label">Select Client Group To Edit</Form.Label>
                  </Col>
                  <Col xs="7">
                    <SelectInput
                      preValue={this.state.selectedClientGroupId}
                      handleSelect={this.onSelectClientGroupId}
                      handleFetchOptions={this.props.getAllClientGroup}
                      options={this.props.clientGroupList}
                      optionLabel="displayValue"
                      optionValue="key"
                      isClearable
                    />
                  </Col>
                  <Col xs="2">
                    <Button variant="secondary" className="btn-block" disabled={!this.state.selectedClientGroupId} onClick={() => this.onSelectClientGroupId('')}>Cancel</Button>
                  </Col>
                </Row>

                {!this.props.clientGroupDetail &&
                  <div className="no-result" />
                }

                {this.state.editForm.clientGroupID &&
                  this.getEditForm()
                }
              </Tab>

              <Tab eventKey="add-new" title={<div><FontAwesomeIcon icon={faPlus}/> Add New</div>} className="p-3">
                {this.state.currentTab === 'add-new' &&
                  this.getEditForm()
                }
              </Tab>
            </Tabs>
          </Col>
        </Row>

        <ConfirmNavigationModal
          show={this.state.warningPopUp}
          handleCloseTab={this.changeTab}
          currentTab={this.state.currentTab}
        />
      </div>
    );
  }
}


ClientGroupMaintenance.propTypes = {
	getAllClientGroup: PropTypes.func,
	getClientGroup: PropTypes.func,
	clearClientGroupDetail: PropTypes.func,
	editClientGroup: PropTypes.func,
	addNewClientGroup: PropTypes.func,
	shouldRefreshClientGroupDetail: PropTypes.bool,
	newAddedClientGroup: PropTypes.object,
};

function mapStateToProps(state) {
	return {
    clientGroupList: state.common.clientGroupList,
    clientGroupDetail: state.clientgroup.clientGroupDetail,
    roleList: state.role.roleList,
    groupList: state.common.groupList,
    shouldRefreshClientGroupDetail: state.clientgroup.shouldRefreshClientGroupDetail,
    newAddedClientGroup: state.clientgroup.newAddedClientGroup,
	}
}

const mapDispatchToProps = {
  getAllClientGroup,
  getClientGroup,
  clearClientGroupDetail,
  editClientGroup,
  addNewClientGroup,
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientGroupMaintenance);
