import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

import _find from 'lodash/find';

class SelectInput extends Component {
	constructor(props) {
		super(props);
		this.onSelectClick = this.onSelectClick.bind(this);
		this.onFocus = this.onFocus.bind(this);

		this.state = {
			currentObj: '',
			isLoading: false
		};
	}

	componentDidMount() {
		this.setCurrentArray()
	}

	componentDidUpdate(prevProps) {
		if (prevProps.preValue !== this.props.preValue) {
			this.setCurrentArray()
		}
		if (!prevProps.options && this.props.options) {
			this.setLoading(false)
			this.setCurrentArray()
		}
	}

	setCurrentArray() {
		if (this.props.preValue) {
			if (this.props.preDisplayValue) {
				this.setState({
					currentObj: {
						displayValue: this.props.preDisplayValue,
						key: this.props.preValue
					}
				})
			} else {
				this.setState({
					currentObj: _find(this.props.options, {key: this.props.preValue}) || ''
				})
			}
		} else {
			this.setState({
				currentObj: ''
			})
		}
	}

	onSelectClick(selectedOption) {
		if (selectedOption) {
			this.setState({
				currentObj: selectedOption
			})
			this.props.handleSelect(selectedOption[this.props.optionValue], this.props.name)
		} else {
			this.clearLoginOptions()
		}
	}

	onFocus() {
		if(!this.props.options) {
			this.setLoading(true)
			this.props.handleFetchOptions()
		}
	}

	setLoading(bool) {
		this.setState({
			isLoading: bool
		})
	}

	clearLoginOptions() {
		this.setState({
			currentObj: ''
		})
		this.props.handleSelect('', this.props.name)
	}

  render() {
		return (
			<Select
				value={this.state.currentObj}
				onChange={this.onSelectClick}
				onFocus={this.onFocus}
				isLoading={this.state.isLoading}
				options={this.props.options || []}
				placeholder=""
				isClearable={this.props.isClearable}
				getOptionLabel={option => option[this.props.optionLabel]}
				getOptionValue={option => option[this.props.optionValue]}
				name={this.props.name}
				isDisabled={this.props.disabled}
				isSearchable={this.props.isSearchable}
				styles={{
					control: (provided, state) => ({
						...provided,
						borderColor: this.props.isInValid ? 'red' : '#ced4da',
						borderRadius: 0
				})}}
			/>
    );
  }
}
SelectInput.defaultProps = {
	isSearchable: true
}

SelectInput.propTypes = {
	handleSelect: PropTypes.func,
	handleFetchOptions: PropTypes.func,
	options: PropTypes.array,
	optionLabel: PropTypes.string,
	optionValue: PropTypes.string,
	preValue: PropTypes.string,
	preDisplayValue: PropTypes.string,
	name: PropTypes.string,
	isClearable: PropTypes.bool,
	isDisabled: PropTypes.bool,
	isInValid: PropTypes.bool,
	isSearchable: PropTypes.bool
}

export default SelectInput;
