import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus, faSearch, faLongArrowAltLeft, faTimes} from '@fortawesome/free-solid-svg-icons';
import {Button, Tab, Tabs, Row, Col, Form, Alert, Modal} from 'react-bootstrap';
import toaster from 'toasted-notes' ;
import 'toasted-notes/src/styles.css';

import SelectInput from '../components/SelectInput';
import MultiSelectInput from '../components/MultiSelectInput';
import Pagination from '../components/Pagination';
import ConfirmNavigationModal from '../components/ConfirmNavigationModal';

import _extend from 'lodash/extend';
import _map from 'lodash/map';
import _get from 'lodash/get';
import _ceil from 'lodash/ceil';
import _find from 'lodash/find';

import {
  searchClient,
  clearSearchClient,
  getClientDetail,
  clearClientDetail,
  editClient,
  addNewClient,
  retriveClient,
  clearRetrivedClient,
} from '../actions/client';
import {addNewClientGroup} from '../actions/clientgroup';
import {getRoles} from '../actions/role';
import {getDebtorCode, getClientName, getSource, getAllClientGroup} from '../actions/common';

import {month} from '../util/constances.js';
const pageSize = 10

class ClientMaintenance extends Component {
  constructor(props) {
    super(props);

    this.onAddNewClick = this.onAddNewClick.bind(this);
    this.onFieldChange = this.onFieldChange.bind(this);
    this.onSelectDebtorCode = this.onSelectDebtorCode.bind(this);
    this.onSelectClientName = this.onSelectClientName.bind(this);
    this.onSelectSource = this.onSelectSource.bind(this);
    this.onSubmitEditClick = this.onSubmitEditClick.bind(this);
    this.changeTab = this.changeTab.bind(this);
    this.onGetNewClientDetail = this.onGetNewClientDetail.bind(this);
    this.onSearchClick = this.onSearchClick.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.onCancelClick = this.onCancelClick.bind(this);
    this.onDetailClick = this.onDetailClick.bind(this);
    this.onRetriveClick = this.onRetriveClick.bind(this);
    this.onSelectPermissionRoles = this.onSelectPermissionRoles.bind(this);
    this.onSelectClientGroup = this.onSelectClientGroup.bind(this);
    this.toggleAddNewClientGroup = this.toggleAddNewClientGroup.bind(this);
    this.onAddNewGroupClientClick = this.onAddNewGroupClientClick.bind(this);
    this.onClientGroupFieldChange = this.onClientGroupFieldChange.bind(this);

    this.state = {
      currentTab: 'edit',
      isFillingForm: false,
      editForm: {
        "clientID": '',
        "sourceSystem": '',
        "debtorCode": '',
        "clientStatus": 'E',
        "finYearMonth": 1,
        "clientName": '',
        "clientGroup": '',
        "permissionRoles": []
      },
      newForm: {
        "clientID": '',
        "sourceSystem": '',
        "debtorCode": '',
        "clientStatus": 'E',
        "finYearMonth": 1,
        "clientName": '',
        "clientGroup": '',
        "permissionRoles": []
      },
      selectedSource: '',
      selectedDebtorCode: '',
      selectedClientName: '',
      currentPage: 0,
      isOpenAddModal: false,
      newClientGroup: {
        clientGroupID: '',
        clientGroupName: '',
        clientGroupStatus: 'E'
      }
    };
  }

  componentWillUnmount() {
    this.props.clearSearchClient()
    this.props.clearClientDetail()
    this.props.clearRetrivedClient()
  }

  componentDidUpdate(prevProps, prevState) {
    if(
      prevProps.clientDetail !== this.props.clientDetail ||
      prevProps.retrivedClient !== this.props.retrivedClient
    ) {
      this.setClientDetail()
    }

    if (!prevProps.shouldRefreshClientDetail && this.props.shouldRefreshClientDetail) {
      this.props.getClientDetail(`${this.props.clientDetail.sourceSystem}-${ this.props.clientDetail.debtorCode}`)
      this.setFillingForm(false)
    }

    if (prevProps.newAddedClient.timestamp !== this.props.newAddedClient.timestamp) {
      // this.props.getRoles();
      this.setFillingForm(false)

      toaster.notify(({ onClose }) => (
        <Alert
          variant="success"
          className="alert-msg mb-0"
          dismissible
          onClose={onClose}
        >
          <Alert.Link onClick={this.onGetNewClientDetail}>Click here to review or edit.</Alert.Link>
        </Alert>
      ), {duration: 3000})
    }

    if (prevProps.newAddedClientGroup.timestamp !== this.props.newAddedClientGroup.timestamp) {
      this.props.getAllClientGroup();
      this.toggleAddNewClientGroup()

      setTimeout(function(){
        this.onSelectClientGroup(this.props.newAddedClientGroup.clientGroupID)
      }.bind(this), 500);
    }
  }

  onFieldChange(e) {
    let formName = this.state.currentTab === 'edit' ? 'editForm' : 'newForm'

    this.setState({
      isFillingForm: true,
      [formName]: _extend(this.state[formName], {
        [e.target.name]: e.target.value,
      })
    })
  }

  onClientGroupFieldChange(e) {

    this.setState({
      newClientGroup: _extend(this.state.newClientGroup, {
        [e.target.name]: e.target.value,
      })
    })
  }

  onSelectDebtorCode(id) {
    this.setState({
      selectedDebtorCode: id
    })
  }

  onSelectClientName(id) {
    this.setState({
      selectedClientName: id
    })
  }

  onSelectSource(id) {
    this.setState({
      selectedSource: id
    })
  }

  onDebtorCodeChange(e) {
    this.setState({
      selectedDebtorCode: e.target.value
    })
  }

  onSelectClientGroup(id) {
    let formName = this.state.currentTab === 'edit' ? 'editForm' : 'newForm'

    this.setState({
      isFillingForm: true,
      [formName]: _extend(this.state[formName], {
        clientGroup: id,
      })
    })
  }

  onSelectPermissionRoles(array) {
    let formName = this.state.currentTab === 'edit' ? 'editForm' : 'newForm'

    this.setState({
      isFillingForm: true,
      [formName]: _extend(this.state[formName], {
        permissionRoles: _map(array, (r) => r.key),
      })
    })
  }

  onAddNewClick() {
    if(this.state.newForm){
      this.setState({
        newForm: _extend(this.state.newForm, {
          finYearMonth: parseInt(this.state.newForm.finYearMonth),
        })
      })
    }
    this.props.addNewClient(this.state.newForm)
  }

  onAddNewGroupClientClick() {
    //console.log('onAddNewGroupClientClick')
    this.props.addNewClientGroup(this.state.newClientGroup)
  }

  onGetNewClientDetail() {
    this.setState({
      currentTab: 'edit',
      selectedSource: '',
      selectedDebtorCode: '',
      isFillingForm: false
    })
    this.props.getClientDetail(this.props.newAddedClient.clientID)
  }

  onSubmitEditClick() {
    if(this.state.editForm){
      this.setState({
        editForm: _extend(this.state.editForm, {
          finYearMonth: parseInt(this.state.editForm.finYearMonth),
        })
      })
    }
    this.props.editClient(this.state.editForm)
  }

  onSearchClick(page) {
    this.props.searchClient(
      this.state.selectedDebtorCode,
      this.state.selectedClientName,
      this.state.selectedSource,
      pageSize,
      page
    )

    this.setState({
      currentPage: page
    })
  }

  onPageChange(page) {
    this.onSearchClick(page)
  }

  onCancelClick() {
    this.setState({
      selectedDebtorCode: '',
      selectedClientName: '',
      selectedSource: '',
      currentPage: 0,
      isFillingForm: false,
    })
    this.props.clearSearchClient()
  }

  onDetailClick(source, debtorCode) {
    this.props.getClientDetail(`${source}-${debtorCode}`)
  }

  onRetriveClick(source, debtorCode) {
    this.props.clearRetrivedClient()

    this.props.retriveClient(source, debtorCode)
  }

  onBackClick() {
    if (this.state.currentTab === 'add-new') {
      this.props.clearRetrivedClient()
      this.setFillingForm(false)
    } else {
      this.props.clearClientDetail()
      this.setFillingForm(false)
    }
  }

  onTabClick(tabName) {
    if(tabName !== this.state.currentTab) {
      if (this.state.isFillingForm) {
        this.setState({
          warningPopUp: true,
        })
      } else {
        const formName = tabName === 'edit' ? 'newForm' : 'editForm'
        this.setState({
          currentTab: tabName,
          selectedDebtorCode: '',
          selectedClientName: '',
          selectedSource: '',
          [formName]: {
            "clientID": '',
            "sourceSystem": '',
            "debtorCode": '',
            "clientStatus": 'E',
            "finYearMonth": 1,
            "clientName": '',
            "clientGroup": '',
            "permissionRoles": []
          },
        })
        if (this.props.clientSearchResult) {
          this.props.clearSearchClient()
        }
        if (this.props.clientDetail) {
          this.props.clearClientDetail()
        }
        if (this.props.retrivedClient) {
          this.props.clearRetrivedClient()
        }
      }
    }
  }

  onClearData(name) {
    this.setState({
      [name]: ''
    })
  }

  onKeyDown(e) {
    if (e.key === 'Enter' &&
    this.state.selectedSource &&
    this.state.selectedDebtorCode) {
      this.onRetriveClick()
    }
  }

  toggleAddNewClientGroup() {
    if (this.state.isOpenAddModal) {
      this.setState({
        isOpenAddModal: !this.state.isOpenAddModal,
        newClientGroup: {
          clientGroupID: '',
          clientGroupName: '',
          clientGroupStatus: 'E'
        }
      })
    } else {
      this.setState({
       isOpenAddModal: !this.state.isOpenAddModal
     })
    }
  }

  changeTab(isChangeTab, tabName) {
    if (isChangeTab) {
      const formName = tabName === 'edit' ? 'newForm' : 'editForm'
      this.setState({
        currentTab: tabName,
        isFillingForm: false,
        warningPopUp: false,
        selectedDebtorCode: '',
        selectedClientName: '',
        selectedSource: '',
        [formName]: {
          "clientID": '',
          "sourceSystem": '',
          "debtorCode": '',
          "clientStatus": 'E',
          "finYearMonth": 1,
          "clientName": '',
          "clientGroup": '',
          "permissionRoles": []
        },
      })


      if(this.props.clientDetail) {
        this.props.clearClientDetail()
      }
      if(this.props.retrivedClient) {
        this.props.clearRetrivedClient()
      }
    } else {
      this.setState({
        warningPopUp: false
      })
    }
  }

  setFillingForm(bool) {
    this.setState({
      isFillingForm: bool
    })
  }

  setClientDetail() {
    let isEdit = this.state.currentTab === 'edit'
    if (isEdit) {
      this.setState({
        editForm: {
          "clientID": this.props.clientDetail ? this.props.clientDetail.clientID : '',
          "sourceSystem": this.props.clientDetail ? this.props.clientDetail.sourceSystem : '',
          "debtorCode": this.props.clientDetail ? this.props.clientDetail.debtorCode : '',
          "clientName": this.props.clientDetail ? this.props.clientDetail.clientName : '',
          "finYearMonth": this.props.clientDetail ? this.props.clientDetail.finYearMonth : 1,
          "clientStatus": this.props.clientDetail ? this.props.clientDetail.clientStatus : 'E',
          "clientGroup": this.props.clientDetail ? this.props.clientDetail.clientGroup : '',
          "permissionRoles": this.props.clientDetail ? _map(this.props.clientDetail.permissionRoles, (role) => role.key) : []
        }
      })
    } else {
      this.setState({
        newForm: _extend(this.state.newForm, {
          "clientID": this.props.retrivedClient ? this.state.selectedSource + '-' + this.state.selectedDebtorCode : '',
          "sourceSystem": this.props.retrivedClient ? this.state.selectedSource : '',
          "debtorCode": this.props.retrivedClient ? this.state.selectedDebtorCode : '',
          "clientName": this.props.retrivedClient ? this.props.retrivedClient : '',
          "finYearMonth": 1,
          "clientStatus": 'E',
          "clientGroup": '',
          "permissionRoles": []
        }),
      })
    }
  }

  getResult() {
    return (
      <div className="pt-5">
        <h5>Result</h5>
        <table className="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Client Name</th>
              <th scope="col">Debtor Code</th>
              <th scope="col">Source</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {this.props.clientSearchResult.recordCount > 0 ?
              _map(this.props.clientSearchResult.data, (result, i) =>
                <tr key={i}>
                  <th scope="row">{this.state.currentPage === 0 ? i + 1 : (i + 1) + (pageSize * this.state.currentPage)}</th>
                  <td>{result.clientName}</td>
                  <td>{result.debtorCode}</td>
                  <td>{result.sourceSystem}</td>
                  <td className="text-center">
                    <Button variant="outline-secondary" onClick={() => this.onDetailClick(result.sourceSystem, result.debtorCode)}>Edit detail</Button>
                  </td>
                </tr>
              ):
              <tr>
                <td colSpan="5" className="p-5 text-center border-bottom"><div>No records.</div></td>
              </tr>
            }
          </tbody>
        </table>
        {this.props.clientSearchResult.recordCount > pageSize &&
          <Pagination
            noOfPages={_ceil(this.props.clientSearchResult.recordCount / pageSize)}
            currentPage={this.state.currentPage}
            handlePageChange={this.onPageChange}
          />
        }
      </div>
    )
  }

  getEditForm() {
    let formName = this.state.currentTab === 'edit' ? 'editForm' : 'newForm'
    let isEdit = this.state.currentTab === 'edit'
    return (
      <div>
        <Row className="form-group">
          <Col xs="3" className="text-right">
            <Form.Label className="col-form-label">Source</Form.Label>
          </Col>
          <Col xs="7">
            <p className="col-form-label">{this.state[formName].sourceSystem}</p>
          </Col>
        </Row>

        <Row className="form-group">
          <Col xs="3" className="text-right">
            <Form.Label className="col-form-label">Debtor Code</Form.Label>
          </Col>
          <Col xs="7">
            <p className="col-form-label">{this.state[formName].debtorCode}</p>
          </Col>
        </Row>

        <Row className="form-group">
          <Col xs="3" className="text-right">
            <Form.Label className="col-form-label">Client Name*</Form.Label>
          </Col>
          <Col xs="7">
            <Form.Control
              type="text"
              value={this.state[formName].clientName}
              name="clientName"
              isInvalid={isEdit && !this.state[formName].clientName}
              onChange={(e) => this.onFieldChange(e)}
              maxLength={100}
            />
          </Col>
        </Row>

        <Row className="form-group">
          <Col xs="3" className="text-right">
            <Form.Label className="col-form-label">Financial Year Month</Form.Label>
          </Col>
          <Col xs="7">
            <Form.Control
              as="select"
              name="finYearMonth"
              value={this.state[formName].finYearMonth}
              onChange={(e) => this.onFieldChange(e)}
            >
              {_map(month, m =>
                <option value={m.value} key={m.value}>{m.name}</option>
              )}
            </Form.Control>
          </Col>
        </Row>

        <Row className="form-group">
          <Col xs="3" className="text-right">
            <Form.Label className="col-form-label">Status</Form.Label>
          </Col>
          <Col xs="7">
            <Form.Control
              as="select"
              name="clientStatus"
              value={this.state[formName].clientStatus}
              onChange={(e) => this.onFieldChange(e)}
            >
              <option value="E">Enabled</option>
              <option value="D">Disabled</option>
            </Form.Control>
          </Col>
        </Row>

        <Row className="form-group">
          <Col xs="3" className="text-right">
            <Form.Label className="col-form-label">Client Group{!isEdit && '*'}</Form.Label>
          </Col>
          <Col xs="6">
            <SelectInput
              preValue={isEdit ? _get(this.props.clientDetail, 'clientGroup') : this.state.newForm.clientGroup}
              preDisplayValue={isEdit ? _get(this.props.clientDetail, 'clientGroupName') : ''}
              handleSelect={this.onSelectClientGroup}
              handleFetchOptions={this.props.getAllClientGroup}
              options={this.props.clientGroupList}
              optionLabel="displayValue"
              optionValue="key"
            />
          </Col>
          <Col xs="auto" className="p-0">
            <Button size="sm" className="rounded-circle mt-1" variant="outline-secondary" onClick={this.toggleAddNewClientGroup}><FontAwesomeIcon icon={faPlus} /></Button>
          </Col>
        </Row>

        {isEdit &&
          <Row className="form-group">
            <Col xs="3" className="text-right">
              <Form.Label className="col-form-label">Group Members</Form.Label>
            </Col>
            <Col xs="7 col-form-label">
              {_map(this.props.clientDetail.groupMembers, (member, i) =>
                <div key={i}>{member}</div>
              )}
            </Col>
          </Row>
        }

        <Row className="form-group">
          <Col xs="3" className="text-right">
            <Form.Label className="col-form-label">Roles to have permission</Form.Label>
          </Col>
          <Col xs="7">
            <MultiSelectInput
              handleSelect={this.onSelectPermissionRoles}
              handleFetchOptions={this.props.getRoles}
              options={this.props.roleList}
              preValue={isEdit ? this.props.clientDetail.permissionRoles : _map(this.state.newForm.permissionRoles, (role) => (_find(this.props.roleList, {'key': role})))}
              optionLabel="displayValue"
              optionValue="key"
            />
          </Col>
        </Row>

        <div className="pt-3 text-center">

          <Button size="lg" className="mr-3" variant="outline-secondary" onClick={() => this.onBackClick()}><FontAwesomeIcon icon={faLongArrowAltLeft}/> Back</Button>

          <Button
            size="lg"
            onClick={isEdit ? this.onSubmitEditClick : this.onAddNewClick}
            disabled={isEdit ? !this.state.editForm.clientName : !this.state.newForm.clientName || !this.state.newForm.clientGroup}
          >
            {isEdit ? 'Submit Edit' : 'Add New Client'}
          </Button>
        </div>
      </div>
    )
  }

  render () {
    return (
      <div>
        <div className="text-center">
          <h3>Client maintenance</h3>
        </div>
        <Row className="justify-content-center mb-5">
          <Col lg="10">
            <Tabs activeKey={this.state.currentTab} id="client-maintenance-tab" onSelect={(k) => this.onTabClick(k)}>
              <Tab eventKey="edit" title="Edit" className="p-3">
                {!this.props.clientDetail &&
                  <div>
                    <Row className="form-group ">
                      <Col xs="3" className="text-right">
                        <Form.Label className="font-weight-bold col-form-label">Source</Form.Label>
                      </Col>
                      <Col xs="7">
                        <SelectInput
                          preValue={this.state.selectedSource}
                          handleSelect={this.onSelectSource}
                          handleFetchOptions={this.props.getSource}
                          options={this.props.sourceList}
                          optionLabel="displayValue"
                          optionValue="key"
                          isClearable
                        />
                      </Col>
                    </Row>
                    <Row className="form-group ">
                      <Col xs="3" className="text-right">
                        <Form.Label className="font-weight-bold col-form-label">Debtor Code</Form.Label>
                      </Col>
                      <Col xs="7">
                        <SelectInput
                          preValue={this.state.selectedDebtorCode}
                          handleSelect={this.onSelectDebtorCode}
                          handleFetchOptions={this.props.getDebtorCode}
                          options={this.props.debtorCodeList}
                          optionLabel="displayValue"
                          optionValue="key"
                          isClearable
                        />
                      </Col>
                    </Row>
                    <Row className="form-group ">
                      <Col xs="3" className="text-right">
                        <Form.Label className="font-weight-bold col-form-label">Client Name</Form.Label>
                      </Col>
                      <Col xs="7">
                        <SelectInput
                          preValue={this.state.selectedClientName}
                          handleSelect={this.onSelectClientName}
                          handleFetchOptions={this.props.getClientName}
                          options={this.props.clientNameList}
                          optionLabel="displayValue"
                          optionValue="key"
                          isClearable
                        />
                      </Col>
                    </Row>

                    <Row className="pb-3 border-bottom">
                      <Col className="text-center">
                        <Button
                          variant="secondary"
                          className="mr-2"
                          onClick={() => this.onCancelClick()}
                          disabled={!this.props.clientSearchResult}
                        >Cancel</Button>
                        <Button variant="primary" onClick={() => this.onSearchClick(0)}><FontAwesomeIcon icon={faSearch} /> Search</Button>
                      </Col>
                    </Row>

                    {this.props.clientSearchResult ?
                      this.getResult() :
                      <div className="no-result" />
                    }
                  </div>
                }

                {this.props.clientDetail &&
                  this.getEditForm()
                }
              </Tab>


              <Tab eventKey="add-new" title={<div><FontAwesomeIcon icon={faPlus} /> Add New</div>} className="p-3">
                {!this.props.clientDetail &&
                  <div onKeyDown={(e) => this.onKeyDown(e)}>
                    <Row className="form-group ">
                      <Col xs="3" className="text-right">
                        <Form.Label className="font-weight-bold col-form-label">Source</Form.Label>
                      </Col>
                      <Col xs="7">
                        <SelectInput
                          preValue={this.state.selectedSource}
                          handleSelect={this.onSelectSource}
                          handleFetchOptions={this.props.getSource}
                          options={this.props.sourceList}
                          optionLabel="displayValue"
                          optionValue="key"
                          isClearable
                        />
                      </Col>
                    </Row>
                    <Row className="form-group pb-3 border-bottom">
                      <Col xs="3" className="text-right">
                        <Form.Label className="font-weight-bold col-form-label">Debtor Code</Form.Label>
                      </Col>
                      <Col xs="5">
                        <div className="relative">
                          <Form.Control
                            type="text"
                            value={this.state.selectedDebtorCode}
                            name="selectedDebtorCode"
                            onChange={(e) => this.onDebtorCodeChange(e)}
                          />
                          {this.state.selectedDebtorCode && <button type="button" onClick={() => this.onClearData("selectedDebtorCode")} className="btn date-range__cancel-icon"><FontAwesomeIcon icon={faTimes} size="sm" /></button>}
                        </div>
                      </Col>
                      <Col xs="2">
                        <Button variant="primary"
                          onClick={() => this.onRetriveClick(this.state.selectedSource, this.state.selectedDebtorCode)}
                          className="btn-block"
                          disabled={!this.state.selectedSource || !this.state.selectedDebtorCode}
                        ><FontAwesomeIcon icon={faSearch} /> Retrive</Button>
                      </Col>
                    </Row>
                  </div>
                }

                {
                  this.state.newForm.clientID ?
                    this.getEditForm() :
                    <div className="no-result" />
                }
              </Tab>
            </Tabs>
          </Col>
        </Row>

        <ConfirmNavigationModal
          show={this.state.warningPopUp}
          handleCloseTab={this.changeTab}
          currentTab={this.state.currentTab}
        />


        <Modal centered show={this.state.isOpenAddModal} onHide={() => this.toggleAddNewClientGroup()} >
    			<Modal.Header>
    				<Modal.Title>Add Client Group</Modal.Title>
    			</Modal.Header>
    			<Modal.Body>
            <div className="pt-3">
              <Row className="form-group">
                <Col xs="5" className="text-right">
                  <Form.Label className="col-form-label">Client Group ID</Form.Label>
                </Col>
                <Col xs="7">
                  <Form.Control
                    type="text"
                    value={this.state.newClientGroup.clientGroupID}
                    name="clientGroupID"
                    onChange={(e) => this.onClientGroupFieldChange(e)}
                    maxLength={20}
                  />
                </Col>
              </Row>
              <Row className="form-group">
                <Col xs="5" className="text-right">
                  <Form.Label className="col-form-label">Client Group Name*</Form.Label>
                </Col>
                <Col xs="7">
                  <Form.Control
                    type="text"
                    value={this.state.newClientGroup.clientGroupName}
                    name="clientGroupName"
                    onChange={(e) => this.onClientGroupFieldChange(e)}
                    maxLength={100}
                    autoComplete="off"
                  />
                </Col>
              </Row>
              <Row className="form-group">
                <Col xs="5" className="text-right">
                  <Form.Label className="col-form-label">Status</Form.Label>
                </Col>
                <Col xs="7">
                  <Form.Control
                    as="select"
                    name="clientGroupStatus"
                    value={this.state.newClientGroup.clientGroupStatus}
                    onChange={(e) => this.onClientGroupFieldChange(e)}
                  >
                    <option value="E">Enabled</option>
                    <option value="D">Disabled</option>
                  </Form.Control>
                </Col>
              </Row>

            </div>
    			</Modal.Body>
    			<Modal.Footer>
            <div className="text-center w-100">
              <Button
                size="lg"
                onClick={this.onAddNewGroupClientClick}
                disabled={!this.state.newClientGroup.clientGroupName}
              >
                Add New Client Group
              </Button>
            </div>
    			</Modal.Footer>
    		</Modal>
      </div>
    );
  }
}

ClientMaintenance.propTypes = {
	getDebtorCode: PropTypes.func,
	getClientName: PropTypes.func,
	getSource: PropTypes.func,
	searchClient: PropTypes.func,
	clearSearchClient: PropTypes.func,
	getClientDetail: PropTypes.func,
	clearClientDetail: PropTypes.func,
	getRoles: PropTypes.func,
	editClient: PropTypes.func,
	getAllClientGroup: PropTypes.func,
	addNewClient: PropTypes.func,
	retriveClient: PropTypes.func,
	clearRetrivedClient: PropTypes.func,
	addNewClientGroup: PropTypes.func,
	newAddedClientGroup: PropTypes.object,
};

function mapStateToProps(state) {
	return {
    debtorCodeList: state.common.debtorCodeList,
    clientNameList: state.common.clientNameList,
    sourceList: state.common.sourceList,
    clientGroupList: state.common.clientGroupList,
    roleList: state.role.roleList,
    clientSearchResult: state.client.clientSearchResult,
    clientDetail: state.client.clientDetail,
    shouldRefreshClientDetail: state.client.shouldRefreshClientDetail,
    newAddedClient: state.client.newAddedClient,
    retrivedClient: state.client.retrivedClient,
    newAddedClientGroup: state.clientgroup.newAddedClientGroup,
	}
}

const mapDispatchToProps = {
  getDebtorCode,
  getClientName,
  getSource,
  searchClient,
  clearSearchClient,
  getClientDetail,
  clearClientDetail,
  getRoles,
  editClient,
  getAllClientGroup,
  addNewClient,
  retriveClient,
  clearRetrivedClient,
  addNewClientGroup,
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientMaintenance);
