import * as ActionTypes from '../constants/ActionTypes';

const initialState = {
	reportTypeList: null,
	reportParams: null,
	securityClientGroupList: null,
	securityClientList: null,
	reportViwer: null
};

const user = (state = initialState, action) => {
	switch (action.type) {
	case ActionTypes.GET_REPORT_LIST_SUCCESS:
		return Object.assign({}, state, {
			reportTypeList: action.payload,
		});
	case ActionTypes.GET_REPORT_PARAM_SUCCESS:
		return Object.assign({}, state, {
			reportParams: action.payload,
		});
	case ActionTypes.GET_SECURITY_CLIENT_GROUP_SUCCESS:
		return Object.assign({}, state, {
			securityClientGroupList: action.payload,
		});
	case ActionTypes.GET_SECURITY_CLIENT_SUCCESS:
		return Object.assign({}, state, {
			securityClientList: action.payload,
		});
	case ActionTypes.GET_REPORT_VIEWER_SUCCESS:
		return Object.assign({}, state, {
			reportViwer: action.payload,
		});
	case ActionTypes.CLEAR_REPORT_PARAM:
		return Object.assign({}, state, {
			// reportTypeList: null,
			reportParams: null,
			securityClientGroupList: null,
			securityClientList: null,
		});
	default:
		return state;
	}
};

export default user;
