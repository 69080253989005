import React, {Component} from 'react';
import PropTypes from 'prop-types';
import CreatableSelect from 'react-select/creatable';

import _map from 'lodash/map';
import {isEmail} from '../util/validation';

const components = {
  DropdownIndicator: null
};

const createOption = (label) => ({
  label,
  value: label
});

class EmailInputBox extends Component {
	constructor(props) {
		super(props);
		this.onChange = this.onChange.bind(this);
		this.onInputChange = this.onInputChange.bind(this);
		this.onKeyDown = this.onKeyDown.bind(this);

		this.state = {
			inputValue: '',
	    value: [],
		};
	}

  componentDidMount() {
    if (this.props.preValue) {
			this.setCurrentArray()
		}
  }

	componentDidUpdate(prevProps) {
		if (prevProps.preValue !== this.props.preValue) {
			this.setCurrentArray()
		}
	}

	onChange(value) {
		this.props.handleSelect(value ? _map(value, c => c.value) : [] , this.props.name)
  }

  onInputChange(inputValue) {
    this.setState({ inputValue });
  };

	onKeyDown(event) {
    const { inputValue, value } = this.state;
    if (!inputValue) return;
    if (event.key === "Enter" || event.key === "Tab") {
			if (isEmail(inputValue) && !this.props.preValue.includes(inputValue)) {
	      this.setState({
	        inputValue: "",
	      });

				this.props.handleSelect(_map([...value, createOption(inputValue)], c => c.value), this.props.name)
			}
    }
  };

	setCurrentArray() {
		this.setState({
			value: _map(this.props.preValue, value => ({'label': value, 'value': value})),
		})
	}

  render() {
		return (
			<CreatableSelect
        components={components}
        inputValue={this.state.inputValue}
        isClearable
        isMulti
        menuIsOpen={false}
        onChange={this.onChange}
        onInputChange={this.onInputChange}
        onKeyDown={this.onKeyDown}
        placeholder="Type email and press enter"
				value={this.state.value}
				styles={{
					control: (provided, state) => ({
						...provided,
						borderColor: this.props.isInValid ? 'red' : '#ced4da',
						borderRadius: 0
				})}}
      />
    );
  }
}

EmailInputBox.propTypes = {
	handleSelect: PropTypes.func,
	preValue: PropTypes.array,
	name: PropTypes.string,
	isInValid: PropTypes.bool,
}

export default EmailInputBox;
