import * as ActionTypes from '../constants/ActionTypes';
import moment from 'moment';
import _assign from 'lodash/assign';

const initialState = {
	userList: null,
	userDetail: null,
	shouldRefreshUserDetail: false,
	newAddedUser: {
		timestamp: null
	},
};

const user = (state = initialState, action) => {
	switch (action.type) {
	case ActionTypes.GET_USERS_SUCCESS:
		return Object.assign({}, state, {
			userList: action.payload,
		});
	case ActionTypes.GET_USER_DETAIL_SUCCESS:
		return Object.assign({}, state, {
			userDetail: action.payload,
			shouldRefreshUserDetail: false
		});
	case ActionTypes.EDIT_USER_SUCCESS:
		return Object.assign({}, state, {
			shouldRefreshUserDetail: true,
		});
	case ActionTypes.ADD_USER_SUCCESS:
		return Object.assign({}, state, {
			newAddedUser: _assign({timestamp: moment().valueOf()}, action.payload)
		});
	case ActionTypes.CLEAR_USER_DETAIL:
		return Object.assign({}, state, {
			userDetail: null,
		});
	default:
		return state;
	}
};

export default user;
