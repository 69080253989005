import React from 'react';
import PropTypes from 'prop-types';
import {Button,  Modal} from 'react-bootstrap';

const ConfirmNavigationModal = (props) => {
	return (
		<Modal centered show={props.show} onHide={() => props.handleCloseTab(false)} >
			<Modal.Header>
				<Modal.Title>Confirm Navigation</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<p>You have unsaved changes that will be lost if you decide to continue.</p>
				<p>Are you sure you want to leave this tab?</p>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={() => props.handleCloseTab(false)}>
					Stay on this tab
				</Button>
				<Button variant="primary" onClick={() => props.handleCloseTab(true, props.currentTab === 'edit' ? 'add-new' : 'edit')}>
					Leave this tab
				</Button>
			</Modal.Footer>
		</Modal>
  );
}

ConfirmNavigationModal.propTypes = {
	handleCloseTab: PropTypes.func,
	show: PropTypes.bool,
	currentTab: PropTypes.string,
}

export default ConfirmNavigationModal;
