import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

class MultiSelectInput extends Component {
	constructor(props) {
		super(props);
		this.onSelectClick = this.onSelectClick.bind(this);
		this.onFocus = this.onFocus.bind(this);

		this.state = {
			currentArray: this.props.preValue,
			isLoading: false
		};
	}

	componentDidUpdate(prevProps) {
		if (prevProps.preValue !== this.props.preValue) {
			this.setCurrentArray()
		}
		if (!prevProps.options && this.props.options) {
			this.setLoading(false)
		}
	}

	onFocus() {
		if(!this.props.options) {
			this.setLoading(true)
			this.props.handleFetchOptions()
		}
	}

	setLoading(bool) {
		this.setState({
			isLoading: bool
		})
	}

	setCurrentArray() {
		this.setState({
			currentArray: this.props.preValue
		})
	}

	onSelectClick(selectedOption) {
		if (selectedOption) {
			this.setState({
				currentArray: selectedOption
			})
			this.props.handleSelect(selectedOption)
		} else { // clear
			this.clearLoginOptions()
		}
	}

	clearLoginOptions() {
		this.setState({
			currentArray: []
		});
		this.props.handleSelect([])
	}

  render() {
		return (
			<Select
				value={this.state.currentArray}
				onChange={this.onSelectClick}
				onFocus={this.onFocus}
				isLoading={this.state.isLoading}
				options={this.props.options || []}
				placeholder=""
				isMulti
				closeMenuOnSelect={false}
				isClearable
				getOptionLabel={option => option[this.props.optionLabel]}
				getOptionValue={option => option[this.props.optionValue]}
				name={this.props.name}
				isDisabled={this.props.disabled}
				styles={{
					control: (provided, state) => ({
						...provided,
						borderColor: this.props.isInValid ? 'red' : '#ced4da',
						borderRadius: 0
				})}}
			/>
    );
  }
}

MultiSelectInput.propTypes = {
	handleSelect: PropTypes.func,
	handleFetchOptions: PropTypes.func,
	options: PropTypes.array,
	optionLabel: PropTypes.string,
	optionValue: PropTypes.string,
	preValue: PropTypes.array,
	name: PropTypes.string,
	disabled: PropTypes.bool,
	isInValid: PropTypes.bool,
}

export default MultiSelectInput;
