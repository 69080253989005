// general
export const INIT_REQUEST = 'INIT_REQUEST';
export const INIT_SUCCESS = 'INIT_SUCCESS';
export const INIT_FAILURE = 'INIT_FAILURE';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const FORGET_REQUEST = 'FORGET_REQUEST';
export const FORGET_SUCCESS = 'FORGET_SUCCESS';
export const FORGET_FAILURE = 'FORGET_FAILURE';

export const RESET_REQUEST = 'RESET_REQUEST';
export const RESET_SUCCESS = 'RESET_SUCCESS';
export const RESET_FAILURE = 'RESET_FAILURE';

export const LOG_OUT = 'LOG_OUT';

export const GET_COUNTRY_REQUEST = 'GET_COUNTRY_REQUEST';
export const GET_COUNTRY_SUCCESS = 'GET_COUNTRY_SUCCESS';
export const GET_COUNTRY_FAILURE = 'GET_COUNTRY_FAILURE';

export const GET_GROUPS_REQUEST = 'GET_GROUPS_REQUEST';
export const GET_GROUPS_SUCCESS = 'GET_GROUPS_SUCCESS';
export const GET_GROUPS_FAILURE = 'GET_GROUPS_FAILURE';

export const GET_SOURCE_REQUEST = 'GET_SOURCE_REQUEST';
export const GET_SOURCE_SUCCESS = 'GET_SOURCE_SUCCESS';
export const GET_SOURCE_FAILURE = 'GET_SOURCE_FAILURE';

export const GET_AIRPORT_REQUEST = 'GET_AIRPORT_REQUEST';
export const GET_AIRPORT_SUCCESS = 'GET_AIRPORT_SUCCESS';
export const GET_AIRPORT_FAILURE = 'GET_AIRPORT_FAILURE';

export const GET_CLIENT_NAME_REQUEST = 'GET_CLIENT_NAME_REQUEST';
export const GET_CLIENT_NAME_SUCCESS = 'GET_CLIENT_NAME_SUCCESS';
export const GET_CLIENT_NAME_FAILURE = 'GET_CLIENT_NAME_FAILURE';

export const GET_CLIENT_ID_REQUEST = 'GET_CLIENT_ID_REQUEST';
export const GET_CLIENT_ID_SUCCESS = 'GET_CLIENT_ID_SUCCESS';
export const GET_CLIENT_ID_FAILURE = 'GET_CLIENT_ID_FAILURE';

export const GET_DEBTOR_CODE_REQUEST = 'GET_DEBTOR_CODE_REQUEST';
export const GET_DEBTOR_CODE_SUCCESS = 'GET_DEBTOR_CODE_SUCCESS';
export const GET_DEBTOR_CODE_FAILURE = 'GET_DEBTOR_CODE_FAILURE';

export const GET_ALL_CLIENT_GROUP_REQUEST = 'GET_ALL_CLIENT_GROUP_REQUEST';
export const GET_ALL_CLIENT_GROUP_SUCCESS = 'GET_ALL_CLIENT_GROUP_SUCCESS';
export const GET_ALL_CLIENT_GROUP_FAILURE = 'GET_ALL_CLIENT_GROUP_FAILURE';

// user
export const GET_USERS_REQUEST = 'GET_USERS_REQUEST';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAILURE = 'GET_USERS_FAILURE';

export const GET_USER_DETAIL_REQUEST = 'GET_USER_DETAIL_REQUEST';
export const GET_USER_DETAIL_SUCCESS = 'GET_USER_DETAIL_SUCCESS';
export const GET_USER_DETAIL_FAILURE = 'GET_USER_DETAIL_FAILURE';

export const EDIT_USER_REQUEST = 'EDIT_USER_REQUEST';
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS';
export const EDIT_USER_FAILURE = 'EDIT_USER_FAILURE';

export const CLEAR_USER_DETAIL = 'CLEAR_USER_DETAIL';

export const ADD_USER_REQUEST = 'ADD_USER_REQUEST';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_USER_FAILURE = 'ADD_USER_FAILURE';

//role
export const GET_ROLES_REQUEST = 'GET_ROLES_REQUEST';
export const GET_ROLES_SUCCESS = 'GET_ROLES_SUCCESS';
export const GET_ROLES_FAILURE = 'GET_ROLES_FAILURE';

export const EDIT_ROLE_REQUEST = 'EDIT_ROLE_REQUEST';
export const EDIT_ROLE_SUCCESS = 'EDIT_ROLE_SUCCESS';
export const EDIT_ROLE_FAILURE = 'EDIT_ROLE_FAILURE';

export const GET_ROLE_DETAIL_REQUEST = 'GET_ROLE_DETAIL_REQUEST';
export const GET_ROLE_DETAIL_SUCCESS = 'GET_ROLE_DETAIL_SUCCESS';
export const GET_ROLE_DETAIL_FAILURE = 'GET_ROLE_DETAIL_FAILURE';

export const CLEAR_ROLE_DETAIL = 'CLEAR_ROLE_DETAIL';

export const ADD_ROLE_REQUEST = 'ADD_ROLE_REQUEST';
export const ADD_ROLE_SUCCESS = 'ADD_ROLE_SUCCESS';
export const ADD_ROLE_FAILURE = 'ADD_ROLE_FAILURE';

// client
export const SEARCH_CLIENT_REQUEST = 'SEARCH_CLIENT_REQUEST';
export const SEARCH_CLIENT_SUCCESS = 'SEARCH_CLIENT_SUCCESS';
export const SEARCH_CLIENT_FAILURE = 'SEARCH_CLIENT_FAILURE';


export const GET_CLIENT_DETAIL_REQUEST = 'GET_CLIENT_DETAIL_REQUEST';
export const GET_CLIENT_DETAIL_SUCCESS = 'GET_CLIENT_DETAIL_SUCCESS';
export const GET_CLIENT_DETAIL_FAILURE = 'GET_CLIENT_DETAIL_FAILURE';

export const EDIT_CLIENT_REQUEST = 'EDIT_CLIENT_REQUEST';
export const EDIT_CLIENT_SUCCESS = 'EDIT_CLIENT_SUCCESS';
export const EDIT_CLIENT_FAILURE = 'EDIT_CLIENT_FAILURE';

export const ADD_CLIENT_REQUEST = 'ADD_CLIENT_REQUEST';
export const ADD_CLIENT_SUCCESS = 'ADD_CLIENT_SUCCESS';
export const ADD_CLIENT_FAILURE = 'ADD_CLIENT_FAILURE';

export const RETRIVE_CLIENT_REQUEST = 'RETRIVE_CLIENT_REQUEST';
export const RETRIVE_CLIENT_SUCCESS = 'RETRIVE_CLIENT_SUCCESS';
export const RETRIVE_CLIENT_FAILURE = 'RETRIVE_CLIENT_FAILURE';

export const CLEAR_SEARCH_CLIENT = 'CLEAR_SEARCH_CLIENT';
export const CLEAR_RETRIVED_CLIENT = 'CLEAR_RETRIVED_CLIENT';
export const CLEAR_CLIENT_DETAIL = 'CLEAR_CLIENT_DETAIL';

// client group
export const GET_CLIENT_GROUP_REQUEST = 'GET_CLIENT_GROUP_REQUEST';
export const GET_CLIENT_GROUP_SUCCESS = 'GET_CLIENT_GROUP_SUCCESS';
export const GET_CLIENT_GROUP_FAILURE = 'GET_CLIENT_GROUP_FAILURE';

export const EDIT_CLIENT_GROUP_REQUEST = 'EDIT_CLIENT_GROUP_REQUEST';
export const EDIT_CLIENT_GROUP_SUCCESS = 'EDIT_CLIENT_GROUP_SUCCESS';
export const EDIT_CLIENT_GROUP_FAILURE = 'EDIT_CLIENT_GROUP_FAILURE';

export const ADD_CLIENT_GROUP_REQUEST = 'ADD_CLIENT_GROUP_REQUEST';
export const ADD_CLIENT_GROUP_SUCCESS = 'ADD_CLIENT_GROUP_SUCCESS';
export const ADD_CLIENT_GROUP_FAILURE = 'ADD_CLIENT_GROUP_FAILURE';

export const CLEAR_CLIENT_GROUP_DETAIL = 'CLEAR_CLIENT_GROUP_DETAIL';

// report
export const GET_REPORT_LIST_REQUEST = 'GET_REPORT_LIST_REQUEST';
export const GET_REPORT_LIST_SUCCESS = 'GET_REPORT_LIST_SUCCESS';
export const GET_REPORT_LIST_FAILURE = 'GET_REPORT_LIST_FAILURE';

export const GET_REPORT_PARAM_REQUEST = 'GET_REPORT_PARAM_REQUEST';
export const GET_REPORT_PARAM_SUCCESS = 'GET_REPORT_PARAM_SUCCESS';
export const GET_REPORT_PARAM_FAILURE = 'GET_REPORT_PARAM_FAILURE';

export const GET_SECURITY_CLIENT_GROUP_REQUEST = 'GET_SECURITY_CLIENT_GROUP_REQUEST';
export const GET_SECURITY_CLIENT_GROUP_SUCCESS = 'GET_SECURITY_CLIENT_GROUP_SUCCESS';
export const GET_SECURITY_CLIENT_GROUP_FAILURE = 'GET_SECURITY_CLIENT_GROUP_FAILURE';

export const GET_SECURITY_CLIENT_REQUEST = 'GET_SECURITY_CLIENT_REQUEST';
export const GET_SECURITY_CLIENT_SUCCESS = 'GET_SECURITY_CLIENT_SUCCESS';
export const GET_SECURITY_CLIENT_FAILURE = 'GET_SECURITY_CLIENT_FAILURE';

export const GET_REPORT_VIEWER_REQUEST = 'GET_REPORT_VIEWER_REQUEST';
export const GET_REPORT_VIEWER_SUCCESS = 'GET_REPORT_VIEWER_SUCCESS';
export const GET_REPORT_VIEWER_FAILURE = 'GET_REPORT_VIEWER_FAILURE';
export const CLEAR_REPORT_PARAM = 'CLEAR_REPORT_PARAM';

// schedule
export const SEARCH_SCHEDULE_REQUEST = 'SEARCH_SCHEDULE_REQUEST';
export const SEARCH_SCHEDULE_SUCCESS = 'SEARCH_SCHEDULE_SUCCESS';
export const SEARCH_SCHEDULE_FAILURE = 'SEARCH_SCHEDULE_FAILURE';

export const GET_SCHEDULE_DETAIL_REQUEST = 'GET_SCHEDULE_DETAIL_REQUEST';
export const GET_SCHEDULE_DETAIL_SUCCESS = 'GET_SCHEDULE_DETAIL_SUCCESS';
export const GET_SCHEDULE_DETAIL_FAILURE = 'GET_SCHEDULE_DETAIL_FAILURE';

export const GET_SCHEDULE_OPTIONS_REQUEST = 'GET_SCHEDULE_OPTIONS_REQUEST';
export const GET_SCHEDULE_OPTIONS_SUCCESS = 'GET_SCHEDULE_OPTIONS_SUCCESS';
export const GET_SCHEDULE_OPTIONS_FAILURE = 'GET_SCHEDULE_OPTIONS_FAILURE';

export const EDIT_SCHEDULE_REQUEST = 'EDIT_SCHEDULE_REQUEST';
export const EDIT_SCHEDULE_SUCCESS = 'EDIT_SCHEDULE_SUCCESS';
export const EDIT_SCHEDULE_FAILURE = 'EDIT_SCHEDULE_FAILURE';

export const ADD_SCHEDULE_REQUEST = 'ADD_SCHEDULE_REQUEST';
export const ADD_SCHEDULE_SUCCESS = 'ADD_SCHEDULE_SUCCESS';
export const ADD_SCHEDULE_FAILURE = 'ADD_SCHEDULE_FAILURE';

export const GET_ScheduleType_REQUEST = 'GET_ScheduleType_REQUEST';
export const GET_ScheduleType_SUCCESS = 'GET_ScheduleType_SUCCESS';
export const GET_ScheduleType_FAILURE = 'GET_ScheduleType_FAILURE';

export const CLEAR_SCHEDULE_DETAIL = 'CLEAR_SCHEDULE_DETAIL';
export const CLEAR_SEARCH_SCHEDULE = 'CLEAR_SEARCH_SCHEDULE';

// msg
export const CLEAR_MSG = 'CLEAR_MSG';
export const CLEAR_ALL_MSG = 'CLEAR_ALL_MSG';
