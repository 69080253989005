import fetch from 'node-fetch';
import config from '../config';
import Auth from '../Auth/Auth';
import * as ActionTypes from '../constants/ActionTypes';

const currentConfig = config.development;
const auth = new Auth();

export function getClientGroup(id) {
  return (dispatch) => {
    const endpoint = `${currentConfig.serverURL}/Admin/clientgroup/${id}`;

		dispatch({
			type: ActionTypes.GET_CLIENT_GROUP_REQUEST,
      endpoint: endpoint
		})

    fetch(endpoint, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
        'Token': auth.getAccessToken(),
        'RESULTSTRUCT': true
			}
		})
			.then(response => response.json())
			.then((result) => {
        if (result.success) {
          dispatch({
            type: ActionTypes.GET_CLIENT_GROUP_SUCCESS,
            payload: result.data
          })
        } else {
          dispatch({
  					type: ActionTypes.GET_CLIENT_GROUP_FAILURE,
  					payload: null,
            msg: `Get client failed. <b>${result.msg}</b>`,
            result: result
  				})
        }
			})
			.catch((error) => {
				dispatch({
					type: ActionTypes.GET_CLIENT_GROUP_FAILURE,
					error,
					payload: null,
          msg: `Get client failed. API issue.`
				})
			});
	};
}

export function editClientGroup(form) {
  return (dispatch) => {
    const endpoint = `${currentConfig.serverURL}/Admin/clientgroup/edit`;
    const body = form

		dispatch({
			type: ActionTypes.EDIT_CLIENT_GROUP_REQUEST,
      body: body
		})

    fetch(endpoint, {
			method: 'PUT',
      body: JSON.stringify(body),
			headers: {
				'Content-Type': 'application/json',
        'Token': auth.getAccessToken(),
        'RESULTSTRUCT': true
			}
		})
			.then(response => response.json())
			.then((result) => {
        if (result.success) {
          dispatch({
            type: ActionTypes.EDIT_CLIENT_GROUP_SUCCESS,
            payload: result.data,
            msg: `Edit client group <b>${result.data.clientGroupID}</b> succeed.`
          })
        } else {
          dispatch({
  					type: ActionTypes.EDIT_CLIENT_GROUP_FAILURE,
  					payload: null,
            msg: `Edit client group failed. <b>${result.msg}</b>`
  				})
        }
			})
			.catch((error) => {
				dispatch({
					type: ActionTypes.EDIT_CLIENT_FAILURE,
					error,
					payload: [],
          msg: `Edit client failed. API issue.`
				})
			});
	};
}

export function addNewClientGroup(form) {
  return (dispatch) => {
    const endpoint = `${currentConfig.serverURL}/Admin/clientgroup/add`;
    const body = form

		dispatch({
			type: ActionTypes.ADD_CLIENT_GROUP_REQUEST,
      body: body
		})

    fetch(endpoint, {
			method: 'POST',
      body: JSON.stringify(body),
			headers: {
				'Content-Type': 'application/json',
        'Token': auth.getAccessToken(),
        'RESULTSTRUCT': true
			}
		})
			.then(response => response.json())
			.then((result) => {
        if (result.success) {
          dispatch({
            type: ActionTypes.ADD_CLIENT_GROUP_SUCCESS,
            payload: result.data,
            msg: `Add client group <b>${form.clientGroupName}</b> succeed.`
          })
        } else {
          dispatch({
  					type: ActionTypes.ADD_CLIENT_GROUP_FAILURE,
  					payload: null,
            msg: `Add client group failed. <b>${result.msg}</b>`
  				})
        }
			})
			.catch((error) => {
				dispatch({
					type: ActionTypes.ADD_CLIENT_GROUP_FAILURE,
					error,
					payload: null,
          msg: `Add client group failed. API issue.`
				})
			});
	};
}
//
// export function clearRetrivedClient() {
//   return (dispatch) => {
//     dispatch({
// 			type: ActionTypes.CLEAR_RETRIVED_CLIENT,
// 		})
// 	};
// }
//
// export function clearSearchClient() {
//   return (dispatch) => {
//     dispatch({
// 			type: ActionTypes.CLEAR_SEARCH_CLIENT,
// 		})
// 	};
// }
//
export function clearClientGroupDetail() {
  return (dispatch) => {
    dispatch({
			type: ActionTypes.CLEAR_CLIENT_GROUP_DETAIL,
		})
	};
}
