import fetch from 'node-fetch';
import config from '../config';
import Auth from '../Auth/Auth';
import * as ActionTypes from '../constants/ActionTypes';

import _omit from 'lodash/omit';

// import scheduleSearchResult from '../mock/scheduleSearchResult.json';
// import scheduleDeatil from '../mock/scheduleDeatil.json';

const currentConfig = config.development;
const auth = new Auth();

export function searchSchedule(reportId, clientId) {
  return (dispatch) => {
    const endpoint = `${currentConfig.serverURL}/Subscription/list?reportid=${reportId}&clientId=${clientId}`;

		dispatch({
			type: ActionTypes.SEARCH_SCHEDULE_REQUEST,
      endpoint: endpoint
		})

    fetch(endpoint, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
        'Token': auth.getAccessToken(),
        'RESULTSTRUCT': true
			}
		})
			.then(response => response.json())
			.then((result) => {
        if (result.success) {
          dispatch({
            type: ActionTypes.SEARCH_SCHEDULE_SUCCESS,
            payload: result.data
          })
        } else {
          dispatch({
  					type: ActionTypes.SEARCH_SCHEDULE_FAILURE,
  					payload: null,
            msg: `Search schedule failed. <b>${result.msg}</b>`,
            result: result
  				})
        }
			})
			.catch((error) => {
				dispatch({
					type: ActionTypes.SEARCH_SCHEDULE_FAILURE,
					error,
					payload: null,
          msg: `Search schedule failed. API issue.`
				})
			});
	};
}

export function getScheduleDetail(id) {
  return (dispatch) => {
    const endpoint = `${currentConfig.serverURL}/Subscription/get/${id}`;

		dispatch({
			type: ActionTypes.GET_SCHEDULE_DETAIL_REQUEST,
      endpoint: endpoint
		})

    fetch(endpoint, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
        'Token': auth.getAccessToken(),
        'RESULTSTRUCT': true
			}
		})
			.then(response => response.json())
			.then((result) => {
        if (result.success) {
          dispatch({
            type: ActionTypes.GET_SCHEDULE_DETAIL_SUCCESS,
            payload: result.data// scheduleDeatil
          })
        } else {
          dispatch({
  					type: ActionTypes.GET_SCHEDULE_DETAIL_FAILURE,
  					payload: null,
            msg: `Get schedule detail ${id} failed. <b>${result.msg}</b>`
  				})
        }
			})
			.catch((error) => {
				dispatch({
					type: ActionTypes.GET_SCHEDULE_DETAIL_FAILURE,
					error,
					payload: null,
          msg: `Get schedule detail failed. API issue.`
				})
			});
	};
}

export function getScheduleOptions(scheduleId) {
  return (dispatch) => {
    let endpoint = `${currentConfig.serverURL}/subscription/shareschedule`;
    if (scheduleId) {
      endpoint = `${currentConfig.serverURL}/subscription/shareschedule?scheduleId=${scheduleId}`;
    }
    //console.log(endpoint)

		dispatch({
			type: ActionTypes.GET_SCHEDULE_OPTIONS_REQUEST,
      endpoint: endpoint
		})

    fetch(endpoint, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
        'Token': auth.getAccessToken(),
        'RESULTSTRUCT': true
			}
		})
			.then(response => response.json())
			.then((result) => {
        if (result.success) {
          dispatch({
            type: ActionTypes.GET_SCHEDULE_OPTIONS_SUCCESS,
            payload: result.data
          })
        } else {
          dispatch({
  					type: ActionTypes.GET_SCHEDULE_OPTIONS_FAILURE,
  					payload: null,
            msg: `Get schedule options failed. <b>${result.msg}</b>`
  				})
        }
			})
			.catch((error) => {
				dispatch({
					type: ActionTypes.GET_SCHEDULE_OPTIONS_FAILURE,
					error,
					payload: null,
          msg: `Get schedule options failed. API issue.`
				})
			});
	};
}

export function editSchedule(form) {
  return (dispatch) => {
    const endpoint = `${currentConfig.serverURL}/Subscription/set`;
    const body = _omit(form, ['reportName', 'reportPath'])

		dispatch({
			type: ActionTypes.EDIT_SCHEDULE_REQUEST,
      body: body
		})

    fetch(endpoint, {
			method: 'PUT',
      body: JSON.stringify(body),
			headers: {
				'Content-Type': 'application/json',
        'Token': auth.getAccessToken(),
        'RESULTSTRUCT': true
			}
		})
			.then(response => response.json())
			.then((result) => {
        if (result.success) {
          dispatch({
            type: ActionTypes.EDIT_SCHEDULE_SUCCESS,
            payload: result.data,
            msg: `Edit schedule <b>${form.reportName}</b> succeed.`
          })
        } else {
          dispatch({
  					type: ActionTypes.EDIT_SCHEDULE_FAILURE,
  					payload: null,
            msg: `Edit schedule failed. <b>${result.msg}</b>`
  				})
        }
			})
			.catch((error) => {
				dispatch({
					type: ActionTypes.EDIT_SCHEDULE_FAILURE,
					error,
					payload: null,
          msg: `Edit schedule failed. API issue.`
				})
			});
	};
}

export function addNewSchedule(form, reportName) {
  return (dispatch) => {
    const endpoint = `${currentConfig.serverURL}/Subscription/create`;
    const body = form

		dispatch({
			type: ActionTypes.ADD_SCHEDULE_REQUEST,
      body: body
		})

    fetch(endpoint, {
			method: 'POST',
      body: JSON.stringify(body),
			headers: {
				'Content-Type': 'application/json',
        'Token': auth.getAccessToken(),
        'RESULTSTRUCT': true
			}
		})
			.then(response => response.json())
			.then((result) => {
        if (result.success) {
          dispatch({
            type: ActionTypes.ADD_SCHEDULE_SUCCESS,
            payload: {
              reportId: form.reportId,
              subscriptionId: result.data
            },
            msg: `Add schedule <b>${reportName}</b> succeed.`
          })
        } else {
          dispatch({
  					type: ActionTypes.ADD_SCHEDULE_FAILURE,
  					payload: null,
            msg: `Add schedule failed. <b>${result.msg}</b>`,
            result
  				})
        }
			})
			.catch((error) => {
				dispatch({
					type: ActionTypes.ADD_SCHEDULE_FAILURE,
					error,
					payload: null,
          msg: `Add schedule failed. API issue.`
				})
			});

    // setTimeout(function(){
    //   dispatch({
    //     type: ActionTypes.ADD_CLIENT_SUCCESS,
    //     payload: addNewClientResult,
    //     msg: `Add client <b>${form.clientID}</b> succeed.`
    //   })
    // }, 1000);

	};
}

export function getScheduleTypes() {
  return (dispatch) => {
    const endpoint = `${currentConfig.serverURL}/Common/option/get?type=ScheduleType`;

    dispatch({
      type: ActionTypes.GET_ScheduleType_REQUEST,
    })

    fetch(endpoint, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Token': auth.getAccessToken(),
        'RESULTSTRUCT': true
      }
    })
    .then(response => response.json())
    .then((result) => {
      if (result.success) {
        dispatch({
          type: ActionTypes.GET_ScheduleType_SUCCESS,
          payload: result.data
        })
      } else {
        dispatch({
          type: ActionTypes.GET_ScheduleType_FAILURE,
          payload: null,
          msg: `Get ScheduleType failed. <b>${result.msg}</b>`
        })
      }
    })
    .catch((error) => {
      dispatch({
        type: ActionTypes.GET_ScheduleType_FAILURE,
        error,
        payload: null,
        msg: `Get ScheduleType failed. API issue.`
      })
    });
  };
}


export function clearSearchSchedule() {
  return (dispatch) => {
    dispatch({
			type: ActionTypes.CLEAR_SEARCH_SCHEDULE,
		})
	};
}

export function clearScheduleDetail() {
  return (dispatch) => {
    dispatch({
			type: ActionTypes.CLEAR_SCHEDULE_DETAIL,
		})
	};
}
