import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus} from '@fortawesome/free-solid-svg-icons';
import {Button, Tab, Tabs, Row, Col, Form, Alert} from 'react-bootstrap';
import toaster from 'toasted-notes' ;
import 'toasted-notes/src/styles.css';

import MultiSelectInput from '../components/MultiSelectInput';
import SelectInput from '../components/SelectInput';
import ConfirmNavigationModal from '../components/ConfirmNavigationModal';

import _extend from 'lodash/extend';
import _map from 'lodash/map';
import _find from 'lodash/find';

import {month, currency} from '../util/constances.js';

import {getUsers} from '../actions/user';
import {getCountryCode} from '../actions/common';
import {clearAllMsg} from '../actions/index';
import {
  getRoles,
  getRoleDetail,
  clearRoleDetail,
  editRole,
  addNewRole,
} from '../actions/role';

class RoleMaintenance extends Component {
  constructor(props) {
    super(props);

    this.onAddNewClick = this.onAddNewClick.bind(this);
    this.onFieldChange = this.onFieldChange.bind(this);
    this.onSelectRoles = this.onSelectRoles.bind(this);
    this.onSelectRoleId = this.onSelectRoleId.bind(this);
    this.onSubmitEditClick = this.onSubmitEditClick.bind(this);
    this.changeTab = this.changeTab.bind(this);
    this.onGetNewRoleDetail = this.onGetNewRoleDetail.bind(this);
    this.onCountryCodeSelectClick = this.onCountryCodeSelectClick.bind(this);

    this.state = {
      currentTab: 'edit',
      selectedRoleId: '',
      isFillingForm: false,
      editForm: {
        roleID: '',
        roleName: '',
        finYearMonth: month[0].value,
        countryCode: '',
        currency: currency[0],
        members: [],
      },
      newForm: {
        roleID: '',
        roleName: '',
        finYearMonth: month[0].value,
        countryCode: '',
        currency: currency[0],
        members: [],
      },
      isValidRoleId: true,
    };
  }

  componentDidMount() {
    if (!this.props.countryCodeList) {
      this.props.getCountryCode()
    }
  }

  componentWillUnmount() {
    this.props.clearRoleDetail()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedRoleId !== this.state.selectedRoleId) {
      if (this.state.selectedRoleId) {
        this.props.getRoleDetail(this.state.selectedRoleId)
      } else {
        this.props.clearRoleDetail()
        this.setRoleDetail()
        this.setFillingForm(false)
      }
    }

    if (this.props.roleDetail) {
      if(prevProps.roleDetail !== this.props.roleDetail) {
        this.setRoleDetail()
      }
    }

    if (prevProps.newAddedRole.timestamp !== this.props.newAddedRole.timestamp) {
      this.props.getRoles();
      this.clearNewForm()

      toaster.notify(({ onClose }) => (
        <Alert
          variant="success"
          className="alert-msg mb-0"
          dismissible
          onClose={onClose}
        >
          <Alert.Link onClick={this.onGetNewRoleDetail}>Click here to review or edit.</Alert.Link>
        </Alert>
      ), {duration: 3000})
    }

    if (!prevProps.shouldRefreshRoleDetail && this.props.shouldRefreshRoleDetail) {
      this.props.getRoleDetail(this.state.selectedRoleId)
      this.setFillingForm(false)
    }
  }


  onFieldChange(e) {
    let formName = this.state.currentTab === 'edit' ? 'editForm' : 'newForm'

    this.setState({
      isFillingForm: true,
      [formName]: _extend(this.state[formName], {
        [e.target.name]: e.target.value,
      })
    })
  }

  changeTab(isChangeTab, tabName) {
    if (isChangeTab) {
      const formName = tabName === 'edit' ? 'newForm' : 'editForm'
      this.setState({
        currentTab: tabName,
        isFillingForm: false,
        warningPopUp: false,
        selectedRoleId: '',
        [formName]: {
          roleID: '',
          roleName: '',
          finYearMonth: month[0].value,
          countryCode: '',
          currency: currency[0],
          members: [],
        },
        isValidRoleId: true,
      })
    } else {
      this.setState({
        warningPopUp: false
      })
    }
  }

  onTabClick(tabName) {
    if(tabName !== this.state.currentTab) {
      if (this.state.isFillingForm) {
        this.setState({
          warningPopUp: true,
        })
      } else {
        const formName = tabName === 'edit' ? 'newForm' : 'editForm'
        this.setState({
          currentTab: tabName,
          selectedRoleId: '',
          [formName]: {
            roleID: '',
            roleName: '',
            finYearMonth: month[0].value,
            countryCode: '',
            currency: currency[0],
            members: [],
          },
          isValidRoleId: true,
        })
      }
    }
  }

  onAddNewClick() {
    this.props.addNewRole(this.state.newForm)
  }

  onSubmitEditClick() {
    this.props.editRole(this.state.editForm)
  }

  onSelectRoles(array) {
    let formName = this.state.currentTab === 'edit' ? 'editForm' : 'newForm'
    this.setState({
      isFillingForm: true,
      [formName]: _extend(this.state[formName], {
        members: _map(array, (r) => r.key),
      })
    })
  }

  onGetNewRoleDetail() {
    this.setState({
      selectedRoleId: this.props.newAddedRole.roleID,
      currentTab: 'edit',
      isFillingForm: false
    })
    this.props.clearAllMsg()
  }

  setFillingForm(bool) {
    this.setState({
      isFillingForm: bool
    })
  }

  clearNewForm() {
    this.setState({
      newForm: {
        roleID: '',
        roleName: '',
        finYearMonth: month[0].value,
        countryCode: '',
        currency: currency[0],
        members: [],
      },
      isFillingForm: false,
    })
  }

  checkValidRoleId(id) {
    if (_find(this.props.roleList, {'key': id})) {
      this.setState({
        isValidRoleId: false
      })
    } else {
      this.setState({
        isValidRoleId: true
      })
    }
  }

  onSelectRoleId(id) {
    this.setState({
      selectedRoleId: id
    })
  }

  onCountryCodeSelectClick(value) {
    let formName = this.state.currentTab === 'edit' ? 'editForm' : 'newForm'
    this.setState({
      isFillingForm: true,
      [formName]: _extend(this.state[formName], {
        countryCode: value,
      })
    })
  }

  setRoleDetail() {
    this.setState({
      editForm: {
        "roleID": this.state.selectedRoleId ? this.props.roleDetail.roleID : '',
        "roleName": this.state.selectedRoleId ? this.props.roleDetail.roleName : '',
        "finYearMonth": this.state.selectedRoleId ? this.props.roleDetail.finYearMonth || month[0].value : '',
        "countryCode": this.state.selectedRoleId ? this.props.roleDetail.countryCode : '',
        "currency": this.state.selectedRoleId ? this.props.roleDetail.currency || currency[0] : '',
        "members": this.state.selectedRoleId ? _map(this.props.roleDetail.members, (r) => r.key) : []
      }
    })
  }

  getEditForm() {
    let formName = this.state.currentTab === 'edit' ? 'editForm' : 'newForm'
    let isEdit = this.state.currentTab === 'edit'
    return (
      <div className="pt-3">
        <Row className="form-group">
          <Col xs="3" className="text-right">
            <Form.Label className="col-form-label">Role ID{!isEdit && '*'}</Form.Label>
          </Col>
          <Col xs="7">
            {isEdit ?
              <p className="col-form-label">{this.state.editForm.roleID}</p>:
              <Form.Control
                type="text"
                value={this.state.newForm.roleID}
                name="roleID"
                isInvalid={!this.state.isValidRoleId}
                onChange={(e) => this.onFieldChange(e)}
                onBlur={() => this.checkValidRoleId(this.state.newForm.roleID)}
                maxLength={10}
              />
            }
            {!this.state.isValidRoleId &&
              <Form.Text className="text-danger">
                This Role ID has been used.
              </Form.Text>
            }
          </Col>
        </Row>

        <Row className="form-group">
          <Col xs="3" className="text-right">
            <Form.Label className="col-form-label">Name{!isEdit && '*'}</Form.Label>
          </Col>
          <Col xs="7">
            {isEdit ?
              <p className="col-form-label">{this.state.editForm.roleName}</p>:
              <Form.Control
                type="text"
                value={this.state.newForm.roleName}
                name="roleName"
                onChange={(e) => this.onFieldChange(e)}
                maxLength={100}
              />
            }
          </Col>
        </Row>

        <Row className="form-group">
          <Col xs="3" className="text-right">
            <Form.Label className="col-form-label">Financial Year Month</Form.Label>
          </Col>
          <Col xs="7">
            <Form.Control
              as="select"
              name="finYearMonth"
              value={this.state[formName].finYearMonth || month[0].value}
              onChange={(e) => this.onFieldChange(e)}
            >
              {_map(month, m =>
                <option value={m.value} key={m.value}>{m.name}</option>
              )}
            </Form.Control>
          </Col>
        </Row>

        <Row className="form-group">
          <Col xs="3" className="text-right">
            <Form.Label className="col-form-label">Country Code</Form.Label>
          </Col>
          <Col xs="7">
            <SelectInput
              preValue={this.state[formName].countryCode}
              handleSelect={this.onCountryCodeSelectClick}
              handleFetchOptions={this.props.getCountryCode}
              options={this.props.countryCodeList}
              optionLabel="displayValue"
              optionValue="key"
              name="countryCode"
              isClearable={false}
            />
          </Col>
        </Row>

        <Row className="form-group">
          <Col xs="3" className="text-right">
            <Form.Label className="col-form-label">Currency</Form.Label>
          </Col>
          <Col xs="7">
            <Form.Control
              as="select"
              name="currency"
              value={this.state[formName].currency || currency[0]}
              onChange={(e) => this.onFieldChange(e)}
            >
              {_map(currency, c =>
                <option value={c} key={c}>{c}</option>
              )}
            </Form.Control>
          </Col>
        </Row>

        <Row className="form-group">
          <Col xs="3" className="text-right">
            <Form.Label className="col-form-label">Members</Form.Label>
          </Col>
          <Col xs="7">
            <MultiSelectInput
              handleSelect={this.onSelectRoles}
              handleFetchOptions={this.props.getUsers}
              options={this.props.userList}
              preValue={isEdit ? this.props.roleDetail.members : _map(this.state.newForm.members, (role) => (_find(this.props.userList, {'key': role})))}
              optionLabel="displayValue"
              optionValue="key"
            />
          </Col>
        </Row>

        <div className="pt-3 text-center">
          <Button
            size="lg"
            onClick={isEdit ? this.onSubmitEditClick : this.onAddNewClick}
            disabled={isEdit ? false : (
              !this.state.newForm.roleID ||
              !this.state.newForm.roleName ||
              !this.state.isValidRoleId
            )}
          >
            {isEdit ? 'Submit Edit' : 'Add New Role'}
          </Button>
        </div>
      </div>
    )
  }

  render () {
    return (
      <div>
        <div className="text-center">
          <h3>Role maintenance</h3>
        </div>
        <Row className="justify-content-center mb-5">
          <Col lg="10">
            <Tabs activeKey={this.state.currentTab} id="role-maintenance-tab" onSelect={(k) => this.onTabClick(k)}>
              <Tab eventKey="edit" title="Edit" className="p-3">
                <Row className="border-bottom pb-3">
                  <Col xs="3" className="text-right">
                    <Form.Label className="font-weight-bold col-form-label">Select Role To Edit</Form.Label>
                  </Col>
                  <Col xs="7">
                    <SelectInput
                      preValue={this.state.selectedRoleId}
                      handleSelect={this.onSelectRoleId}
                      handleFetchOptions={this.props.getRoles}
                      options={this.props.roleList}
                      optionLabel="displayValue"
                      optionValue="key"
                      name="selectedRoleId"
                      isClearable
                    />
                  </Col>
                  <Col xs="2">
                    <Button variant="secondary" className="btn-block" disabled={!this.state.selectedRoleId} onClick={() => this.onSelectRoleId('')}>Cancel</Button>
                  </Col>
                </Row>

                {!this.props.roleDetail &&
                  <div className="no-result" />
                }

                {this.state.editForm.roleID &&
                  this.getEditForm()
                }
              </Tab>

              <Tab eventKey="add-new" title={<div><FontAwesomeIcon icon={faPlus}/> Add New</div>} className="p-3">
                {this.state.currentTab === 'add-new' &&
                  this.getEditForm()
                }
              </Tab>
            </Tabs>
          </Col>
        </Row>

        <ConfirmNavigationModal
          show={this.state.warningPopUp}
          handleCloseTab={this.changeTab}
          currentTab={this.state.currentTab}
        />
      </div>
    );
  }
}


RoleMaintenance.propTypes = {
	getUsers: PropTypes.func,
	getRoles: PropTypes.func,
	getRoleDetail: PropTypes.func,
	clearRoleDetail: PropTypes.func,
	editRole: PropTypes.func,
	addNewRole: PropTypes.func,
	getCountryCode: PropTypes.func,
	shouldRefreshRoleDetail: PropTypes.bool,
	newAddedRole: PropTypes.object,
};

function mapStateToProps(state) {
	return {
    userList: state.user.userList,
    roleList: state.role.roleList,
    roleDetail: state.role.roleDetail,
    groupList: state.common.groupList,
    shouldRefreshRoleDetail: state.role.shouldRefreshRoleDetail,
    newAddedRole: state.role.newAddedRole,
    countryCodeList: state.common.countryCodeList,
	}
}

const mapDispatchToProps = {
  getUsers,
  getRoles,
  getCountryCode,
  addNewRole,
  getRoleDetail,
  clearRoleDetail,
  editRole,
  clearAllMsg,
};

export default connect(mapStateToProps, mapDispatchToProps)(RoleMaintenance);
