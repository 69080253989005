import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus, faSearch, faEye, faLongArrowAltLeft, faSpinner} from '@fortawesome/free-solid-svg-icons';
import {Button, Tab, Tabs, Row, Col, Form, ButtonToolbar, OverlayTrigger, Tooltip, Alert} from 'react-bootstrap';
import toaster from 'toasted-notes' ;
import 'toasted-notes/src/styles.css';

import SelectInput from '../components/SelectInput';
import MultiSelectInput from '../components/MultiSelectInput';
import EmailInputBox from '../components/EmailInputBox';
import ConfirmNavigationModal from '../components/ConfirmNavigationModal';
import {genValidReportParams} from '../util/common.js';
import {prettyDateTime} from '../util/transformDate.js'
import AirportSelect from '../components/AirportSelect';
import CustomDatePicker from '../components/CustomDatePicker';
import CustomDateRangePicker from '../components/CustomDateRangePicker';

import _orderBy from 'lodash/orderBy';
import _extend from 'lodash/extend';
import _find from 'lodash/find';
import _map from 'lodash/map';
import _without from 'lodash/without';
import _concat from 'lodash/concat';
import _get from 'lodash/get';

import {clearAllMsg} from '../actions/index';
import {getClientId} from '../actions/common';
import {
  getReportType,
  getReportParameters,
  getSecurityClientGroup,
  getSecurityClient,
  clearReportParameters,
} from '../actions/report';
import {
  searchSchedule,
  getScheduleDetail,
  clearScheduleDetail,
  clearSearchSchedule,
  getScheduleOptions,
  editSchedule,
  addNewSchedule,
  getScheduleTypes,
} from '../actions/schedule';

const pageSize = 10

class Schedule extends Component {
  constructor(props) {
    super(props);

    this.onFieldChange = this.onFieldChange.bind(this);
		this.onReportSelectClick = this.onReportSelectClick.bind(this);
		this.onCustomerSelectClick = this.onCustomerSelectClick.bind(this);
    this.changeTab = this.changeTab.bind(this);
    this.onSubmitClick = this.onSubmitClick.bind(this);
    this.onSearchClick = this.onSearchClick.bind(this);
    this.onDetailClick = this.onDetailClick.bind(this);
    this.onCancelClick = this.onCancelClick.bind(this);
    this.onEmailChange = this.onEmailChange.bind(this);
    this.onGetNewScheduleDetail = this.onGetNewScheduleDetail.bind(this);
    this.onClientSelectClick = this.onClientSelectClick.bind(this);
    this.onCustomerCheck = this.onCustomerCheck.bind(this);
    this.onSelectInputClick = this.onSelectInputClick.bind(this);
    this.onDateSelect = this.onDateSelect.bind(this);
		this.onDateRangeSelect = this.onDateRangeSelect.bind(this);

    this.state = {
      currentTab: 'edit',
      isFillingForm: false,
      scheduleRQ: {
        reportId: '',
        description: '',
        schedule: {
          scheduleId: ''
        },
        email: {
          emailTo: [],
          emailCC: [],
          emailBCC: [],
          subject: '',
          body: '',
        },
        clientGroupId: '',
        clients: [],
        params: {}
      },
      selectedReport: '',
      selectedCustomer: '',
      currentPage: 0,
      criteria: this.getCriteria_Init(),
      isLoadingClients: false,
      paramErrorField: [],
      isRequiredField: [],
      isCheckAllClient: false,
      formErrorField: [],
      errorMsg: null,
    };
  }

  componentWillUnmount() {
    this.props.clearSearchSchedule()
    this.props.clearScheduleDetail()
    this.props.clearReportParameters()
  }

  componentDidUpdate(prevProps, prevState) {
    if(!this.props.scheduleTypeList){
      this.props.getScheduleTypes()
    }
    if(prevProps.scheduleDetail !== this.props.scheduleDetail) {
      this.setScheduleRQ()
    }

    if (!prevProps.shouldRefreshScheduleDetail && this.props.shouldRefreshScheduleDetail) {
      this.setFillingForm(false)
    }

    if (this.state.currentTab === 'add-new' && !this.props.scheduleOptions) {
      this.props.getScheduleOptions()
    }

    if (prevProps.reportParams !== this.props.reportParams) {
      this.setReportParams(this.state.currentTab === 'add-new')
    }

    if (this.state.currentTab === 'add-new' && this.props.scheduleOptions && this.props.scheduleOptions.length > 0 && !this.state.scheduleRQ.schedule.scheduleId) {
      this.setScheduleId()
    }

    if (prevProps.newAddedSchedule.timestamp !== this.props.newAddedSchedule.timestamp) {
      this.clearForm()

      toaster.notify(({ onClose }) => (
        <Alert
          variant="success"
          className="alert-msg mb-0"
          dismissible
          onClose={onClose}
        >
          <Alert.Link onClick={this.onGetNewScheduleDetail}>Click here to review or edit.</Alert.Link>
        </Alert>
      ), {duration: 3000})
    }
  }

  onClientSelectClick(selectedOption, name) {
		this.setState({
			criteria: _extend(this.state.criteria, {
				[name]: selectedOption
			})
		})
		this.setLoadingClients(true)
		this.props.getSecurityClient(selectedOption)
  }

  setLoadingClients(bool) {
		this.setState({
			isLoadingClients: bool
		})
  }
  
  checkAllClients() {
		if (this.props.securityClientList) {
			this.setState({
				criteria: _extend(this.state.criteria, {
					Clients: _map(this.props.securityClientList, client => client.key)
				}),
				isCheckAllClient: true
			})
		}
  }
  
  onCustomerCheck(e) {
		if (e.target.name === 'all') {
			if (e.target.checked) {
				this.checkAllClients()
			} else {
				this.setState({
					criteria: _extend(this.state.criteria, {
						Clients: []
					}),
					isCheckAllClient: false
				})
			}
		} else {
			if (e.target.checked) {
				this.setState({
					criteria: _extend(this.state.criteria, {
						Clients: _concat(this.state.criteria.Clients, e.target.name)
					})
				})
			} else {
				this.setState({
					criteria: _extend(this.state.criteria, {
						Clients: _without(this.state.criteria.Clients, e.target.name)
					}),
					isCheckAllClient: false
				})
			}
		}
  }
  
  onSelectInputClick(val, name) {
    if (name === 'scheduleId') {
      this.setState({
        isFillingForm: true,
        scheduleRQ: _extend(this.state.scheduleRQ, {
          schedule: {
            scheduleId: val,
          },
        }),
        criteria :_extend(this.state.criteria, {
          ScheduleType : this.getScheduleType(val)
        })
      })
    }
    else {
      this.setState({
        criteria: _extend(this.state.criteria, {
          [name]: val
        })
      })
    }
  }
  
  getScheduleType(scheduleId) {
    let so = _find(this.props.scheduleOptions, { 'id': scheduleId });
    let scheduleOptionName = (so ? so.name : '').toUpperCase();
    let matchScheduleOption = _find(this.props.scheduleTypeList, o => { return scheduleOptionName.indexOf(o.displayValue.toUpperCase()) !== -1; });
    let scheduleType = matchScheduleOption ? matchScheduleOption.key : 'M';
    return scheduleType;
  }

  onSearchClick(page) {
    this.props.searchSchedule(
      this.state.selectedReport,
      this.state.selectedCustomer,
      pageSize,
      page
    )

    this.setState({
      currentPage: page
    })
  }

  onCancelClick() {
    this.setState({
      selectedReport: '',
      selectedCustomer: '',
      currentPage: 0,
      isFillingForm: false,
    })
    this.props.clearSearchSchedule()
  }

  onDetailClick(subscriptionId, reportId) {
    this.setState({
			criteria: _extend(this.state.criteria, {
				ScheduleType :''
			})
		})
    this.props.getScheduleDetail(subscriptionId)
    this.props.getSecurityClientGroup()
  }

  onGetNewScheduleDetail() {
    this.setState({
      currentTab: 'edit',
      isFillingForm: false
    })

    this.onDetailClick(this.props.newAddedSchedule.subscriptionId, this.props.newAddedSchedule.reportId)
  }

  onFieldChange(e) {
    this.setState({
			criteria: _extend(this.state.criteria, {
				[e.target.name]: e.target.value
			})
		})
  }
  
  onDescChange(e, name) {
    this.setState({
      isFillingForm: true,
      scheduleRQ: _extend(this.state.scheduleRQ, {
        [e.target.name]: e.target.value,
      })
    })
  }

  onEmailChange(value, name) {
    let isEmail = false
    if (Array.isArray(value)) {isEmail = true}
    this.setState({
      isFillingForm: true,
      scheduleRQ: _extend(this.state.scheduleRQ, {
        email: _extend(this.state.scheduleRQ.email, {
          [isEmail ? name : value.target.name]: isEmail ? value : value.target.value
        })
      })
    })
  }

  onDateSelect(startDateValue, name) {
		this.setState({
			criteria: _extend(this.state.criteria, {
				[name]: startDateValue
			})
		})
  }
   
  onDateRangeSelect(startDateValue, endDateValue, name, i) {
		this.setState({
			criteria: _extend(this.state.criteria, {
				[`${name}From`]: startDateValue,
				[`${name}To`]: endDateValue,
			})
		})
 	}

	onReportSelectClick(reportId) {
    if(this.state.currentTab === 'add-new') {
      this.setState({
        isFillingForm: true,
        scheduleRQ: _extend(this.state.scheduleRQ, {
          reportId: reportId
        }),
        criteria: this.getCriteria_Init()
      })
      this.props.getReportParameters(reportId);
    } else {
      this.setState({
        selectedReport: reportId,
        criteria: this.getCriteria_Init()
      })
    }
  }
  getCriteria_Init() {
    return {
      ScheduleType: this.state ? this.state.criteria.ScheduleType : ''
    }
  }

	onCustomerSelectClick(value) {
		this.setState({
			selectedCustomer: value
		})
	}

  onTabClick(toTabName) {
    if(toTabName !== this.state.currentTab) {
      if (this.state.isFillingForm) {
        this.setState({
          warningPopUp: true,
        })
      } else {
        this.changeTab(true, toTabName)
      }
    }
  }

  changeTab(isChangeTab, toTabName) {
    if (isChangeTab) {
      this.setState({
        currentTab: toTabName,
      })
      this.clearForm();
    } else {
      this.setState({
        warningPopUp: false
      })
    }
  }

  clearForm() {
    this.setState({
      isFillingForm: false,
      warningPopUp: false,
      // selectedReport: '',
      // selectedCustomer: '',
      scheduleRQ: {
        reportId: '',
        description: '',
        schedule: {
          scheduleId: ""
        },
        email: {
          emailTo: [],
          emailCC: [],
          emailBCC: [],
          subject: '',
          body: '',
        },
        clientGroupId: '',
        clients: [],
        params: {}
      },
      criteria: this.getCriteria_Init(),
      isLoadingClients: false,
      paramErrorField: [],
      isRequiredField: [],
      isCheckAllClient: false,
      formErrorField: [],
      errorMsg: null,
    })

    // if (this.props.scheduleSearchResult) {
    //   this.props.clearSearchSchedule()
    // }
    if (this.props.reportParams) {
      this.props.clearReportParameters()
    }
    this.props.clearScheduleDetail()
  }

  onSubmitClick(isEdit) {
    let formErrorField = []
    if (!this.state.scheduleRQ.reportId) {
      formErrorField.push('reportId')
    }
    if (!this.state.scheduleRQ.schedule.scheduleId) {
      formErrorField.push('scheduleId')
    }
    else {
      if (this.props.scheduleOptions && !_find(this.props.scheduleOptions, { id: this.state.scheduleRQ.schedule.scheduleId })) {
        formErrorField.push('scheduleId')
      }
    }
    if (!this.state.scheduleRQ.email.emailTo.length > 0) {
      formErrorField.push('emailTo')
    }
    if (!this.state.scheduleRQ.email.subject) {
      formErrorField.push('subject')
    }
    let checkParamsResult = genValidReportParams(this.state, this.props.reportParams)

    this.setState({
      formErrorField,
      paramErrorField: checkParamsResult.errorField
    })
    if (formErrorField.length === 0 && checkParamsResult.errorField.length === 0) {
      this.setState({
        errorMsg: null,
        paramErrorField: checkParamsResult.errorField,
        formErrorField
      })

      let rq = {}
      _map(this.state.scheduleRQ, (value, key) => rq[key] = value)
      rq.params = {}
      _map(checkParamsResult.params, (item) => rq.params[item.Key] = item.Value)
      _map(this.state.criteria, (value, key) => {
        if (key === 'ClientGroup') {
          rq.clientGroupId = value
        }
        if (key === 'Clients') {
          rq.clients = value
        }
      })
      if (isEdit) {
        this.props.editSchedule(rq)
      } else {
        this.props.addNewSchedule(rq, _find(this.props.reportTypeList, { key: this.state.scheduleRQ.reportId }).displayValue)
      }
    } else {
      this.setState({
        errorMsg: 'Some criterias are not filled.',
        paramErrorField: checkParamsResult.errorField,
        formErrorField
      })
    }
  }

  onBackClick() {
    this.props.clearScheduleDetail()
    this.props.clearReportParameters()
    this.clearForm()
    this.setFillingForm(false)
  }

  setScheduleRQ() {
    if(this.props.scheduleDetail && this.props.scheduleDetail.reportId){
      this.setState({
        scheduleRQ: this.props.scheduleDetail
      })
      if (this.props.scheduleDetail.clientGroupId) {
        this.props.getSecurityClient(this.props.scheduleDetail.clientGroupId)
      }
      this.props.getReportParameters(this.props.scheduleDetail.reportId)
      this.props.getScheduleOptions(this.props.scheduleDetail.schedule.scheduleId)
    }
  }

  setFillingForm(bool) {
    this.setState({
      isFillingForm: bool
    })
  }

  setScheduleId() {
    this.setState({
      scheduleRQ: _extend(this.state.scheduleRQ, {
        schedule: {
          scheduleId: this.props.scheduleOptions[0].id
        }
      }),
      criteria :_extend(this.state.criteria, {
        ScheduleType : this.getScheduleType(this.props.scheduleOptions[0].id)
      })
    })
  }

	setReportParams(isAdd) {
		let isRequiredField = []
		let criteria = _map(this.props.reportParams, (o) => {
			if (o.controlType === 'ClientGroup') {
				if (o.isRequire) {
					isRequiredField.push('ClientGroup', 'Clients')
				}
				return {
					'ClientGroup': '',
					'Clients': []
				}
			}
			if (this.isDateControl(o.controlType)) {
				//日期控件不做处理
			} else if (o.controlType === 'MultiSel') {
				if (o.isRequire) {
					isRequiredField.push(o.rptParamName)
				}
				return { [o.rptParamName]: _get(o, 'defaultValue', []) }
			} else {
				if (o.isRequire) {
					isRequiredField.push(o.rptParamName)
				}
				return { [o.rptParamName]: _get(o, 'defaultValue', '') }
			}
		}
		)

    //criteria系数组，merged系object。eg:[{x:1},{y:2}] => {x:1, y:2}
		let merged = criteria.reduce(function (acc, x) {
			for (var key in x) acc[key] = x[key];
			return acc;
		}, {})

    if (!isAdd && this.props.scheduleDetail && this.props.scheduleDetail.params) {
      _map(this.props.scheduleDetail.params, (value, key) => {
        if (key === 'Clients') {
          merged[key] = value.split(',')
          merged['ClientGroup'] = this.props.scheduleDetail.clientGroupId
        }
        else if (key === 'ScheduleType') {
          merged[key] = value
        }
        else {
          _map(this.props.reportParams, item => {
            if (item.rptParamName === key && !this.isDateControl(item.controlType)) {
              if (item.controlType === 'MultiSel') {
                merged[key] = value.split(',')
              }
              else {
                merged[key] = value
              }
            }
          })
        }
      })
    }
		this.setState({
			criteria: _extend(this.state.criteria,
				merged
			),
			isRequiredField,
			isFillingForm: false
		})
  }

  getScheduleDesc() {
    let s = _find(this.props.scheduleOptions, { 'id': this.state.scheduleRQ.schedule.scheduleId })
    return s ? s.desc : ''
  }

  isDateControl(controlType){
    return controlType.indexOf('Date') !== -1
  }
  
  getResult() {
    return (
      <div className="pt-5">
        <h5>Result</h5>
        <table className="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Report Type</th>
              <th scope="col">Description</th>
              <th scope="col">Status</th>
              <th scope="col">Last Run</th>
              <th scope="col">Delivery</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {this.props.scheduleSearchResult.length > 0 ?
              this.props.scheduleSearchResult.map((sch, i) =>
              <tr key={i}>
                <th scope="row">{i + 1}</th>
                <td>{sch.reportName}</td>
                <td>{sch.description}</td>
                <td>{sch.status}</td>
                <td>{sch.lastRun ? prettyDateTime(sch.lastRun) : '-'}</td>
                <td>
                  <ButtonToolbar>
                    <OverlayTrigger
                      key="right"
                      placement="right"
                      overlay={
                        <Tooltip id="tooltip-right">
                          {sch.emailTo ? sch.emailTo.map((mail, i) =>
                            <div key={i}>{mail}</div>
                          ) : 'No Email sent'}
                        </Tooltip>
                      }
                    >
                      <div><FontAwesomeIcon icon={faEye} /> Mail sent to</div>
                    </OverlayTrigger>
                  </ButtonToolbar>
                </td>
                <td className="text-center">
                  <Button variant="outline-secondary" onClick={() => this.onDetailClick(sch.subscriptionId, sch.reportId)}>Edit detail</Button>
                </td>
              </tr>
            ):
            <tr>
              <td colSpan="6" className="p-5 text-center border-bottom"><div>No records.</div></td>
            </tr>
          }
          </tbody>
        </table>
      </div>
    )
  }

  getEditForm() {
    let isEdit = this.state.currentTab === 'edit'
    return (
      <div>
        <Row className="form-group">
          <Col xs="3" className="text-right">
            <Form.Label className="col-form-label">Report Type{isEdit ? '' : '*'}</Form.Label>
          </Col>
          <Col xs="7">
            {isEdit?
              <p className="col-form-label">{this.props.scheduleDetail.reportName}</p>:
              <SelectInput
                preValue={this.state.scheduleRQ.reportId}
                handleSelect={this.onReportSelectClick}
                handleFetchOptions={this.props.getReportType}
                options={this.props.reportTypeList}
                optionLabel="displayValue"
                optionValue="key"
                isInValid={this.state.formErrorField.includes('reportId')}
              />
            }
          </Col>
        </Row>
        <Row className="form-group">
          <Col xs="3" className="text-right">
            <Form.Label className="col-form-label">Description</Form.Label>
          </Col>
          <Col xs="7">
            <Form.Control
              type="text"
              onChange={(e) => this.onDescChange(e)}
              name="description"
              value={this.state.scheduleRQ.description}
            />
          </Col>
        </Row>

        <Row className="form-group">
          <Col xs="3" className="text-right">
            <Form.Label className="col-form-label">Schedule*</Form.Label>
          </Col>
          <Col xs="7">
            <SelectInput
							preValue={this.state.scheduleRQ.schedule.scheduleId}
							handleSelect={this.onSelectInputClick}
						  handleFetchOptions={()=>{}}
							options={this.props.scheduleOptions && this.props.scheduleOptions.length > 0 && _map(_orderBy(this.props.scheduleOptions, ['name'], ['asc']), (option, i) =>
                ({
                  key:option.id,
                  displayValue:option.name
                })
              )}
							optionLabel="displayValue"
							optionValue="key"
							name="scheduleId"
							isClearable={false}
							isSearchable={false}
							isInValid={this.state.formErrorField.includes('scheduleId')}
						/>
            {this.props.scheduleOptions && this.props.scheduleOptions.length > 0 && this.state.scheduleRQ.schedule.scheduleId &&
              <p className="small mb-0 text-muted pl-2">{this.getScheduleDesc()}</p>
            }
            {!this.state.scheduleRQ.schedule.scheduleId && this.state.scheduleRQ.schedule.alias &&
              <p className="small mb-0 text-muted pl-2">Specific schedule: {this.state.scheduleRQ.schedule.alias}</p>
            }
          </Col>
        </Row>

        <Row className="form-group">
          <Col xs="3" className="text-right">
            <Form.Label className="col-form-label">Schedule Type</Form.Label>
          </Col>
          <Col xs="7">
						<SelectInput
              name="ScheduleType"
							preValue={this.state.criteria.ScheduleType}
							handleSelect={this.onSelectInputClick}
						  handleFetchOptions={()=>{}}
              options={this.props.scheduleTypeList}
							optionLabel="displayValue"
							optionValue="key"
							isClearable={false}
							isSearchable={false}
						/>
					</Col>
        </Row>

        <Row className="form-group">
          <Col xs="3" className="text-right">
            <Form.Label className="col-form-label">Mail To*</Form.Label>
          </Col>
          <Col xs="7">
            <EmailInputBox
              preValue={this.state.scheduleRQ.email.emailTo}
              name="emailTo"
              handleSelect={this.onEmailChange}
              isInValid={this.state.formErrorField.includes('emailTo')}
            />
          </Col>
        </Row>

        <Row className="form-group">
          <Col xs="3" className="text-right">
            <Form.Label className="col-form-label">Mail CC</Form.Label>
          </Col>
          <Col xs="7">
            <EmailInputBox
              preValue={this.state.scheduleRQ.email.emailCC}
              name="emailCC"
              handleSelect={this.onEmailChange}
            />
          </Col>
        </Row>

        <Row className="form-group">
          <Col xs="3" className="text-right">
            <Form.Label className="col-form-label">Mail BCC</Form.Label>
          </Col>
          <Col xs="7">
            <EmailInputBox
              preValue={this.state.scheduleRQ.email.emailBCC}
              name="emailBCC"
              handleSelect={this.onEmailChange}
            />
          </Col>
        </Row>

        <Row className="form-group">
          <Col xs="3" className="text-right">
            <Form.Label className="col-form-label">Subject*</Form.Label>
          </Col>
          <Col xs="7">
            <Form.Control
              type="text"
              onChange={(e) => this.onEmailChange(e)}
              name="subject"
              value={this.state.scheduleRQ.email.subject}
              isInvalid={this.state.formErrorField.includes('subject')}
            />
          </Col>
        </Row>

        <Row className="form-group">
          <Col xs="3" className="text-right">
            <Form.Label className="col-form-label">Body</Form.Label>
          </Col>
          <Col xs="7">
            <Form.Control
              as="textarea"
              onChange={(e) => this.onEmailChange(e)}
              name="body"
              value={this.state.scheduleRQ.email.body || ''}
            />
          </Col>
        </Row>
        
        {_map(this.props.reportParams, (ele) =>
          {
            if(!this.isDateControl(ele.controlType)) return(
              <Row className="form-group" key={ele.controlName_EN}>
                <Col xs="3" className="text-right">
                  <Form.Label className="col-form-label">{ele.controlName_EN}{ele.isRequire && '*'}</Form.Label>
                </Col>
                {this.displayControlType(ele)}
              </Row>)
          }
        )}


        <div className="pt-3 text-center">
          {this.state.errorMsg &&
						<p className="red small text-center">{this.state.errorMsg}</p>
					}
          {isEdit &&
            <Button size="lg" className="mr-3" variant="outline-secondary" onClick={() => this.onBackClick()}><FontAwesomeIcon icon={faLongArrowAltLeft}/> Back</Button>
          }

          <Button
            size="lg"
            onClick={() => this.onSubmitClick(isEdit)}
          >{isEdit ? 'Submit Edit' : 'Add New Schedule'}</Button>
        </div>
      </div>
    )
  }

	displayControlType(ele) {
		if (ele.controlType === 'ClientGroup') {
			return (
				<Col xs="7">
					<Form.Group controlId="ClientGroup">
						<SelectInput
							preValue={this.state.criteria.ClientGroup}
							handleSelect={this.onClientSelectClick}
							handleFetchOptions={this.props.getSecurityClientGroup}
							options={this.props.securityClientGroupList}
							optionLabel="displayValue"
							optionValue="key"
							name="ClientGroup"
							isClearable={!ele.isRequire}
							isInValid={this.state.paramErrorField.includes('ClientGroup')}
						/>
					</Form.Group>

					<Form.Group controlId="customer" >
						<Form.Label>Clients*</Form.Label>
						<div className={`${this.state.paramErrorField.includes('Clients') ? 'border-red' : ''} multiselect-wrapper card small`}>
							{this.state.criteria.ClientGroup && this.props.securityClientList ?
								<div className="has-select">
									<div className="py-2 px-2 border-bottom">
										<Form.Group controlId="selectAll" className="mb-0">
											<Form.Check type="checkbox" label="Select All" className="mt-0" checked={this.state.isCheckAllClient} onChange={(e) => this.onCustomerCheck(e)} name="all" />
										</Form.Group>
									</div>
									{_map(this.props.securityClientList, (client, i) =>
										<div className="other-options" key={i}>
											<div className="py-2 px-3 border-bottom">
												<Form.Group controlId={client.key} className="mb-0">
													<Form.Check
														type="checkbox"
														name={client.key}
														label={client.displayValue}
														className="mt-0"
														checked={this.state.criteria.Clients && this.state.criteria.Clients.includes(client.key)}
														onChange={(e) => this.onCustomerCheck(e)}
													/>
												</Form.Group>
											</div>
										</div>
									)}
								</div> :
								<div className="no-select text-center pt-5">
									<p className="pt-4 text-muted">{this.state.isLoadingClients ? <FontAwesomeIcon icon={faSpinner} size="2x" spin /> : 'Please select client'}</p>
								</div>
							}
						</div>
					</Form.Group>
				</Col>
			)
    } 
    else if (ele.controlType === 'TextBox') {
			return (
				<Col xs="7">
					<Form.Control
						value={_get(this.state.criteria, ele.rptParamName, '')}
						name={ele.rptParamName}
						onChange={(e) => this.onFieldChange(e)}
						isInvalid={this.state.paramErrorField.includes(ele.rptParamName)}
					/>
				</Col>
			)
    } 
    else if (ele.controlType === 'SingleSel') {
			if (ele.dataSourceKey === "Airport") {
				return (
					<Col xs="7">
						<AirportSelect
              preValue={_get(this.state.criteria, ele.rptParamName, '')}
							handleSelect={this.onSelectInputClick}
							name={ele.rptParamName}
							isRequire={ele.isRequire}
							isInValid={this.state.paramErrorField.includes(ele.rptParamName)}
							dataSourceKey={ele.dataSourceKey}
							isMulti={false}
						/>
					</Col>
				)
			}
			else{
				return (
					<Col xs="7">
						<SelectInput
							preValue={_get(this.state.criteria, ele.rptParamName, '')}
							handleSelect={this.onSelectInputClick}
						  handleFetchOptions={()=>{}}
							options={ele.dataSource}
							optionLabel="displayValue"
							optionValue="key"
							name={ele.rptParamName}
							isClearable={!ele.isRequire}
							isSearchable={ele.autoCompleteMode === "Append"}
							isInValid={this.state.paramErrorField.includes(ele.rptParamName)}
						/>
					</Col>
				)
			}
    } 
    else if (ele.controlType === 'DateRange') {
			return (
				<Col xs="7">
					<CustomDateRangePicker
						handleSelect={this.onDateRangeSelect}
						name={ele.rptParamName}
						startDateValue={this.state.criteria[`${ele.rptParamName}From`]}
						endDateValue={this.state.criteria[`${ele.rptParamName}To`]}
						index={0}
						isClearAvailable={!ele.isRequire}
						isInvalid={this.state.paramErrorField.includes(`${ele.rptParamName}From`) || this.state.paramErrorField.includes(`${ele.rptParamName}To`)}
					/>
				</Col>
			)
		} else if (ele.controlType === 'Date') {
			return (
				<Col xs="7">
					<CustomDatePicker
						handleSelect={this.onDateSelect}
						name={ele.rptParamName}
						value={this.state.criteria[ele.rptParamName]}
						isClearAvailable={!ele.isRequire}
						isInvalid={this.state.paramErrorField.includes(ele.rptParamName)}
					/>
				</Col>
			)
    } 
    else if (ele.controlType === 'MultiSel') {
			if (ele.dataSourceKey === "Airport") {
				return (
					<Col xs="7">
						<AirportSelect
              preValue={_get(this.state.criteria, ele.rptParamName, [])}
							handleSelect={this.onSelectInputClick}
							name={ele.rptParamName}
							isRequire={ele.isRequire}
							isInValid={this.state.paramErrorField.includes(ele.rptParamName)}
							dataSourceKey={ele.dataSourceKey}
							isMulti={true}
						/>
					</Col>
				)
			}
			else{
				return (
					<Col xs="7">
						<MultiSelectInput
              preValue={_map(_get(this.state.criteria, ele.rptParamName, []), (k) => (_find(ele.dataSource, {'key': k})))}
							handleSelect={(options) => { this.onSelectInputClick(_map(options, 'key'), ele.rptParamName) }}
						  handleFetchOptions={()=>{}}
							options={ele.dataSource}
							optionLabel="displayValue"
							optionValue="key"
							name={ele.rptParamName}
							isInValid={this.state.paramErrorField.includes(ele.rptParamName)}
            />
					</Col>
				)
			}
    } 
    else return ele.controlType
  }
  
  render () {
    return (
      <div>
        <div className="text-center">
          <h3>Schedule</h3>
        </div>

        <Row className="justify-content-center mb-5">
          <Col lg="10">
            <Tabs activeKey={this.state.currentTab} id="schedule-tab" onSelect={(k) => this.onTabClick(k)}>
              <Tab eventKey="edit" title="Edit" className="p-3">
                {!this.props.scheduleDetail &&
                  <div>
                    <Row className="form-group ">
                      <Col xs="3" className="text-right">
                        <Form.Label className="font-weight-bold col-form-label">Report Type</Form.Label>
                      </Col>
                      <Col xs="7">
                        <SelectInput
                          preValue={this.state.selectedReport}
                          handleSelect={this.onReportSelectClick}
                          handleFetchOptions={this.props.getReportType}
                          options={this.props.reportTypeList}
                          optionLabel="displayValue"
                          optionValue="key"
                          isClearable
                        />
                      </Col>
                    </Row>
                    <Row className="form-group ">
                      <Col xs="3" className="text-right">
                        <Form.Label className="font-weight-bold col-form-label">Client</Form.Label>
                      </Col>
                      <Col xs="7">
                        <SelectInput
                          preValue={this.state.selectedCustomer}
                          handleSelect={this.onCustomerSelectClick}
                          handleFetchOptions={this.props.getClientId}
                          options={this.props.clientIdList}
                          optionLabel="displayValue"
                          optionValue="key"
                          isClearable
                        />
                      </Col>
                    </Row>

                    <Row className="pb-3 border-bottom">
                      <Col className="text-center">
                        <Button
                          variant="secondary"
                          className="mr-2"
                          onClick={() => this.onCancelClick()}
                          disabled={!this.props.scheduleSearchResult}
                        >Cancel</Button>
                        <Button variant="primary" onClick={() => this.onSearchClick(0)}><FontAwesomeIcon icon={faSearch} /> Search</Button>
                      </Col>
                    </Row>

                    {this.props.scheduleSearchResult ?
                      this.getResult() :
                      <div className="no-result" />
                    }
                  </div>
                }

                {this.props.scheduleDetail && this.props.reportParams &&
                  this.getEditForm()
                }
              </Tab>


              <Tab eventKey="add-new" title={<div><FontAwesomeIcon icon={faPlus} /> Add New</div>} className="p-3">
                {this.state.currentTab === 'add-new' &&
                  this.getEditForm()
                }
              </Tab>
            </Tabs>
          </Col>
        </Row>

        <ConfirmNavigationModal
          show={this.state.warningPopUp}
          handleCloseTab={this.changeTab}
          currentTab={this.state.currentTab}
        />

      </div>
    );
  }
}

Schedule.propTypes = {
	getReportType: PropTypes.func,
	getClientId: PropTypes.func,
	getScheduleTypes: PropTypes.func,
	getSecurityClientGroup: PropTypes.func,
	getSecurityClient: PropTypes.func,
	searchSchedule: PropTypes.func,
	getScheduleDetail: PropTypes.func,
	getReportParameters: PropTypes.func,
	getScheduleOptions: PropTypes.func,
	clearScheduleDetail: PropTypes.func,
	clearSearchSchedule: PropTypes.func,
	clearReportParameters: PropTypes.func,
	clearAllMsg: PropTypes.func,
	editSchedule: PropTypes.func,
	addNewSchedule: PropTypes.func,
};

function mapStateToProps(state) {
	return {
    reportTypeList: state.report.reportTypeList,
    clientIdList: state.common.clientIdList,
    scheduleTypeList: state.common.scheduleTypeList,
    securityClientGroupList: state.report.securityClientGroupList,
    securityClientList: state.report.securityClientList,
    scheduleDetail: state.schedule.scheduleDetail,
    scheduleSearchResult: state.schedule.scheduleSearchResult,
    scheduleOptions: state.schedule.scheduleOptions,
    shouldRefreshScheduleDetail: state.schedule.shouldRefreshScheduleDetail,
    newAddedSchedule: state.schedule.newAddedSchedule,
    reportParams: state.report.reportParams,
	}
}

const mapDispatchToProps = {
  getReportType,
  getClientId,
  getScheduleTypes,
  getSecurityClientGroup,
  getSecurityClient,
  searchSchedule,
  getScheduleDetail,
  getReportParameters,
  getScheduleOptions,
  clearScheduleDetail,
  clearSearchSchedule,
  clearReportParameters,
  clearAllMsg,
  editSchedule,
  addNewSchedule,
};

export default connect(mapStateToProps, mapDispatchToProps)(Schedule);
