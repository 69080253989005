import fetch from 'node-fetch';
import config from '../config';
import Auth from '../Auth/Auth';
import * as ActionTypes from '../constants/ActionTypes';

const currentConfig = config.development;
const auth = new Auth();

export function getRoles() {
  return (dispatch) => {
    const endpoint = `${currentConfig.serverURL}/Common/option/get?type=AdminRole`;

		dispatch({
			type: ActionTypes.GET_ROLES_REQUEST,
		})

    fetch(endpoint, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
        'Token': auth.getAccessToken(),
        'RESULTSTRUCT': true
			}
		})
			.then(response => response.json())
			.then((result) => {
        if (result.success) {
          dispatch({
            type: ActionTypes.GET_ROLES_SUCCESS,
            payload: result.data
          })
        } else {
          dispatch({
  					type: ActionTypes.GET_ROLES_FAILURE,
  					payload: null,
            msg: `Get roles failed. <b>${result.msg}</b>`,
            result: result
  				})
        }
			})
			.catch((error) => {
				dispatch({
					type: ActionTypes.GET_ROLES_FAILURE,
					error,
					payload: null,
          msg: `Get roles failed. API issue.`
				})
			});
	};
}

export function getRoleDetail(id) {
  return (dispatch) => {
    const endpoint = `${currentConfig.serverURL}/Admin/role/${id}`;

		dispatch({
			type: ActionTypes.GET_ROLE_DETAIL_REQUEST,
		})

    fetch(endpoint, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
        'Token': auth.getAccessToken(),
        'RESULTSTRUCT': true
			}
		})
			.then(response => response.json())
			.then((result) => {
        if (result.success) {
          dispatch({
            type: ActionTypes.GET_ROLE_DETAIL_SUCCESS,
            payload: result.data
          })
        } else {
          dispatch({
  					type: ActionTypes.GET_ROLE_DETAIL_FAILURE,
  					payload: null,
            msg: `Get role detail failed. <b>${result.msg}</b>`
  				})
        }
			})
			.catch((error) => {
				dispatch({
					type: ActionTypes.GET_ROLE_DETAIL_FAILURE,
					error,
					payload: null,
          msg: `Get role detail failed. API issue.`
				})
			});
	};
}

export function editRole(form) {
  return (dispatch) => {
    const endpoint = `${currentConfig.serverURL}/Admin/role/edit`;
    const body = {
      "roleID": form.roleID,
      "roleName": form.roleName,
      "finYearMonth": form.finYearMonth,
      "countryCode": form.countryCode,
      "currency": form.currency,
      "members": form.members
    }

		dispatch({
			type: ActionTypes.EDIT_ROLE_REQUEST,
      body: body
		})

    fetch(endpoint, {
			method: 'PUT',
      body: JSON.stringify(body),
			headers: {
				'Content-Type': 'application/json',
        'Token': auth.getAccessToken(),
        'RESULTSTRUCT': true
			}
		})
			.then(response => response.json())
			.then((result) => {
        if (result.success) {
          dispatch({
            type: ActionTypes.EDIT_ROLE_SUCCESS,
            payload: result.data,
            msg: `Edit role <b>${form.roleName}</b> succeed.`
          })
        } else {
          dispatch({
  					type: ActionTypes.EDIT_ROLE_FAILURE,
  					payload: null,
            msg: `Edit role failed. <b>${result.msg}</b>`
  				})
        }
			})
			.catch((error) => {
				dispatch({
					type: ActionTypes.EDIT_ROLE_FAILURE,
					error,
					payload: null,
          msg: `Edit role failed. API issue.`
				})
			});
	};
}

export function addNewRole(form) {
  return (dispatch) => {
    const endpoint = `${currentConfig.serverURL}/Admin/role/add`;
    const body = {
      "roleID": form.roleID,
      "roleName": form.roleName,
      "finYearMonth": form.finYearMonth,
      "countryCode": form.countryCode,
      "currency": form.currency,
      "members": form.members
    }

		dispatch({
			type: ActionTypes.ADD_ROLE_REQUEST,
      body: body
		})

    fetch(endpoint, {
			method: 'POST',
      body: JSON.stringify(body),
			headers: {
				'Content-Type': 'application/json',
        'Token': auth.getAccessToken(),
        'RESULTSTRUCT': true
			}
		})
			.then(response => response.json())
			.then((result) => {
        if (result.success) {
          dispatch({
            type: ActionTypes.ADD_ROLE_SUCCESS,
            payload: result.data,
            msg: `Add role <b>${form.roleName}</b> succeed.`
          })
        } else {
          dispatch({
  					type: ActionTypes.ADD_ROLE_FAILURE,
  					payload: null,
            msg: `Add role failed. <b>${result.msg}</b>`,
            result: result
  				})
        }
			})
			.catch((error) => {
				dispatch({
					type: ActionTypes.ADD_ROLE_FAILURE,
					error,
					payload: null,
          msg: `Add role failed. API issue.`
				})
			});
	};
}

export function clearRoleDetail() {
  return (dispatch) => {
    dispatch({
			type: ActionTypes.CLEAR_ROLE_DETAIL,
		})
	};
}
