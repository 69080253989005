import React from "react";
import Loading from "../components/Loading";
import { login } from "../actions/index"; // Import your actions
import { connect } from "react-redux";

class SSOCallback extends React.Component {
  constructor(props) {
    super(props);
    this.accessToken = new URLSearchParams(window.location.search).get(
      "accessToken"
    );
  }

  componentDidMount() {
    this.mounted = true;
    console.log(this.accessToken);
    if (this.accessToken) {
      this.props.login("", "", this.accessToken);
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  render() {
    return (
      <div>
        SSO Loading...Please wait
        <Loading />
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {};
}

const mapDispatchToProps = {
  login,
};

export default connect(mapStateToProps, mapDispatchToProps)(SSOCallback);
