import React from 'react';
import ReactDOM from 'react-dom';
import App from './container/App';
import * as serviceWorker from './serviceWorker';
import fetch from 'node-fetch';
import config from './config';
import { genBrow } from './util/common';

const currentConfig = config.development;
const endpoint = `${currentConfig.serverURL}/Common/log`;
genBrow();

ReactDOM.render(<App />, document.getElementById('root'));
window.addEventListener('error', (e) => {
    sendUILog(e.type, e.message);
}, true);
window.addEventListener("unhandledrejection", e => {
    e.preventDefault();
    sendUILog(e.type, e.message);
});
// Promise.reject('promiseError');
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
function sendUILog(type, msg) {
    fetch(endpoint, {
        method: 'POST',
        body: JSON.stringify({
            UIType: type,
            UIMsg: msg,
            UIUrl: window.location.href,
            UIBrow: localStorage.brow
        }),
        headers: {
            'Content-Type': 'application/json',
            'RESULTSTRUCT': true
        }
    }).catch(() => { });
}

