import * as ActionTypes from '../constants/ActionTypes';

const initialState = {
	countryCodeList: null,
	groupList: null,
	sourceList: null,
	clientNameList: null,
	clientIdList: null,
	scheduleTypeList: null,
	debtorCodeList: null,
	clientGroupList: null,
	airportList: null,
	globalSettings:  {},
};

const common = (state = initialState, action) => {
	switch (action.type) {
	case ActionTypes.GET_COUNTRY_SUCCESS:
		return Object.assign({}, state, {
			countryCodeList: action.payload
		});
	case ActionTypes.GET_GROUPS_SUCCESS:
		return Object.assign({}, state, {
			groupList: action.payload
		});
	case ActionTypes.GET_SOURCE_SUCCESS:
		return Object.assign({}, state, {
			sourceList: action.payload
		});
	case ActionTypes.GET_AIRPORT_SUCCESS:
		return Object.assign({}, state, {
			airportList: action.payload
		});
	case ActionTypes.GET_ScheduleType_SUCCESS:
		return Object.assign({}, state, {
			scheduleTypeList: action.payload
	});
	case ActionTypes.GET_CLIENT_NAME_SUCCESS:
		return Object.assign({}, state, {
			clientNameList: action.payload
		});
	case ActionTypes.GET_CLIENT_ID_SUCCESS:
		return Object.assign({}, state, {
			clientIdList: action.payload
		});
	case ActionTypes.GET_DEBTOR_CODE_SUCCESS:
		return Object.assign({}, state, {
			debtorCodeList: action.payload
		});
	case ActionTypes.GET_ALL_CLIENT_GROUP_SUCCESS:
		return Object.assign({}, state, {
			clientGroupList: action.payload
		});
	case ActionTypes.CLEAR_SEARCH_CLIENT:
	case ActionTypes.CLEAR_CLIENT_GROUP_DETAIL:
		return Object.assign({}, state, {
			debtorCodeList: null,
			clientGroupList: null,
		});
	case ActionTypes.CLEAR_SEARCH_SCHEDULE:
		return Object.assign({}, state, {
			clientIdList: null,
		});
	case ActionTypes.INIT_SUCCESS:
		return Object.assign({}, state, {
			globalSettings: action.payload,
		});
	default:
		return state;
	}
};

export default common;
