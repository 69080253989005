import React from 'react';
import PropTypes from 'prop-types';
import {ButtonGroup, DropdownButton, Dropdown, Button} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronLeft, faChevronRight} from '@fortawesome/free-solid-svg-icons';

const Pagination = (props) => {
	let items = [];
	for (let number = 0; number < props.noOfPages; number++) {
	  items.push(
			<Dropdown.Item
				eventKey={number}
				key={number}
				active={number === props.currentPage}
				onClick={() => props.handlePageChange(number)}
			>
				{number + 1}
			</Dropdown.Item>
	  );
	}
	return (
		<div className="text-center">
			<ButtonGroup>
			  <Button
					variant="none"
					className="border"
					onClick={() => props.handlePageChange(props.currentPage - 1)}
					disabled={props.currentPage === 0}
				><FontAwesomeIcon icon={faChevronLeft} /></Button>

			  <DropdownButton
					variant="none"
					as={ButtonGroup}
					title={props.currentPage + 1}
					id="page-nested-dropdown"
					className="pagination-dropdown border"
				>
			    {items}
			  </DropdownButton>

				<Button
					variant="none"
					className="border"
					onClick={() => props.handlePageChange(props.currentPage + 1)}
					disabled={props.currentPage === props.noOfPages - 1}
				><FontAwesomeIcon icon={faChevronRight} /></Button>
			</ButtonGroup>
		</div>
  );
}

Pagination.propTypes = {
	noOfPages: PropTypes.number,
	currentPage: PropTypes.number,
	handlePageChange: PropTypes.func
};

export default Pagination;
