import * as ActionTypes from '../constants/ActionTypes';
import moment from 'moment';
import _assign from 'lodash/assign';

const initialState = {
	roleDetail: null,
	roleList: null,
	shouldRefreshRoleDetail: false,
	newAddedRole: {
		timestamp: null
	},
};

const user = (state = initialState, action) => {
	switch (action.type) {
	case ActionTypes.GET_ROLES_SUCCESS:
		return Object.assign({}, state, {
			roleList: action.payload
		});
	case ActionTypes.GET_ROLE_DETAIL_SUCCESS:
		return Object.assign({}, state, {
			roleDetail: action.payload,
			shouldRefreshRoleDetail: false
		});
	case ActionTypes.EDIT_ROLE_SUCCESS:
		return Object.assign({}, state, {
			shouldRefreshRoleDetail: true,
		});
	case ActionTypes.ADD_ROLE_SUCCESS:
		return Object.assign({}, state, {
			newAddedRole: _assign({timestamp: moment().valueOf()}, action.payload)
		});
	case ActionTypes.CLEAR_ROLE_DETAIL:
		return Object.assign({}, state, {
			roleDetail: null,
		});
	default:
		return state;
	}
};

export default user;
