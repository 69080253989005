import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Iframe from 'react-iframe'

import ReportSearchPanel from '../components/Report/ReportSearchPanel';
import Loading from '../components/Loading';

import {clearAllMsg} from '../actions/index';
import {
  getReportType,
  getSecurityClientGroup,
  getSecurityClient,
  getReportParameters,
  clearReportParameters,
} from '../actions/report';
import {getAirports} from '../actions/common';
import fetch from 'node-fetch';
import toaster from 'toasted-notes' ;
import {Alert} from 'react-bootstrap';
import config from '../config';
import Auth from '../Auth/Auth';

const auth = new Auth();

class Report extends Component {
  constructor(props) {
    super(props);

    this.onViewReport = this.onViewReport.bind(this);
    this.onRestClick = this.onRestClick.bind(this);
    this.onTogglePanel = this.onTogglePanel.bind(this);

    this.state = {
      iframeURL: null,
			isPanelOpen: true,
      loading: false
    };
  }

  componentDidMount() {
  }

	componentDidUpdate(prevProps, prevState) {
	}

  componentWillUnmount() {
    this.props.clearReportParameters()
  }

  onViewReport(criteria) {
    if (this.state.iframeURL) {
      this.setState({
        iframeURL: null
      })

      setTimeout(function(){
        this.searchReport(criteria)
      }.bind(this), 500);

    } else {
      this.searchReport(criteria)
    }

  }

  onRestClick() {
    this.props.clearReportParameters()
    this.setState({
      iframeURL: null,
    })
  }

  onTogglePanel() {
		this.setState({
			isPanelOpen: !this.state.isPanelOpen
		})
	}

  searchReport(criteria) {
    // let iframURLink = `https://data.travelctm.asia/CAD/RptAPI/Viewer/SSRSByLink3`

    this.setState({
      loading: true,
      isPanelOpen: true
    })
    fetch(`${config.development.serverURL}/Report/token`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Token': auth.getAccessToken(),
        'RESULTSTRUCT': true
      }
    })
      .then(res => res.json())
      .then(result => {
        if(result.success){
          fetch(`${config.development.reportURL}/Rdl/SSRS`, {
            method: 'POST',
            body: JSON.stringify(criteria),
            headers: {
              'Content-Type': 'application/json',
              'Token': result.data,
            }
          })
            .then(res => res.text())
            .then(res => {
              if(res.indexOf('$cacheKey$') !== -1){
                this.setState({
                  iframeURL: `${config.development.reportURL}/RdlReportViewer.aspx?cacheKey=${res.substring(10)}`
                })
              }
              else{
                this.handleError(res)
              }
            })
            .catch((error) => {
              this.handleError()
            })
        }
        else{
          this.handleError(result.msg)
        }
      })
      .catch((error) => {
        this.handleError()
      })
  }
  handleError(msg){
    let err = 'Error'
    if(msg){
      err = msg
    }
    this.setState({
      loading: false
    })
    toaster.notify(({ onClose }) => (
      <Alert
        variant="danger"
        className="alert-msg mb-0"
        dismissible
        onClose={onClose}
      >
        {err}
      </Alert>
    ), { duration: 3000 })
  }

  hideSpinner = () => {
    this.setState({
      loading: false,
      isPanelOpen: false,
    });
  };

  render () {
    return (
      <div className="report-container">
        <ReportSearchPanel
          reportTypeList={this.props.reportTypeList}
          handleTogglePanelClick={this.onTogglePanel}
          handleFetchReport={this.props.getReportType}
          handleReportSelect={this.props.getReportParameters}
          handleGetSecurityClientGroup={this.props.getSecurityClientGroup}
          handleGetAirport={this.props.getAirports}
          handleGetSecurityClient={this.props.getSecurityClient}
          handleClearReportParam={this.onRestClick}
          handleViewReport={this.onViewReport}
          reportParams={this.props.reportParams}
          securityClientGroupList={this.props.securityClientGroupList}
          securityClientList={this.props.securityClientList}
          airportList={this.props.airportList}
          isOpen={this.state.isPanelOpen}
        />

        {this.state.loading ? (
          <Loading isAbsolute />
        ) : null}

        {this.state.iframeURL ?
          <Iframe url={this.state.iframeURL}
            width="100%"
            height="100%"
            position="relative"
            className="mr-3"
            onLoad={this.hideSpinner}
          />:
          <div className="no-result" />
        }

      </div>
    );
  }
}

Report.propTypes = {
	getReportType: PropTypes.func,
	getReport: PropTypes.func,
  getSecurityClientGroup: PropTypes.func,
  getAirports: PropTypes.func,
  getSecurityClient: PropTypes.func,
  clearReportParameters: PropTypes.func,
};

function mapStateToProps(state) {
	return {
    reportTypeList: state.report.reportTypeList,
    reportParams: state.report.reportParams,
    securityClientGroupList: state.report.securityClientGroupList,
    securityClientList: state.report.securityClientList,
    airportList: state.common.airportList,
    reportViwer: state.report.reportViwer,
	}
}

const mapDispatchToProps = {
  getReportType,
  getSecurityClientGroup,
  getAirports,
  getSecurityClient,
  getReportParameters,
  clearReportParameters,
  clearAllMsg,
};

export default connect(mapStateToProps, mapDispatchToProps)(Report);
