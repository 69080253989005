import fetch from 'node-fetch';
import history from '../history';
import cookie from "universal-cookie";
import config from '../config';
import moment from 'moment';

import _isObject from 'lodash/isObject';

const cookies = new cookie();
const currentConfig = config.development;

//var authObj = cookies.get('ctmAisaDataAuthObj');
//console.log('authObj', authObj)

export default class Auth {

  constructor() {
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.hasSession = this.hasSession.bind(this);
    this.getAccessToken = this.getAccessToken.bind(this);
    this.getName = this.getName.bind(this);
    this.getEmail = this.getEmail.bind(this);
    this.getApiVersion = this.getApiVersion.bind(this);
    this.renewSession = this.renewSession.bind(this);
    this.getAuthObjVal = this.getAuthObjVal.bind(this);
  }

  login(username, password) {
    const endpoint = `${currentConfig.serverURL}/Security/login`;
    const body = {
      "Email": username,
      "Password": password
    }

    fetch(endpoint, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        'RESULTSTRUCT': true
      },
    })
      .then(response => response.json())
      .then((result) => {
        if (result.success) {
          this.setSession(result.data);
        } else {
          alert('Login failed. Wrong email/password')
        }
      })
      .catch((error) => {
        alert('Login failed. API issue.')
      });
  }

  handleAuthentication() {
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult);
      } else if (err) {
        history.replace(process.env.PUBLIC_URL);
        //console.log(err);
        alert(`Error: ${err.error}. Check the console for further details.`);
      }
    });
  }

  getAccessToken() {
    return this.getAuthObjVal('accessToken');
  }

  getName() {
    return this.getAuthObjVal('userName');
  }

  getEmail() {
    return this.getAuthObjVal('email');
  }
  isAdmin(){
    return this.getAuthObjVal('isAdmin');
  }
  getApiVersion() {
    return this.getAuthObjVal('apiVersion');
  }
  getAuthObjVal(key){
    let obj = cookies.get('ctmAisaDataAuthObj');
    if(obj != null) {
      return obj[key];
    }
    return "";
  }
  setSession(authResult) {
    //console.log('setSession', authResult)
    // Set the time that the access token will expire at
    this.accessToken = authResult.access_Token;
    this.refreshToken = authResult.refresh_Token;
    // this.expiresAt = moment().add(2, 's').format();
    this.expiresAt = authResult.expires_In;
    this.userName = authResult.userName;
    this.email = authResult.email;

    cookies.set('ctmAisaDataAuthObj',{
      accessToken: this.accessToken,
      refreshToken: this.refreshToken,
      expiresAt: this.expiresAt,
      userName: this.userName,
      email: this.email,
      apiVersion: authResult.apiVersion,
      isAdmin: authResult.isAdmin
    } , {path: '/'});

    // refresh page
    // window.location.reload();
    window.location.href = `${process.env.PUBLIC_URL}/`;
  }

  renewSession(needRefresh) {
    //console.log('renewSession')
    let accessToken = this.getAccessToken();
    let refreshToken = this.getAuthObjVal('refreshToken');
    if(accessToken && refreshToken) {
      const endpoint = `${currentConfig.serverURL}/Security/rtoken`;
      const body = {
        "refreshToken": refreshToken,
      }

      fetch(endpoint, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json'
        },
      })
        .then(response => response.json())
        .then((result) => {
          if (_isObject(result)) {
            cookies.set('ctmAisaDataAuthObj',{
              accessToken: result.access_Token,
              refreshToken: refreshToken,
              expiresAt: result.expires_In, // moment().add(2, 's').format(),
              userName: this.getAuthObjVal('userName'),
              email: this.getAuthObjVal('email'),
              apiVersion: this.getAuthObjVal('apiVersion'),
              isAdmin: this.isAdmin()
            } , {path: '/'});

            //console.log('refreshed!', cookies.get('ctmAisaDataAuthObj'))

            if (needRefresh) {

              window.location.reload();
            }
          } else {
            this.logout();
            alert(`Renew session error. Could not get a new token. You are logged out.`);
          }
        })
        .catch((error) => {
          this.logout();
          alert('Renew session error. You are logged out.', error);
        });
    } else {
      this.logout();
      alert(`Could not get a new token.`);
    }
  }

  logout() {
    cookies.remove('ctmAisaDataAuthObj', { path: '/' });
    // refresh page
    // history.replace(process.env.PUBLIC_URL);
    window.location.reload();
  }

  hasSession() {
    return cookies.get('ctmAisaDataAuthObj') != null
  }

  isExpired() {
    // Check whether the current time > access token's expiry time
    let expiresAt = this.getAuthObjVal('expiresAt')
    if (expiresAt) {
      if(moment().isBefore(expiresAt)) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }

  }
}
