import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import Auth from '../Auth/Auth';

import { Card, Button, Form, InputGroup, Modal } from 'react-bootstrap';

import Loading from '../components/Loading';
import PwdRules from '../components/PwdRules';
import config from '../config';
import { getUrlParams } from '../util/common';

import { login, forget, reset, checkEmail } from '../actions/index'; // Import your actions
import _ from 'lodash'

class LoginPage extends Component {
	constructor(props) {
		super(props);
		this.onLoginClick = this.onLoginClick.bind(this);
		this.onForgetClick = this.onForgetClick.bind(this);
		this.onResetClick = this.onResetClick.bind(this);
		this.onFieldChange = this.onFieldChange.bind(this);
		this.onCheckEmail = this.onCheckEmail.bind(this);
		this.deboundCheckEmail = _.debounce(this.onCheckEmail, 500);

		const authKey = getUrlParams('auth');
		const email = getUrlParams('email');
		const isReset = authKey && email;
		this.state = {
			username: '',
			password: '',
			toLogin: !isReset,
			toForget: false,
			toReset: isReset,
			forgetEmail: '',
			resetEmail: email,
			newPwd: '',
			confirmPwd: '',
			authKey: authKey,
			resetValidated: false,
			forgetValidated: false,
			checkEmailFeedback: 'Invalid email format.',
			inValidEmailFormat: void 0,
			pwdUnMatch: false,
			showResetModel: false,
		}
	}

	onLoginClick() {
		this.props.login(this.state.username, this.state.password)
	}

	async onForgetClick(event) {
		event.preventDefault();
		event.stopPropagation();
		if (await this.onCheckEmail(event)) {
			await this.props.forget(this.state.forgetEmail)
		}
		const elem = this.refs.sendToEmailRef;
		if(this.props.requestError && elem && elem.setCustomValidity){
			elem.setCustomValidity(this.props.responeMsg);
			this.setState({
				checkEmailFeedback: this.props.responeMsg,
			})
		}
		this.setState({
			forgetValidated: true
		})
	}

	onResetClick(event) {
		if (this.checkForm(event)) {
			let isMacth = this.state.newPwd === this.state.confirmPwd
			this.setState({
				pwdUnMatch: !isMacth
			})
			if (isMacth) {
				this.props.reset({
					authKey: this.state.authKey,
					password: this.state.newPwd
				}, () => {
					this.setState({
						showResetModel: true
					})
				})
			}
		}
		this.setState({
			resetValidated: true
		})
	}

	async onCheckEmail(event) {
		const elem = this.refs.sendToEmailRef;
		const checkRS = await checkEmail(this.state.forgetEmail);
		elem && elem.setCustomValidity && elem.setCustomValidity("");
		if (!checkRS.validEmail) {
			elem && elem.setCustomValidity && elem.setCustomValidity(checkRS.message);
			this.setState({
				forgetValidated: true,
				checkEmailFeedback: checkRS.message,
			})
		}
		return checkRS.validEmail;
	}

	checkForm(event) {
		const form = event.currentTarget;
		event.preventDefault();
		event.stopPropagation();
		return form.checkValidity();
	}

	onFieldChange(e) {
		this.setState({
			[e.target.name]: e.target.value
		})
	}

	toLogin() {
		this.setState({
			toLogin: true,
			toForget: false,
			toReset: false
		})
	}
	toForget() {
		this.setState({
			toLogin: false,
			toForget: true,
			toReset: false
		})
	}

	onKeyDown(e) {
		if (e.key === 'Enter') {
			this.onLoginClick()
		}
	}

	render() {
		return (
			<div className="page-login" onKeyDown={(e) => this.onKeyDown(e)}>
				<Card style={{ width: '18rem' }}>
					<Card.Img variant="top" src={process.env.PUBLIC_URL + "/images/" + this.props.globalSettings.loginLogo} alt="logo" style={{ objectFit: 'scale-down' }} />
					<Card.Body>
						<Card.Title className="text-center">{this.props.globalSettings.loginTitle}</Card.Title>
						{this.state.toLogin ?
							<div>
								<div>
									<Form.Label className="col-form-label">Username</Form.Label>
									<Form.Control
										type="text"
										name="username"
										onChange={(e) => this.onFieldChange(e)}
										value={this.state.username}
									/>
								</div>
								<div className="pb-4">
									<Form.Label className="col-form-label">Password</Form.Label>
									<InputGroup>
										<Form.Control
											type="password"
											name="password"
											onChange={(e) => this.onFieldChange(e)}
											value={this.state.password}
										/>
										{this.props.globalSettings.useLocalCADLogin ? <Button variant="outline-secondary" id="button-addon2" onClick={() => this.toForget()}>
											Forget?
										</Button> : null}
									</InputGroup>
								</div>
								<div className="text-center">
									<Button variant="primary" onClick={() => this.onLoginClick()}>Login</Button>
								</div>
							</div> : null}
						{this.state.toForget ?
							<div>
								<Form noValidate validated={this.state.forgetValidated} onSubmit={this.onForgetClick}>
									<div className="pb-4">
										<div className="h6" style={{ color: 'black' }}>Forgot your password?</div>
										<Form.Group className="mb-0">
											<Form.Label className="col-form-label" style={{ fontSize: '12px', color: 'gray' }}>Please enter your email to get your password.</Form.Label>
											<Form.Control
												ref='sendToEmailRef'
												type="email"
												required
												name="forgetEmail"
												onChange={(e) => {
													this.deboundCheckEmail(e);
													this.onFieldChange(e);
												}}
												value={this.state.forgetEmail}
											/>
											<Form.Control.Feedback type="invalid">
												{this.state.checkEmailFeedback}
											</Form.Control.Feedback>
										</Form.Group>
										<PwdRules />
									</div>
									<div className="text-center">
										<Button variant="primary" type="submit" className="mr-4">Submit</Button>
										<Button variant="primary" onClick={() => this.toLogin()}>Back</Button>
									</div>
								</Form>
							</div> : null}
						{this.state.toReset ?
							<div>
								<Form noValidate validated={this.state.resetValidated} onSubmit={this.onResetClick}>
									<div className="pb-4">
										<div className="h6 mb-3" style={{ color: 'black' }}>Reset Password</div>
										<Form.Control
											type="text"
											name="resetEmail"
											value={this.state.resetEmail}
											readOnly
										/>
										<Form.Group className="mb-0">
											<Form.Label className="col-form-label" style={{ fontSize: '12px', color: 'gray' }}>new password</Form.Label>
											<Form.Control
												type="password"
												name="newPwd"
												required
												onChange={(e) => this.onFieldChange(e)}
												value={this.state.newPwd}
											/>
										</Form.Group>
										<Form.Group className="mb-0">
											<Form.Label className="col-form-label" style={{ fontSize: '12px', color: 'gray' }}>confirmation password</Form.Label>
											<Form.Control
												type="password"
												name="confirmPwd"
												required
												onChange={(e) => this.onFieldChange(e)}
												isInvalid={this.state.pwdUnMatch}
												value={this.state.confirmPwd}
											/>
											<Form.Control.Feedback type="invalid">
												Two passwords do not match
											</Form.Control.Feedback>
										</Form.Group>
										<PwdRules />
									</div>
									<div className="text-center">
										<Button variant="primary" type="submit" className="mr-4">Submit</Button>
									</div>
								</Form>
							</div> : null}
						<div className="version">Version {config.version}</div>
					</Card.Body>
				</Card>

				{this.props.isLoading && <Loading />}
				<Modal centered show={this.state.showResetModel}>
					<Modal.Header>
						<Modal.Title>Information</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<p>Reset Password Succeed!</p>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="primary" onClick={() => window.location.href = window.location.origin + window.location.pathname}>
							OK
						</Button>
					</Modal.Footer>
				</Modal>
			</div>
		);
	}
}

LoginPage.propTypes = {
	isLoading: PropTypes.bool,
};

function mapStateToProps(state) {
	return {
		isLoading: state.loading.pageLoading,
		globalSettings: state.common.globalSettings,
		responeMsg: state.msg.msg,
		requestError: state.msg.isError
	}
}

const mapDispatchToProps = {
	login, forget, reset
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
