import React, {Component} from 'react';
// import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// import {getUnreadMsg} from '../actions/index';

class Dashboard extends Component {
	constructor(props) {
		super(props);

    // this.onPageChange = this.onPageChange.bind(this);

    this.state = {
      currentPage: 0
    }
	}

  componentDidMount() {
  }

  // onPageChange(val) {
  //   if (this.props.currentPage !== val) {
  //     this.setState({
  //       currentPage: val
  //     })
  //   }
  // }

  render() {
    return (
      <div>
				<div>
					<h3 className="text-center">Dashboard</h3>
					<div className="dashboard-content" />
				</div>
      </div>
    );
  }
}

Dashboard.propTypes = {
	// unreadMsg: PropTypes.array,
	// messageCount: PropTypes.number,
};

function mapStateToProps(state) {
	return {
    // unreadMsg: state.general.unreadMsg,
    // loading: state.loading.pageLoading,
    // messageCount: state.general.messageCount,
	}
}

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
