import * as ActionTypes from '../constants/ActionTypes';
import moment from 'moment';

const initialState = {
	scheduleSearchResult: null,
	scheduleDetail: null,
	shouldRefreshScheduleDetail: false,
	newAddedSchedule: {
		timestamp: null,
		reportId: null,
		subscriptionId: null
	},
	retrivedschedule: null,
	scheduleOptions: null,
};

const user = (state = initialState, action) => {
	switch (action.type) {
	case ActionTypes.SEARCH_SCHEDULE_SUCCESS:
	case ActionTypes.SEARCH_SCHEDULE_FAILURE:
		return Object.assign({}, state, {
			scheduleSearchResult: action.payload,
		});
	case ActionTypes.GET_SCHEDULE_DETAIL_SUCCESS:
	case ActionTypes.GET_SCHEDULE_DETAIL_FAILURE:
		return Object.assign({}, state, {
			scheduleDetail: action.payload,
			shouldRefreshScheduleDetail: false
		});
	case ActionTypes.GET_SCHEDULE_OPTIONS_SUCCESS:
		return Object.assign({}, state, {
			scheduleOptions: action.payload
		});
	case ActionTypes.EDIT_SCHEDULE_SUCCESS:
		return Object.assign({}, state, {
			shouldRefreshScheduleDetail: true,
		});
	case ActionTypes.ADD_SCHEDULE_SUCCESS:
		return Object.assign({}, state, {
			newAddedSchedule: {
				timestamp: moment().valueOf(),
				reportId: action.payload.reportId,
				subscriptionId: action.payload.subscriptionId
			}
		});
	case ActionTypes.CLEAR_SCHEDULE_DETAIL:
		return Object.assign({}, state, {
			scheduleDetail: null,
			scheduleOptions: null,
		});
	case ActionTypes.CLEAR_SEARCH_SCHEDULE:
		return Object.assign({}, state, {
			scheduleSearchResult: null,
		});
	default:
		return state;
	}
};

export default user;
