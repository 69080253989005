import fetch from 'node-fetch';
import config from '../config';
import Auth from '../Auth/Auth';
import * as ActionTypes from '../constants/ActionTypes';

const currentConfig = config.development;
const auth = new Auth();

export function getGroups() {
  return (dispatch) => {
    const endpoint = `${currentConfig.serverURL}/Common/option/get?type=AdminGroup`;

		dispatch({
			type: ActionTypes.GET_GROUPS_REQUEST,
		})

    fetch(endpoint, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
        'Token': auth.getAccessToken(),
        'RESULTSTRUCT': true
			}
		})
			.then(response => response.json())
			.then((result) => {
        if (result.success) {
          dispatch({
            type: ActionTypes.GET_GROUPS_SUCCESS,
            payload: result.data
          })
        } else {
          dispatch({
  					type: ActionTypes.GET_GROUPS_FAILURE,
  					payload: null,
            msg: `Get groups failed. <b>${result.msg}</b>`,
            result: result
  				})
        }
			})
			.catch((error) => {
				dispatch({
					type: ActionTypes.GET_GROUPS_FAILURE,
					error,
					payload: null,
          msg: `Get groups failed. API issue.`
				})
			});
	};
}

export function getCountryCode() {
  return (dispatch) => {
    const endpoint = `${currentConfig.serverURL}/Common/option/get?type=Country`;

    dispatch({
      type: ActionTypes.GET_COUNTRY_REQUEST,
    })

    fetch(endpoint, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Token': auth.getAccessToken(),
        'RESULTSTRUCT': true
      }
    })
    .then(response => response.json())
    .then((result) => {
      if (result.success) {
        dispatch({
          type: ActionTypes.GET_COUNTRY_SUCCESS,
          payload: result.data
        })
      } else {
        dispatch({
          type: ActionTypes.GET_COUNTRY_FAILURE,
          payload: null,
          msg: `Get country code failed. <b>${result.msg}</b>`
        })
      }
    })
    .catch((error) => {
      dispatch({
        type: ActionTypes.GET_COUNTRY_FAILURE,
        error,
        payload: null,
        msg: `Get country code failed. API issue.`
      })
    });
  };
}

export function getDebtorCode() {
  return (dispatch) => {
    const endpoint = `${currentConfig.serverURL}/Common/option/get?type=ClientDebtorCode`;

    dispatch({
      type: ActionTypes.GET_DEBTOR_CODE_REQUEST,
    })

    fetch(endpoint, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Token': auth.getAccessToken(),
        'RESULTSTRUCT': true
      }
    })
    .then(response => response.json())
    .then((result) => {
      if (result.success) {
        dispatch({
          type: ActionTypes.GET_DEBTOR_CODE_SUCCESS,
          payload: result.data
        })
      } else {
        dispatch({
          type: ActionTypes.GET_DEBTOR_CODE_FAILURE,
          payload: null,
          msg: `Get debtor code failed. <b>${result.msg}</b>`
        })
      }
    })
    .catch((error) => {
      dispatch({
        type: ActionTypes.GET_DEBTOR_CODE_FAILURE,
        error,
        payload: null,
        msg: `Get debtor code failed. API issue.`
      })
    });
  };
}

export function getClientName() {
  return (dispatch) => {
    const endpoint = `${currentConfig.serverURL}/Common/option/get?type=ClientName`;

    dispatch({
      type: ActionTypes.GET_CLIENT_NAME_REQUEST,
      endpoint: endpoint
    })

    fetch(endpoint, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Token': auth.getAccessToken(),
        'RESULTSTRUCT': true
      }
    })
    .then(response => response.json())
    .then((result) => {
      if (result.success) {
        dispatch({
          type: ActionTypes.GET_CLIENT_NAME_SUCCESS,
          payload: result.data
        })
      } else {
        dispatch({
          type: ActionTypes.GET_CLIENT_NAME_FAILURE,
          payload: null,
          msg: `Get client name failed. <b>${result.msg}</b>`
        })
      }
    })
    .catch((error) => {
      dispatch({
        type: ActionTypes.GET_CLIENT_NAME_FAILURE,
        error,
        payload: null,
        msg: `Get client name failed. API issue.`
      })
    });
  };
}

export function getClientId() {
  return (dispatch) => {
    const endpoint = `${currentConfig.serverURL}/Common/option/get?type=ClientIdName`;

    dispatch({
      type: ActionTypes.GET_CLIENT_ID_REQUEST,
      endpoint: endpoint
    })

    fetch(endpoint, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Token': auth.getAccessToken(),
        'RESULTSTRUCT': true
      }
    })
    .then(response => response.json())
    .then((result) => {
      if (result.success) {
        dispatch({
          type: ActionTypes.GET_CLIENT_ID_SUCCESS,
          payload: result.data
        })
      } else {
        dispatch({
          type: ActionTypes.GET_CLIENT_ID_FAILURE,
          payload: null,
          msg: `Get client ID failed. <b>${result.msg}</b>`
        })
      }
    })
    .catch((error) => {
      dispatch({
        type: ActionTypes.GET_CLIENT_ID_FAILURE,
        error,
        payload: null,
        msg: `Get client ID failed. API issue.`
      })
    });
  };
}

export function getAllClientGroup() {
  return (dispatch) => {
    const endpoint = `${currentConfig.serverURL}/Common/option/get?type=ClientGroup`;

    dispatch({
      type: ActionTypes.GET_ALL_CLIENT_GROUP_REQUEST,
      endpoint: endpoint
    })

    fetch(endpoint, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Token': auth.getAccessToken(),
        'RESULTSTRUCT': true
      }
    })
    .then(response => response.json())
    .then((result) => {
      if (result.success) {
        dispatch({
          type: ActionTypes.GET_ALL_CLIENT_GROUP_SUCCESS,
          payload: result.data
        })
      } else {
        dispatch({
          type: ActionTypes.GET_ALL_CLIENT_GROUP_FAILURE,
          payload: null,
          msg: `Get all client group failed. <b>${result.msg}</b>`
        })
      }
    })
    .catch((error) => {
      dispatch({
        type: ActionTypes.GET_ALL_CLIENT_GROUP_FAILURE,
        error,
        payload: null,
        msg: `Get all client group failed. API issue.`
      })
    });
  };
}

export function getSource() {
  return (dispatch) => {
    const endpoint = `${currentConfig.serverURL}/Common/option/get?type=SourceSystem`;

    dispatch({
      type: ActionTypes.GET_SOURCE_REQUEST,
    })

    fetch(endpoint, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Token': auth.getAccessToken(),
        'RESULTSTRUCT': true
      }
    })
    .then(response => response.json())
    .then((result) => {
      if (result.success) {
        dispatch({
          type: ActionTypes.GET_SOURCE_SUCCESS,
          payload: result.data
        })
      } else {
        dispatch({
          type: ActionTypes.GET_SOURCE_FAILURE,
          payload: null,
          msg: `Get source failed. <b>${result.msg}</b>`
        })
      }
    })
    .catch((error) => {
      dispatch({
        type: ActionTypes.GET_SOURCE_FAILURE,
        error,
        payload: null,
        msg: `Get source failed. API issue.`
      })
    });
  };
}

export function getAirports() {
  return (dispatch) => {
    const endpoint = `${currentConfig.serverURL}/Common/option/get?type=Airport`;

    dispatch({
      type: ActionTypes.GET_AIRPORT_REQUEST,
    })

    fetch(endpoint, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Token': auth.getAccessToken(),
        'RESULTSTRUCT': true
      }
    })
    .then(response => response.json())
    .then((result) => {
      if (result.success) {
        dispatch({
          type: ActionTypes.GET_AIRPORT_SUCCESS,
          payload: result.data
        })
      } else {
        dispatch({
          type: ActionTypes.GET_AIRPORT_FAILURE,
          payload: null,
          msg: `Get airport failed. <b>${result.msg}</b>`
        })
      }
    })
    .catch((error) => {
      dispatch({
        type: ActionTypes.GET_AIRPORT_FAILURE,
        error,
        payload: null,
        msg: `Get airport failed. API issue.`
      })
    });
  };
}