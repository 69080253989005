import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import {dateFormatToSend, isDateFormat} from './transformDate';

export function genValidReportParams(state, allReportParams) {
    let errorField = []
    let params = []
    _map(state.criteria, (value, key) => {
        if (state.isRequiredField.includes(key) && _isEmpty(value)) {
            errorField.push(key)
        }
        let validParam = true
        //对于为空的日期类型参数，不传到后台
        _map(allReportParams, item => {
            if(item.controlType === 'Date' || item.controlType === 'DateRange'){
                if(key.indexOf(item.rptParamName) !== -1 && _isEmpty(value)){
                    validParam = false
                }
            }
        })
        if(key === 'reportType' || key === 'reportPath' || key === 'ClientGroup'){
            validParam = false
        }
        if(validParam){
            params.push({
                Key: key,
                Value: isDateFormat(value) ? dateFormatToSend(value) : (Array.isArray(value) ? value.join(',') : value)
            })
        }
    })
    return { errorField, params }
  }

export function genBrow(){
    if (localStorage.brow) {
        return
    }
    var userAgent = navigator.userAgent //取得浏览器的userAgent字符串
    var isOpera =
        userAgent.indexOf('OPR') > -1 || userAgent.indexOf('Opera') > -1 //判断是否Opera浏览器
    // var isIE = userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1 && !isOpera; //判断是否IE浏览器
    var isIE = window.ActiveXObject || 'ActiveXObject' in window
    // var isEdge = userAgent.indexOf("Windows NT 6.1; Trident/7.0;") > -1 && !isIE; //判断是否IE的Edge浏览器
    var isEdge = userAgent.indexOf('Edge') > -1 //判断是否IE的Edge浏览器
    var isFF = userAgent.indexOf('Firefox') > -1 //判断是否Firefox浏览器
    var isSafari =
        userAgent.indexOf('Safari') > -1 && userAgent.indexOf('Chrome') === -1 //判断是否Safari浏览器
    var isChrome =
        userAgent.indexOf('Chrome') > -1 &&
        userAgent.indexOf('Safari') > -1 &&
        !isEdge //判断Chrome浏览器

    if (isIE) {
        localStorage.brow = 'IE'
    }
    if (isFF) {
        localStorage.brow = 'FF'
    }
    if (isOpera) {
        localStorage.brow = 'Opera'
    }
    if (isSafari) {
        localStorage.brow = 'Safari'
    }
    if (isChrome) {
        localStorage.brow = 'Chrome'
    }
    if (isEdge) {
        localStorage.brow = 'Edge'
    }
}

export function getUrlParams(name) {
    const reg = new RegExp(`(^|&)${ name}=([^&]*)(&|$)`);
    const r = decodeURIComponent(window.location.search).substr(1).match(reg);
    if (r != null) return  decodeURIComponent(r[2]); return null;
}
