import fetch from 'node-fetch';
import config from '../config';
import Auth from '../Auth/Auth';
import * as ActionTypes from '../constants/ActionTypes';

// import reportParams from '../mock/reportParams.json';

const currentConfig = config.development;
const auth = new Auth();

export function getReportType() {
  return (dispatch) => {
    const endpoint = `${currentConfig.serverURL}/Report/reportlist`;

    dispatch({
      type: ActionTypes.GET_REPORT_LIST_REQUEST,
    })

    fetch(endpoint, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Token': auth.getAccessToken(),
        'RESULTSTRUCT': true
      }
    })
    .then(response => response.json())
    .then((result) => {
      if (result.success) {
        dispatch({
          type: ActionTypes.GET_REPORT_LIST_SUCCESS,
          payload: result.data
        })
      } else {
        dispatch({
          type: ActionTypes.GET_REPORT_LIST_FAILURE,
          payload: null,
          msg: `Get report type failed. <b>${result.msg}</b>`,
          result: result
        })
      }
    })
    .catch((error) => {
      dispatch({
        type: ActionTypes.GET_REPORT_LIST_FAILURE,
        error,
        payload: null,
        msg: `Get report type failed. API issue.`
      })
    });
  };
}

export function getSecurityClientGroup() {
  return (dispatch) => {
    const endpoint = `${currentConfig.serverURL}/Security/clientgroup`;

    dispatch({
      type: ActionTypes.GET_SECURITY_CLIENT_GROUP_REQUEST,
    })

    fetch(endpoint, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Token': auth.getAccessToken(),
        'RESULTSTRUCT': true
      }
    })
    .then(response => response.json())
    .then((result) => {
      if (result.success) {
        dispatch({
          type: ActionTypes.GET_SECURITY_CLIENT_GROUP_SUCCESS,
          payload: result.data
        })
      } else {
        dispatch({
          type: ActionTypes.GET_SECURITY_CLIENT_GROUP_FAILURE,
          payload: null,
          msg: `Get security client group failed. <b>${result.msg}</b>`,
          result: result
        })
      }
    })
    .catch((error) => {
      dispatch({
        type: ActionTypes.GET_SECURITY_CLIENT_GROUP_FAILURE,
        error,
        payload: null,
        msg: `Get security client group failed. API issue.`
      })
    });
  };
}

export function getSecurityClient(groupid) {
  return (dispatch) => {
    const endpoint = `${currentConfig.serverURL}/Security/client/${groupid}`;

    dispatch({
      type: ActionTypes.GET_SECURITY_CLIENT_REQUEST,
    })

    fetch(endpoint, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Token': auth.getAccessToken(),
        'RESULTSTRUCT': true
      }
    })
    .then(response => response.json())
    .then((result) => {
      if (result.success) {
        dispatch({
          type: ActionTypes.GET_SECURITY_CLIENT_SUCCESS,
          payload: result.data
        })
      } else {
        dispatch({
          type: ActionTypes.GET_SECURITY_CLIENT_FAILURE,
          payload: null,
          msg: `Get security client failed. <b>${result.msg}</b>`,
          result: result
        })
      }
    })
    .catch((error) => {
      dispatch({
        type: ActionTypes.GET_SECURITY_CLIENT_FAILURE,
        error,
        payload: null,
        msg: `Get security client failed. API issue.`
      })
    });
  };
}

export function getReportParameters(reportId) {
  return (dispatch) => {
    const endpoint = `${currentConfig.serverURL}/report/control/${reportId}`;

    dispatch({
      type: ActionTypes.GET_REPORT_PARAM_REQUEST,
    })

    fetch(endpoint, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Token': auth.getAccessToken(),
        'RESULTSTRUCT': true
      }
    })
    .then(response => response.json())
    .then((result) => {
      if (result.success) {
        dispatch({
          type: ActionTypes.GET_REPORT_PARAM_SUCCESS,
          payload: result.data // reportParams.data
        })
      } else {
        dispatch({
          type: ActionTypes.GET_REPORT_PARAM_FAILURE,
          payload: null,
          msg: `Get report parameters failed. <b>${result.msg}</b>`,
          result: result
        })
      }
    })
    .catch((error) => {
      dispatch({
        type: ActionTypes.GET_REPORT_PARAM_FAILURE,
        error,
        payload: null,
        msg: `Get report parameters failed. API issue.`
      })
    });
  };
}

export function clearReportParameters() {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.CLEAR_REPORT_PARAM,
    })
  }
}
