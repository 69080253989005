import fetch from 'node-fetch';
import config from '../config';
import Auth from '../Auth/Auth';
import * as ActionTypes from '../constants/ActionTypes';

// import addNewClientResult from '../mock/addNewClientResult.json'; // Import mock data

const currentConfig = config.development;
const auth = new Auth();

export function searchClient(debtorCode, clientName, source, pageSize, pageIndex) {
  return (dispatch) => {
    const endpoint = `${currentConfig.serverURL}/Admin/client/search?PageSize=${pageSize}&PageIndex=${pageIndex}&DebtorCode=${encodeURIComponent(debtorCode)}&ClientName=${encodeURIComponent(clientName)}&SourceSystem=${encodeURIComponent(source)}`;

		dispatch({
			type: ActionTypes.SEARCH_CLIENT_REQUEST,
      endpoint: endpoint
		})

    fetch(endpoint, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
        'Token': auth.getAccessToken(),
        'RESULTSTRUCT': true
			}
		})
			.then(response => response.json())
			.then((result) => {
        if (result.success) {
          dispatch({
            type: ActionTypes.SEARCH_CLIENT_SUCCESS,
            payload: result.data
          })
        } else {
          dispatch({
  					type: ActionTypes.SEARCH_CLIENT_FAILURE,
  					payload: null,
            msg: `Search client failed. <b>${result.msg}</b>`,
            result: result
  				})
        }
			})
			.catch((error) => {
				dispatch({
					type: ActionTypes.SEARCH_CLIENT_FAILURE,
					error,
					payload: null,
          msg: `Search client failed. API issue.`
				})
			});
	};
}

export function getClientDetail(id) {
  return (dispatch) => {
    const endpoint = `${currentConfig.serverURL}/Admin/client/${id}`;

		dispatch({
			type: ActionTypes.GET_CLIENT_DETAIL_REQUEST,
      endpoint: endpoint
		})

    fetch(endpoint, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
        'Token': auth.getAccessToken(),
        'RESULTSTRUCT': true
			}
		})
			.then(response => response.json())
			.then((result) => {
        if (result.success) {
          dispatch({
            type: ActionTypes.GET_CLIENT_DETAIL_SUCCESS,
            payload: result.data
          })
        } else {
          dispatch({
  					type: ActionTypes.GET_CLIENT_DETAIL_FAILURE,
  					payload: null,
            msg: `Get client detail ${id} failed. <b>${result.msg}</b>`
  				})
        }
			})
			.catch((error) => {
				dispatch({
					type: ActionTypes.GET_CLIENT_DETAIL_FAILURE,
					error,
					payload: null,
          msg: `Get client detail failed. API issue.`
				})
			});
	};
}

export function editClient(form) {
  return (dispatch) => {
    const endpoint = `${currentConfig.serverURL}/Admin/client/edit`;
    const body = form

		dispatch({
			type: ActionTypes.EDIT_CLIENT_REQUEST,
      body: body
		})

    fetch(endpoint, {
			method: 'PUT',
      body: JSON.stringify(body),
			headers: {
				'Content-Type': 'application/json',
        'Token': auth.getAccessToken(),
        'RESULTSTRUCT': true
			}
		})
			.then(response => response.json())
			.then((result) => {
        if (result.success) {
          dispatch({
            type: ActionTypes.EDIT_CLIENT_SUCCESS,
            payload: result.data,
            msg: `Edit client <b>${result.data.clientID}</b> succeed.`
          })
        } else {
          dispatch({
  					type: ActionTypes.EDIT_CLIENT_FAILURE,
  					payload: null,
            msg: `Edit client failed. <b>${result.msg}</b>`
  				})
        }
			})
			.catch((error) => {
				dispatch({
					type: ActionTypes.EDIT_CLIENT_FAILURE,
					error,
					payload: [],
          msg: `Edit client failed. API issue.`
				})
			});
	};
}

export function addNewClient(form) {
  return (dispatch) => {
    const endpoint = `${currentConfig.serverURL}/Admin/client/add`;
    const body = form

		dispatch({
			type: ActionTypes.ADD_CLIENT_REQUEST,
      body: body
		})

    fetch(endpoint, {
			method: 'POST',
      body: JSON.stringify(body),
			headers: {
				'Content-Type': 'application/json',
        'Token': auth.getAccessToken(),
        'RESULTSTRUCT': true
			}
		})
			.then(response => response.json())
			.then((result) => {
        if (result.success) {
          dispatch({
            type: ActionTypes.ADD_CLIENT_SUCCESS,
            payload: result.data,
            msg: `Add client <b>${form.clientID}</b> succeed.`
          })
        } else {
          dispatch({
  					type: ActionTypes.ADD_CLIENT_FAILURE,
  					payload: null,
            msg: `Add client failed. <b>${result.msg}</b>`
  				})
        }
			})
			.catch((error) => {
				dispatch({
					type: ActionTypes.ADD_CLIENT_FAILURE,
					error,
					payload: null,
          msg: `Add client failed. API issue.`
				})
			});

    // setTimeout(function(){
    //   dispatch({
    //     type: ActionTypes.ADD_CLIENT_SUCCESS,
    //     payload: addNewClientResult,
    //     msg: `Add client <b>${form.clientID}</b> succeed.`
    //   })
    // }, 1000);

	};
}

export function retriveClient(source, debtorCode) {
  return (dispatch) => {
    const endpoint = `${currentConfig.serverURL}/Admin/client/source/get?source=${source}&debtorCode=${debtorCode}`;

		dispatch({
			type: ActionTypes.RETRIVE_CLIENT_REQUEST,
      endpoint: endpoint
		})

    fetch(endpoint, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
        'Token': auth.getAccessToken(),
        'RESULTSTRUCT': true
			}
		})
      .then(response => response.json())
  		.then(result => {
        dispatch({
          type: result.success ? ActionTypes.RETRIVE_CLIENT_SUCCESS : ActionTypes.RETRIVE_CLIENT_FAILURE,
          payload: result.data,
          msg: result.success ? result.msg : `Retrive client failed. <b>${result.msg}</b>`,
          result
        })
  		})
  		.catch((error) => {
  			dispatch({
  				type: ActionTypes.RETRIVE_CLIENT_FAILURE,
          payload: null,
          msg: 'Retrive client failed. API issue.',
          error,
  			})
  		});
	};
}

export function clearRetrivedClient() {
  return (dispatch) => {
    dispatch({
			type: ActionTypes.CLEAR_RETRIVED_CLIENT,
		})
	};
}

export function clearSearchClient() {
  return (dispatch) => {
    dispatch({
			type: ActionTypes.CLEAR_SEARCH_CLIENT,
		})
	};
}

export function clearClientDetail() {
  return (dispatch) => {
    dispatch({
			type: ActionTypes.CLEAR_CLIENT_DETAIL,
		})
	};
}
