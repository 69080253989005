import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Form, Col, Row} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronRight, faChevronLeft, faSpinner} from '@fortawesome/free-solid-svg-icons';

import SelectInput from '../../components/SelectInput';
import MultiSelectInput from '../../components/MultiSelectInput';
import CustomDatePicker from '../../components/CustomDatePicker';
import CustomDateRangePicker from '../../components/CustomDateRangePicker';
import AirportSelect from '../../components/AirportSelect';
import Loading from '../../components/Loading';

import _map from 'lodash/map';
import _find from 'lodash/find';
import _extend from 'lodash/extend';
import _without from 'lodash/without';
import _concat from 'lodash/concat';
import _groupBy from 'lodash/groupBy';
import _get from 'lodash/get';
import { genValidReportParams} from '../../util/common';

class ReportSearchPanel extends Component {
	constructor(props) {
		super(props);
		this.onClientSelectClick = this.onClientSelectClick.bind(this);
		this.onReportSelectClick = this.onReportSelectClick.bind(this);
		this.onCustomerCheck = this.onCustomerCheck.bind(this);
		this.onFieldChange = this.onFieldChange.bind(this);
		this.onResetClick = this.onResetClick.bind(this);
		this.onDateSelect = this.onDateSelect.bind(this);
		this.onDateRangeSelect = this.onDateRangeSelect.bind(this);
		this.onSelectInputClick = this.onSelectInputClick.bind(this);

		this.state = {
			isLoadingClients: false,
      criteria: {
        reportType: '',
        reportPath: ''
			},
			isCheckAllClient: false,
			isRequiredField: [],
      errorMsg: null,
			isLoadingParam: false,
			errorField: [],
		};
		this.operationState = {
			loadedClientGroups: false
		};
	}

	componentDidMount() {
	}

	componentDidUpdate(prevProps, prevState) {
    if (this.props.reportParams && prevProps.reportParams !== this.props.reportParams) {
      this.setReportParams()
    }
    if (prevProps.securityClientList !== this.props.securityClientList) {
      this.setLoadingClients(false)
			this.checkAllClients()
    }
	}

	onFieldChange(e) {
		this.setState({
			criteria: _extend(this.state.criteria, {
				[e.target.name]: e.target.value
			})
		})
	}

	onDateSelect(startDateValue, name) {
		this.setState({
			criteria: _extend(this.state.criteria, {
				[name]: startDateValue
			})
		})
 	}

	onDateRangeSelect(startDateValue, endDateValue, name, i) {
		this.setState({
			criteria: _extend(this.state.criteria, {
				[`${name}From`]: startDateValue,
				[`${name}To`]: endDateValue,
			})
		})
 	}

	onClientSelectClick(selectedOption, name) {
		this.setState({
			criteria: _extend(this.state.criteria, {
				[name]: selectedOption
			})
		})
		this.setLoadingClients(true)
		this.props.handleGetSecurityClient(selectedOption)
	}

	onSelectInputClick(val, name){
		this.setState({
			criteria: _extend(this.state.criteria, {
				[name]: val
			})
		})
	}

	onReportSelectClick(value) {
		this.setState({
			criteria: {
				reportType: value,
				reportPath: _find(this.props.reportTypeList, {'key': value}).reportPath,
			},
			isLoadingParam: true
		})
		this.props.handleReportSelect(value)
	}

	onCustomerCheck(e) {
		if (e.target.name === 'all') {
			if (e.target.checked) {
				this.checkAllClients()
			} else {
				this.setState({
					criteria: _extend(this.state.criteria, {
						Clients: []
					}),
					isCheckAllClient: false
				})
			}
		} else {
			if (e.target.checked) {
				this.setState({
					criteria: _extend(this.state.criteria, {
						Clients: _concat(this.state.criteria.Clients, e.target.name)
					})
				})
			} else {
				this.setState({
					criteria: _extend(this.state.criteria, {
						Clients: _without(this.state.criteria.Clients, e.target.name)
					}),
					isCheckAllClient: false
				})
			}
		}
	}

	onResetClick() {
		this.setState({
			criteria: {
        reportType: '',
        reportPath: ''
			},
			isRequiredField: [],
			errorMsg: null,
			errorField: []
		})
		this.props.handleClearReportParam()
	}

	checkAllClients() {
		if (this.props.securityClientList) {
			this.setState({
				criteria: _extend(this.state.criteria, {
					Clients: _map(this.props.securityClientList, client => client.key)
				}),
				isCheckAllClient: true
			})
		}
	}

	setReportParams() {
		let isRequiredField = []
		let criteria = _map(this.props.reportParams, (o) => {
			if (o.controlType === 'ClientGroup') {
				if (o.isRequire) {
					isRequiredField.push('ClientGroup', 'Clients')
				}
				return {
					'ClientGroup': '',
					'Clients': []
				}
			}
			if (o.controlType === 'DateRange') {
				if (o.isRequire) {
					isRequiredField.push(`${o.rptParamName}From`, `${o.rptParamName}To`)
				}
				return {
					[`${o.rptParamName}From`]: _get(o, 'defaultValue', ''),
					[`${o.rptParamName}To`]: _get(o, 'defaultValue', '')
				}
			} else if (o.controlType === 'MultiSel') {
				if (o.isRequire) {
					isRequiredField.push(o.rptParamName)
				}
				return { [o.rptParamName]: _get(o, 'defaultValue', []) }
			} else {
				if (o.isRequire) {
					isRequiredField.push(o.rptParamName)
				}
				return { [o.rptParamName]: _get(o, 'defaultValue', '') }
			}
		}
		)

		let merged = criteria.reduce(function (acc, x) {
			for (var key in x) acc[key] = x[key];
			return acc;
		}, {})

		this.setState({
			criteria: _extend(this.state.criteria,
				merged
			),
			isRequiredField,
			isLoadingParam: false
		})
	}

	setLoadingClients(bool) {
		this.setState({
			isLoadingClients: bool
		})
	}

	onSubmitClick() {
		let result = genValidReportParams(this.state, this.props.reportParams)
		let errorField = result.errorField
		let rq = {
			reportPath: this.state.criteria.reportPath,
			params: result.params
		}

		if (errorField.length === 0) {
			this.setState({
				errorMsg: null,
				errorField,
			})
			this.props.handleViewReport(rq)
		} else {
			this.setState({
				errorMsg: 'Some criterias are not filled.',
				errorField,
			})
		}
	}

	getDatasource(ele) {
		if (ele.dataSourceKey === "ClientGroup") {
			if (!this.props.securityClientGroupList && !this.operationState.loadedClientGroups) {
				this.operationState.loadedClientGroups = true
				this.props.handleGetSecurityClientGroup()
			}
			return this.props.securityClientGroupList
		} else {
			return ele.dataSource
		}
	}

	displayControlType(ele) {
		if (ele.controlType === 'ClientGroup') {
			return (
				<Col lg={12}>
					<Form.Group controlId="ClientGroup">
						<SelectInput
							preValue={this.state.criteria.ClientGroup}
							handleSelect={this.onClientSelectClick}
							handleFetchOptions={this.props.handleGetSecurityClientGroup}
							options={this.props.securityClientGroupList}
							optionLabel="displayValue"
							optionValue="key"
							name="ClientGroup"
							isClearable={!ele.isRequire}
							isInValid={this.state.errorField.includes('ClientGroup')}
						/>
					</Form.Group>

					<Form.Group controlId="customer" >
						<Form.Label>Clients*</Form.Label>
						<div className={`${this.state.errorField.includes('Clients') ? 'border-red' : ''} multiselect-wrapper card small`}>
							{this.state.criteria.ClientGroup && this.props.securityClientList ?
								<div className="has-select">
									<div className="py-2 px-2 border-bottom">
										<Form.Group controlId="selectAll" className="mb-0">
											<Form.Check type="checkbox" label="Select All" className="mt-0" checked={this.state.isCheckAllClient} onChange={(e) => this.onCustomerCheck(e)} name="all" />
										</Form.Group>
									</div>
									{_map(this.props.securityClientList, (client, i) =>
										<div className="other-options" key={i}>
											<div className="py-2 px-3 border-bottom">
												<Form.Group controlId={client.key} className="mb-0">
													<Form.Check
														type="checkbox"
														name={client.key}
														label={client.displayValue}
														className="mt-0"
														checked={this.state.criteria.Clients && this.state.criteria.Clients.includes(client.key)}
														onChange={(e) => this.onCustomerCheck(e)}
													/>
												</Form.Group>
											</div>
										</div>
									)}
								</div> :
								<div className="no-select text-center pt-5">
									<p className="pt-4 text-muted">{this.state.isLoadingClients ? <FontAwesomeIcon icon={faSpinner} size="2x" spin /> : 'Please select client'}</p>
								</div>
							}
						</div>
					</Form.Group>
				</Col>
			)
		} else if (ele.controlType === 'TextBox') {
			return (
				<Col lg={8}>
					<Form.Control
						value={_get(this.state.criteria, ele.rptParamName, '')}
						name={ele.rptParamName}
						onChange={(e) => this.onFieldChange(e)}
						isInvalid={this.state.errorField.includes(ele.rptParamName)}
					/>
				</Col>
			)
		} else if (ele.controlType === 'SingleSel') {
			if (ele.dataSourceKey === "Airport") {
				return (
					<Col lg={8}>
						<AirportSelect
							preValue={_get(this.state.criteria, ele.rptParamName, '')}
							handleSelect={this.onSelectInputClick}
							name={ele.rptParamName}
							isRequire={ele.isRequire}
							isInValid={this.state.errorField.includes(ele.rptParamName)}
							dataSourceKey={ele.dataSourceKey}
							isMulti={false}
						/>
					</Col>
				)
			}
			else{
				return (
					<Col lg={8}>
						<SelectInput
							preValue={_get(this.state.criteria, ele.rptParamName, '')}
							handleSelect={this.onSelectInputClick}
						  handleFetchOptions={()=>{}}
							options={this.getDatasource(ele)}
							optionLabel="displayValue"
							optionValue="key"
							name={ele.rptParamName}
							isClearable={!ele.isRequire}
							isSearchable={ele.autoCompleteMode === "Append"}
							isInValid={this.state.errorField.includes(ele.rptParamName)}
						/>
					</Col>
				)
			}
		} else if (ele.controlType === 'DateRange') {
			return (
				<Col lg={8}>
					<CustomDateRangePicker
						handleSelect={this.onDateRangeSelect}
						name={ele.rptParamName}
						startDateValue={this.state.criteria[`${ele.rptParamName}From`]}
						endDateValue={this.state.criteria[`${ele.rptParamName}To`]}
						index={0}
						isClearAvailable={!ele.isRequire}
						isInvalid={this.state.errorField.includes(`${ele.rptParamName}From`) || this.state.errorField.includes(`${ele.rptParamName}To`)}
					/>
				</Col>
			)
		} else if (ele.controlType === 'Date') {
			return (
				<Col lg={8}>
					<CustomDatePicker
						handleSelect={this.onDateSelect}
						name={ele.rptParamName}
						value={this.state.criteria[ele.rptParamName]}
						isClearAvailable={!ele.isRequire}
						isInvalid={this.state.errorField.includes(ele.rptParamName)}
					/>
				</Col>
			)
		} else if (ele.controlType === 'MultiSel') {
			if (ele.dataSourceKey === "Airport") {
				return (
					<Col lg={8}>
						<AirportSelect
							preValue={_get(this.state.criteria, ele.rptParamName, [])}
							handleSelect={this.onSelectInputClick}
							name={ele.rptParamName}
							isRequire={ele.isRequire}
							isInValid={this.state.errorField.includes(ele.rptParamName)}
							dataSourceKey={ele.dataSourceKey}
							isMulti={true}
						/>
					</Col>
				)
			}
			else{
				return (
					<Col lg={8}>
						<MultiSelectInput
              preValue={_map(_get(this.state.criteria, ele.rptParamName, []), (k) => (_find(this.getDatasource(ele), {'key': k})))}
							handleSelect={(options) => { this.onSelectInputClick(_map(options, 'key'), ele.rptParamName) }}
						  handleFetchOptions={()=>{}}
							options={this.getDatasource(ele)}
							optionLabel="displayValue"
							optionValue="key"
							name={ele.rptParamName}
							isInValid={this.state.errorField.includes(ele.rptParamName)}
            />
					</Col>
				)
			}

		} else return ele.controlType
	}

  render() {
		return (
			<div className={`report-panel ${this.props.isOpen ? 'open' : ''}`}>
				<Button className="toogle-panel-btn" variant="info" onClick={this.props.handleTogglePanelClick}>
					<FontAwesomeIcon icon={this.props.isOpen ? faChevronRight : faChevronLeft} />
				</Button>
				<div className="report-panel__content">
					<div className="report-panel__top">
						<Form.Group controlId="report-type">
							<Form.Label className="font-weight-bold">Report Type</Form.Label>
							<SelectInput
								preValue={this.state.criteria.reportType}
								handleSelect={this.onReportSelectClick}
								handleFetchOptions={this.props.handleFetchReport}
								options={this.props.reportTypeList}
								optionLabel="displayValue"
								optionValue="key"
							/>
						</Form.Group>
					</div>

					{this.state.isLoadingParam && <Loading isAbsolute />}

					{_map(_groupBy(this.props.reportParams, (o) => o.controlGroup), (value, key) =>
						<fieldset key={key}>
						{_map(value, (ele) =>
								<Form.Group controlId={ele.controlId} as={Row} key={ele.controlId}>
									<Col lg={4} className="pr-0">
										<Form.Label className="col-form-label">{ele.controlName_EN}{ele.isRequire && '*'}</Form.Label>
									</Col>
									{this.displayControlType(ele)}
								</Form.Group>
						)}
							</fieldset>
					)}
					<div style={{height:"100px"}}></div>
				</div>

				{this.props.reportParams && <div className="report-panel__bottom">
					{this.state.errorMsg &&
						<p className="red small text-center">{this.state.errorMsg}</p>
					}
					<Button variant="outline-secondary" className="mr-2" onClick={() => this.onResetClick()}>Reset</Button>
					<Button onClick={() => this.onSubmitClick()}>View Report</Button>
				</div>}
			</div>
	);
  }
}

ReportSearchPanel.propTypes = {
	handleTogglePanelClick: PropTypes.func,
	handleReportSelect: PropTypes.func,
	handleGetSecurityClientGroup: PropTypes.func,
	handleGetAirport: PropTypes.func,
	handleGetSecurityClient: PropTypes.func,
	handleFetchReport: PropTypes.func,
	handleClearReportParam: PropTypes.func,
	handleViewReport: PropTypes.func,
	reportTypeList: PropTypes.array,
	reportParams: PropTypes.array,
	securityClientGroupList: PropTypes.array,
	securityClientList: PropTypes.array,
	airportList: PropTypes.array,
	isOpen: PropTypes.bool,
}

export default ReportSearchPanel;
