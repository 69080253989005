import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus} from '@fortawesome/free-solid-svg-icons';
import {Button, Tab, Tabs, Row, Col, Form, Alert} from 'react-bootstrap';
import toaster from 'toasted-notes' ;
import 'toasted-notes/src/styles.css';

import MultiSelectInput from '../components/MultiSelectInput';
import SelectInput from '../components/SelectInput';
import ConfirmNavigationModal from '../components/ConfirmNavigationModal';

import {isEmail} from '../util/validation';
import _extend from 'lodash/extend';
import _map from 'lodash/map';
import _find from 'lodash/find';

import {
  getUsers,
  getUserDetail,
  clearUserDetail,
  editUser,
  addNewUser,
} from '../actions/user';
import {getRoles} from '../actions/role';
import {getGroups} from '../actions/common';

class UserMaintenance extends Component {
  constructor(props) {
    super(props);

    this.onAddNewClick = this.onAddNewClick.bind(this);
    this.onFieldChange = this.onFieldChange.bind(this);
    this.onSelectRoles = this.onSelectRoles.bind(this);
    this.onSelectGroups = this.onSelectGroups.bind(this);
    this.onSelectUserId = this.onSelectUserId.bind(this);
    this.onSubmitEditClick = this.onSubmitEditClick.bind(this);
    this.changeTab = this.changeTab.bind(this);
    this.onGetNewUserDetail = this.onGetNewUserDetail.bind(this);

    this.state = {
      currentTab: 'edit',
      selectedUserId: '',
      isFillingForm: false,
      editForm: {
        email: '',
        userName: '',
        userStatus: 'E',
        roles: [],
        groups: [],
      },
      newForm: {
        email: '',
        userName: '',
        userStatus: 'E',
        roles: [],
        groups: [],
      },
      isValidNewEmail: true,
    };
  }

  componentWillUnmount() {
    this.props.clearUserDetail()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedUserId !== this.state.selectedUserId) {
      if (this.state.selectedUserId) {
        this.props.getUserDetail(this.state.selectedUserId)
      } else {
        this.props.clearUserDetail()
        this.setUserDetail()
        this.setFillingForm(false)
      }
    }

    if (this.props.userDetail) {
      if(prevProps.userDetail !== this.props.userDetail) {
        this.setUserDetail()
      }
    }

    if (prevProps.newAddedUser.timestamp !== this.props.newAddedUser.timestamp) {
      this.props.getUsers();
      this.clearNewForm()

      toaster.notify(({ onClose }) => (
        <Alert
          variant="success"
          className="alert-msg mb-0"
          dismissible
          onClose={onClose}
        >
          <Alert.Link onClick={this.onGetNewUserDetail}>Click here to review or edit.</Alert.Link>
        </Alert>
      ), {duration: 3000})
    }

    if (!prevProps.shouldRefreshUserDetail && this.props.shouldRefreshUserDetail) {
      this.props.getUserDetail(this.state.selectedUserId)
      this.setFillingForm(false)
    }
  }


  onFieldChange(e) {
    let formName = this.state.currentTab === 'edit' ? 'editForm' : 'newForm'

    this.setState({
      isFillingForm: true,
      [formName]: _extend(this.state[formName], {
        [e.target.name]: e.target.value,
      })
    })
  }

  changeTab(isChangeTab, tabName) {
    if (isChangeTab) {
      const formName = tabName === 'edit' ? 'newForm' : 'editForm'
      this.setState({
        currentTab: tabName,
        isFillingForm: false,
        warningPopUp: false,
        selectedUserId: '',
        [formName]: {
          email: '',
          userName: '',
          userStatus: 'E',
          roles: [],
          groups: [],
        },
        isValidNewEmail: true,
      })
    } else {
      this.setState({
        warningPopUp: false
      })
    }
  }

  onTabClick(tabName) {
    if(tabName !== this.state.currentTab) {
      if (this.state.isFillingForm) {
        this.setState({
          warningPopUp: true,
        })
      } else {
        const formName = tabName === 'edit' ? 'newForm' : 'editForm'
        this.setState({
          currentTab: tabName,
          selectedUserId: '',
          [formName]: {
            email: '',
            userName: '',
            userStatus: 'E',
            roles: [],
            groups: [],
          },
          isValidNewEmail: true,
        })
      }
    }

  }

  onAddNewClick() {
    this.props.addNewUser(this.state.newForm)
  }

  onSubmitEditClick() {
    this.props.editUser(this.state.editForm)
  }

  onSelectRoles(array) {
    let formName = this.state.currentTab === 'edit' ? 'editForm' : 'newForm'
    this.setState({
      isFillingForm: true,
      [formName]: _extend(this.state[formName], {
        roles: _map(array, (r) => r.key),
      })
    })
  }

  onSelectGroups(array) {
    let formName = this.state.currentTab === 'edit' ? 'editForm' : 'newForm'
    this.setState({
      isFillingForm: true,
      [formName]: _extend(this.state[formName], {
        groups: _map(array, (r) => r.key),
      })
    })
  }

  onGetNewUserDetail() {
    this.setState({
      selectedUserId: this.props.newAddedUser.userId,
      currentTab: 'edit',
      isFillingForm: false
    })
  }

  setFillingForm(bool) {
    this.setState({
      isFillingForm: bool
    })
  }

  clearNewForm() {
    this.setState({
      newForm: {
        email: '',
        userName: '',
        userStatus: 'E',
        roles: [],
        groups: [],
      },
      isFillingForm: false,
    })
  }

  checkEmail(string) {
    this.setState({
      isValidNewEmail: isEmail(string)
    })
  }

  onSelectUserId(id) {
    this.setState({
      selectedUserId: id
    })
  }


  setUserDetail() {
    this.setState({
      editForm: {
        "userId": this.state.selectedUserId ? this.props.userDetail.userId : '',
        "email": this.state.selectedUserId ? this.props.userDetail.email : '',
        "userName": this.state.selectedUserId ? this.props.userDetail.userName : '',
        "userStatus": this.state.selectedUserId ? this.props.userDetail.userStatus : 'E',
        "roles": this.state.selectedUserId ? _map(this.props.userDetail.roles, (r) => r.key) : [],
        "groups": this.state.selectedUserId ? _map(this.props.userDetail.groups, (r) => r.key) : []
      }
    })
  }

  getEditForm() {
    let formName = this.state.currentTab === 'edit' ? 'editForm' : 'newForm'
    let isEdit = this.state.currentTab === 'edit'
    return (
      <div className="pt-3">
        <Row className="form-group">
          <Col xs="3" className="text-right">
            <Form.Label className="col-form-label">Email{!isEdit && '*'}</Form.Label>
          </Col>
          <Col xs="7">
            {isEdit ?
              <p className="col-form-label">{this.state.editForm.email}</p>:
              <Form.Control
                type="text"
                value={this.state.newForm.email}
                name="email"
                isInvalid={!this.state.isValidNewEmail}
                onChange={(e) => this.onFieldChange(e)}
                onBlur={() => this.checkEmail(this.state.newForm.email)}
                maxLength={100}
              />
            }

            {!this.state.isValidNewEmail &&
              <Form.Text className="text-danger">
                Email is empty or invalid.
              </Form.Text>
            }
          </Col>
        </Row>
        <Row className="form-group">
          <Col xs="3" className="text-right">
            <Form.Label className="col-form-label">Name*</Form.Label>
          </Col>
          <Col xs="7">
            <Form.Control
              type="text"
              value={this.state[formName].userName}
              name="userName"
              onChange={(e) => this.onFieldChange(e)}
              maxLength={100}
            />
          </Col>
        </Row>
        <Row className="form-group">
          <Col xs="3" className="text-right">
            <Form.Label className="col-form-label">Status</Form.Label>
          </Col>
          <Col xs="7">
            <Form.Control
              as="select"
              name="userStatus"
              value={this.state[formName].userStatus}
              onChange={(e) => this.onFieldChange(e)}
            >
              <option value="E">Enabled</option>
              <option value="D">Disabled</option>
            </Form.Control>
          </Col>
        </Row>
        <Row className="form-group">
          <Col xs="3" className="text-right">
            <Form.Label className="col-form-label">Assign Roles</Form.Label>
          </Col>
          <Col xs="7">
            <MultiSelectInput
              handleSelect={this.onSelectRoles}
              handleFetchOptions={this.props.getRoles}
              options={this.props.roleList}
              preValue={isEdit ? this.props.userDetail.roles : _map(this.state.newForm.roles, (role) => (_find(this.props.roleList, {'key': role})))}
              optionLabel="displayValue"
              optionValue="key"
            />
          </Col>
        </Row>
        <Row className="form-group">
          <Col xs="3" className="text-right">
            <Form.Label className="col-form-label">Assign Groups</Form.Label>
          </Col>
          <Col xs="7">
            <MultiSelectInput
              handleSelect={this.onSelectGroups}
              handleFetchOptions={this.props.getGroups}
              options={this.props.groupList}
              preValue={isEdit ? this.props.userDetail.groups : _map(this.state.newForm.groups, (role) => (_find(this.props.groupList, {'key': role})))}
              optionLabel="displayValue"
              optionValue="key"
            />
          </Col>
        </Row>
        <div className="pt-3 text-center">
          <Button
            size="lg"
            onClick={isEdit ? this.onSubmitEditClick : this.onAddNewClick}
            disabled={isEdit ? !this.state[formName].userName : (!this.state.newForm.userName || !this.state.newForm.email || !this.state.isValidNewEmail)}
          >
            {isEdit ? 'Submit Edit' : 'Add New User'}
          </Button>
        </div>
      </div>
    )
  }

  render () {
    return (
      <div>
        <div className="text-center">
          <h3>User maintenance</h3>
        </div>

        <Row className="justify-content-center mb-5">
          <Col lg="10">
            <Tabs activeKey={this.state.currentTab} id="user-maintenance-tab" onSelect={(k) => this.onTabClick(k)}>
              <Tab eventKey="edit" title="Edit" className="p-3">
                <Row className="border-bottom pb-3">
                  <Col xs="3" className="text-right">
                    <Form.Label className="font-weight-bold col-form-label">Select User To Edit</Form.Label>
                  </Col>
                  <Col xs="7">
                    <SelectInput
                      preValue={this.state.selectedUserId}
                      handleSelect={this.onSelectUserId}
                      handleFetchOptions={this.props.getUsers}
                      options={this.props.userList}
                      optionLabel="displayValue"
                      optionValue="key"
                      isClearable
                    />
                  </Col>
                  <Col xs="2">
                    <Button variant="secondary" className="btn-block" disabled={!this.state.selectedUserId} onClick={() => this.onSelectUserId('')}>Cancel</Button>
                  </Col>
                </Row>

                {!this.props.userDetail &&
                  <div className="no-result" />
                }

                {this.state.editForm.userId &&
                  this.getEditForm()
                }
              </Tab>

              <Tab eventKey="add-new" title={<div><FontAwesomeIcon icon={faPlus}/> Add New</div>} className="p-3">
                {this.state.currentTab === 'add-new' &&
                  this.getEditForm()
                }
              </Tab>
            </Tabs>
          </Col>
        </Row>

        <ConfirmNavigationModal
          show={this.state.warningPopUp}
          handleCloseTab={this.changeTab}
          currentTab={this.state.currentTab}
        />
      </div>
    );
  }
}


UserMaintenance.propTypes = {
	getUsers: PropTypes.func,
	getRoles: PropTypes.func,
	getGroups: PropTypes.func,
	getUserDetail: PropTypes.func,
	clearUserDetail: PropTypes.func,
	editUser: PropTypes.func,
	addNewUser: PropTypes.func,
	shouldRefreshUserDetail: PropTypes.bool,
	newAddedUser: PropTypes.object,
};

function mapStateToProps(state) {
	return {
    userList: state.user.userList,
    userDetail: state.user.userDetail,
    roleList: state.role.roleList,
    groupList: state.common.groupList,
    shouldRefreshUserDetail: state.user.shouldRefreshUserDetail,
    newAddedUser: state.user.newAddedUser,
	}
}

const mapDispatchToProps = {
  getUsers,
  getRoles,
  getGroups,
  getUserDetail,
  clearUserDetail,
  editUser,
  addNewUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserMaintenance);
