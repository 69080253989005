import * as ActionTypes from '../constants/ActionTypes';
import moment from 'moment';
import _assign from 'lodash/assign';

const initialState = {
	clientGroupDetail: null,
	shouldRefreshClientGroupDetail: false,
	newAddedClientGroup: {
		timestamp: null
	},
};

const user = (state = initialState, action) => {
	switch (action.type) {
	case ActionTypes.GET_CLIENT_GROUP_SUCCESS:
	case ActionTypes.GET_CLIENT_GROUP_FAILURE:
		return Object.assign({}, state, {
			clientGroupDetail: action.payload,
			shouldRefreshClientGroupDetail: false
		});
	case ActionTypes.EDIT_CLIENT_GROUP_SUCCESS:
		return Object.assign({}, state, {
			shouldRefreshClientGroupDetail: true,
		});
	case ActionTypes.ADD_CLIENT_GROUP_SUCCESS:
		return Object.assign({}, state, {
			newAddedClientGroup: _assign({timestamp: moment().valueOf()}, action.payload),
		});
	case ActionTypes.CLEAR_CLIENT_GROUP_DETAIL:
		return Object.assign({}, state, {
			clientGroupDetail: null,
			shouldRefreshClientGroupDetail: false,
		});
	default:
		return state;
	}
};

export default user;
