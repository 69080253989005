import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Alert} from 'react-bootstrap';

import toaster from 'toasted-notes' ;
import 'toasted-notes/src/styles.css';

class PopupMsg extends Component {
	constructor(props) {
		super(props);
		this.onRefreshClick = this.onRefreshClick.bind(this);

		this.state = {
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.timestamp !== this.props.timestamp) {
			toaster.notify(({ onClose }) => (
				<Alert
					variant={this.props.isError ? 'danger': 'success'}
					className="alert-msg mb-0"
					dismissible
					onClose={onClose}
				>
					<div dangerouslySetInnerHTML={{__html: this.props.msg}} />
				</Alert>
			), {duration: 3000})
		}
	}


	onRefreshClick() {
    window.location.reload();
	}

	render() {
		return (
			<div/>
	  );
	}
}

PopupMsg.propTypes = {
	msg: PropTypes.string,
	isError: PropTypes.bool,
	timestamp: PropTypes.number,
};

function mapStateToProps(state) {
	return {
    msg: state.msg.msg,
    isError: state.msg.isError,
    timestamp: state.msg.timestamp,
	}
}

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(PopupMsg);
