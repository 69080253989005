import fetch from 'node-fetch'
import config from '../config'
import Auth from '../Auth/Auth'
import * as ActionTypes from '../constants/ActionTypes'

const currentConfig = config.development
const auth = new Auth()

export function getGlobalSettings(store) {
  const endpoint = `${currentConfig.serverURL}/Common/globalsettings`

  store.dispatch({
    type: ActionTypes.INIT_REQUEST
  })

  fetch(endpoint, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      RESULTSTRUCT: true
    }
  })
    .then(response => response.json())
    .then(result => {
      if (result.success) {
        store.dispatch({
          type: ActionTypes.INIT_SUCCESS,
          payload: result.data
        })
        document.title = result.data.loginTitle
      } else {
        store.dispatch({
          type: ActionTypes.INIT_FAILURE,
          payload: null,
          msg: `Init global settings failed.`,
          result: result
        })
      }
    })
    .catch(error => {
      store.dispatch({
        type: ActionTypes.INIT_FAILURE,
        error,
        payload: null,
        msg: `Init global settings failed.`
      })
    })
}
export function forget(email) {
  return dispatch => {
    const endpoint = `${currentConfig.serverURL}/Security/pwd/forget?email=${email}`

    dispatch({
      type: ActionTypes.FORGET_REQUEST
    })

    return fetch(endpoint, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        RESULTSTRUCT: true
      }
    })
      .then(response => response.json())
      .then(result => {
        if (result.success) {
          dispatch({
            type: ActionTypes.FORGET_SUCCESS,
            msg: result.data
          })
        } else {
          dispatch({
            type: ActionTypes.FORGET_FAILURE,
            msg: result.msg
          })
        }
      })
      .catch(error => {
        dispatch({
          type: ActionTypes.FORGET_FAILURE,
          error,
          payload: null,
          msg: `Forget password failed.`
        })
      })
  }
}

export function checkEmail(email) {
  const endpoint = `${currentConfig.serverURL}/Security/pwd/checkemail?email=${email}`
  return new Promise((reslove, reject) => {
    fetch(endpoint, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        RESULTSTRUCT: true
      }
    })
      .then(response => response.json())
      .then(result => {
        if (result.success) {
          reslove(result.data)
        } else {
          reject({ validEmail: false, message: result.msg })
        }
      })
      .catch(error => {
        reject({
          validEmail: false,
          error,
          data: null,
          message: 'Check email format failed.'
        })
      })
  })
}

export function login(username, password, accessToken) {
  return dispatch => {
    const endpoint = `${currentConfig.serverURL}/Security/login`
    const isAuth0Login = !!accessToken
    const body = {
      Email: username,
      Password: password,
      AccessToken: accessToken
    }

    dispatch({
      type: ActionTypes.LOGIN_REQUEST,
      body: body
    })

    fetch(endpoint, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        RESULTSTRUCT: true
      }
    })
      .then(response => response.json())
      .then(result => {
        if (result.success && result.data) {
          auth.setSession(result.data)
        } else {
          handleLoginError(dispatch, isAuth0Login, result, null)
        }
      })
      .catch(error => {
        handleLoginError(dispatch, isAuth0Login, null, error)
      })
  }
}
export function handleLoginError(dispatch, isAuth0Login, result, error) {
  if (isAuth0Login) {
    window.location.href = `${process.env.PUBLIC_URL}/error`
    return
  }
  if (error) {
    dispatch({
      type: ActionTypes.LOGIN_FAILURE,
      error,
      payload: null,
      msg: `Login failed. API issue.`
    })
  } else {
    dispatch({
      type: ActionTypes.LOGIN_FAILURE,
      payload: null,
      msg: `Login failed. Wrong email/password`,
      result: result
    })
  }
}
export function reset(rq, callback) {
  return dispatch => {
    const endpoint = `${currentConfig.serverURL}/Security/pwd/reset`

    dispatch({
      type: ActionTypes.RESET_REQUEST
    })

    fetch(endpoint, {
      method: 'POST',
      body: JSON.stringify(rq),
      headers: {
        'Content-Type': 'application/json',
        RESULTSTRUCT: true
      }
    })
      .then(response => response.json())
      .then(result => {
        if (result.success) {
          dispatch({
            type: ActionTypes.RESET_SUCCESS,
            msg: result.data
          })
          callback()
        } else {
          dispatch({
            type: ActionTypes.RESET_FAILURE,
            msg: result.msg
          })
        }
      })
      .catch(error => {
        dispatch({
          type: ActionTypes.RESET_FAILURE,
          error,
          msg: `Reset failed. API issue.`
        })
      })
  }
}

export function logout() {
  return dispatch => {
    auth.logout()

    // dispatch({
    //   type: ActionTypes.LOG_OUT,
    //   payload: null
    // })
  }
}

export function clearMsg(i) {
  return dispatch => {
    dispatch({
      type: ActionTypes.CLEAR_MSG,
      payload: i
    })
  }
}

export function clearAllMsg() {
  return dispatch => {
    dispatch({
      type: ActionTypes.CLEAR_ALL_MSG
    })
  }
}
