import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import {Alert} from 'react-bootstrap';

import toaster from 'toasted-notes' ;
import 'toasted-notes/src/styles.css';

import config from '../config';
import Auth from '../Auth/Auth';

import _map from 'lodash/map';
//import _filter from 'lodash/filter';
import _debounce from 'lodash/debounce';
import _template from 'lodash/template';

const currentConfig = config.development;
const auth = new Auth();

const optionChunkSize = 8

const endpoint = _template(`${currentConfig.serverURL}/Common/option/get?type=Airport&text=<%= keyword %>`);
const method = 'GET'

class AirportSelect extends Component {
	constructor(props) {
		super(props);

		this.onSelectAirport = this.onSelectAirport.bind(this);
		this.onSelectInputChange = this.onSelectInputChange.bind(this);
		this.onBlur = this.onBlur.bind(this);
		this.setPreValue = this.setPreValue.bind(this);

		this.state = {
			options: [],
			isLoading: false,
			page: 1,
			isMenuOpen: false,
			currentSelected: null,
			hasSelect: false//是否进行过选择(包括选择和取消选择)
		};
	}

	componentDidMount() {
		this.setPreValue()
	}

	componentDidUpdate(prevProps) {
		if (!this.state.hasSelect && prevProps.preValue !== this.props.preValue) {
			this.setPreValue()
		}
		if (!prevProps.options && this.props.options) {
			this.setLoading(false)
		}
	}

	getAirport(str) {
		return fetch(endpoint({ 'keyword': str }), {
			method: method,
			headers: {
				'Content-Type': 'application/json',
				'Token': auth.getAccessToken(),
				'RESULTSTRUCT': true
			}
		}).then(response => response.json())
	}

	onSelectInputChange = _debounce((str, action) => {
		const act = action.action
		if (act === "input-change") {
			if (str) {
				this.setState({
					isLoading: true
				})
				if (this.props.dataSourceKey === 'Airport') {
					this.getAirport(str).then((result) => {
							if (result.success) {
								this.setSelOptions(result.data);
							} else {
								this.setState({
									isLoading: false
								})

								toaster.notify(({ onClose }) => (
									<Alert
										variant="danger"
										className="alert-msg mb-0"
										dismissible
										onClose={onClose}
									>
										{result.msg}
									</Alert>
								), { duration: 3000 })
							}

						})
						.catch((error) => {
							//console.log('error: ', error)
							this.setState({
								isLoading: false
							})

							toaster.notify(({ onClose }) => (
								<Alert
									variant="danger"
									className="alert-msg mb-0"
									dismissible
									onClose={onClose}
								>
									Error
								</Alert>
							), { duration: 3000 })
						});
				}
			}
		}
	}, 1000)


	setSelOptions(data) {
		let options = _map(data, (r) => ({
			'value': r.key,
			'label': r.displayValue
		}));
		this.setState({
			options: options,
			isLoading: false,
			isMenuOpen: true,
			page: 1,
		});
	}

	setPreValue() {
		if (this.props.preValue) {
			if(this.props.isMulti){
				this.setState({
					currentSelected: _map(this.props.preValue, (r) =>({
						'value': r,
						'label': r
					}))
				})
				_map(this.props.preValue, (str) =>{
					this.getAirport(str).then((result) => {
						if (!this.state.hasSelect && result.success && result.data && result.data.length > 0) {
							this.setState({
								currentSelected: _map(this.state.currentSelected, (c) =>{
									if(c.value === result.data[0].key){
										return {
											'value': result.data[0].key,
											'label': result.data[0].displayValue
										}
									}else{
										return c
									}
								})
							})
						}
					})
				})
			}
			else{
				this.setState({
					currentSelected: {
						'value': this.props.preValue,
						'label': this.props.preValue
					}
				})
				this.getAirport(this.props.preValue).then((result) => {
					if (!this.state.hasSelect && result.success && result.data && result.data.length > 0) {
						this.setState({
							currentSelected: {
								'value': result.data[0].key,
								'label': result.data[0].displayValue
							}
						})
					}
				})
			}
		}
	}

	onSelectAirport(option) {
		let val;
		if (this.props.isMulti) {
			if (option) {
				val = _map(option, 'value')
			} else {
				val = []
			}
		}
		else {
			if (option) {
				val = option['value']
			} else {
				val = ''
			}
		}
		this.setState({
			hasSelect: true,
			currentSelected: option
		})
		this.props.handleSelect(val, this.props.name)
	}

	onBlur() {
		this.setState({
			isMenuOpen: false,
			page: 1,
			options: []
		})
	}

	setLoading(bool) {
		this.setState({
			isLoading: bool
		})
	}

	loadMoreOptions() {
		if ((this.state.page + 1) * optionChunkSize < this.state.options.length) {
			this.setLoading(true)
			setTimeout(function(){
				this.setState({
					page: this.state.page + 1
				})
				this.setLoading(false)
			}.bind(this), 500);
		}

	}

  render() {
		return (
			<div>
				<Select
					value={this.state.currentSelected}
					onChange={this.onSelectAirport}
					onInputChange={(string, action) => this.onSelectInputChange(string, action)}
					isLoading={this.state.isLoading}
					options={this.state.options.slice(0, this.state.page * optionChunkSize)}
					isOptionDisabled={option => option.value === "loading"}
					placeholder="Type and search"
					isMulti={this.props.isMulti}
					closeMenuOnSelect={false}
					isClearable={!this.props.isRequire}
					name={this.props.name}
					onMenuScrollToBottom={() => this.loadMoreOptions()}
					menuIsOpen={this.state.isMenuOpen}
					onBlur={this.onBlur}
					styles={{
					  control: (provided, state) => ({
					    ...provided,
					    borderColor: this.props.isInValid ? 'red' : '#ced4da',
							borderRadius: 0
				  })}}
				/>
			</div>
    );
  }
}

AirportSelect.propTypes = {
	name: PropTypes.string,
	isRequire: PropTypes.bool,
	isInValid: PropTypes.bool,
	handleSelect: PropTypes.func,
	dataSourceKey: PropTypes.string,
	isMulti: PropTypes.bool,
	preValue: PropTypes.any,
};

export default AirportSelect;
