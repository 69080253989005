import React, { Component } from 'react'
import { Router, Route, Switch, Redirect } from 'react-router-dom'
import { Provider } from 'react-redux'
import Auth from '../Auth/Auth'
import history from '../history'

import '../style/App.scss'
import '../style/style.scss'

import LoginPage from './LoginPage'
import MainMenu from './MainMenu'
import Dashboard from './Dashboard'
import Report from './Report'
import UserMaintenance from './UserMaintenance'
import RoleMaintenance from './RoleMaintenance'
import ClientMaintenance from './ClientMaintenance'
import ClientGroupMaintenance from './ClientGroupMaintenance'
import Schedule from './Schedule'
import Loading from '../components/Loading'
import PopupMsg from '../components/PopupMsg'

import store from '../store' // Import the store
import { getGlobalSettings } from '../actions/index'
import SSOCallback from '../components/SSOCallback'
import ErrorPage from './ErrorPage'

const auth = new Auth()

class App extends Component {
  constructor(props) {
    super(props)
    // this.onBurgerClick = this.onBurgerClick.bind(this);

    this.state = {
      isLogged: false,
      isLoading: false
    }
    getGlobalSettings(store)
  }

  componentDidMount() {
    // console.log('hasSession ?', auth.hasSession())

    if (auth.hasSession()) {
      // console.log('isExpired ?', auth.isExpired())
      if (auth.isExpired()) {
        this.setLoading(true)
        auth.renewSession(true)
      } else {
        this.setLogged()
      }
    } else {
      //console.log('no session')
    }
  }

  setLogged() {
    this.setState({
      isLogged: true,
      isLoading: false
    })
  }

  setLoading(bool) {
    this.setState({
      isLoading: bool
    })
  }

  render() {
    return (
      <Provider store={store}>
        <Router history={history}>
          <div className="app body-container">
            {this.state.isLogged ? (
              <div>
                <MainMenu currentPage={history.location.pathname} />
                <main>
                  <div className="container pt-4 relative">
                    <Switch>
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL}/`}
                        render={props => <Dashboard {...props} />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL}/report`}
                        render={props => <Report {...props} />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL}/schedule`}
                        render={props => <Schedule {...props} />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL}/user-maintenance`}
                        render={props =>
                          auth.isAdmin() ? <UserMaintenance {...props} /> : <Redirect to="report" />
                        }
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL}/role-maintenance`}
                        render={props =>
                          auth.isAdmin() ? (
                            <RoleMaintenance {...props} />
                          ) : (
                            <Redirect to="Report " />
                          )
                        }
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL}/client-maintenance`}
                        render={props =>
                          auth.isAdmin() ? (
                            <ClientMaintenance {...props} />
                          ) : (
                            <Redirect to="Report " />
                          )
                        }
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL}/client-group-maintenance`}
                        render={props =>
                          auth.isAdmin() ? (
                            <ClientGroupMaintenance {...props} />
                          ) : (
                            <Redirect to="Report " />
                          )
                        }
                      />
                      <Route component={Report} />
                    </Switch>
                  </div>
                </main>
              </div>
            ) : (
              <div>
                <Switch>
                  <Route
                    exact
                    path={`${process.env.PUBLIC_URL}/sso`}
                    render={props => <SSOCallback {...props} />}
                  />
                  <Route
                    exact
                    path={`${process.env.PUBLIC_URL}/error`}
                    render={props => <ErrorPage {...props} />}
                  />
                  <Route component={LoginPage} />
                </Switch>
              </div>
            )}
          </div>
          {this.state.isLoading && <Loading />}
          <PopupMsg />
        </Router>
      </Provider>
    )
  }
}

export default App
