import React from "react";

class ErrorPage extends React.Component {
  render() {
    return (
      <div>
        Login failed. Please contact the administrator.
      </div>
    );
  }
}

export default ErrorPage;
