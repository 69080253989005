import fetch from 'node-fetch';
import config from '../config';
import Auth from '../Auth/Auth';
import * as ActionTypes from '../constants/ActionTypes';

const currentConfig = config.development;
const auth = new Auth();

export function getUsers() {
  return (dispatch) => {
    const endpoint = `${currentConfig.serverURL}/Common/option/get?type=AdminUser`;

		dispatch({
			type: ActionTypes.GET_USERS_REQUEST,
		})

    fetch(endpoint, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
        'Token': auth.getAccessToken(),
        'RESULTSTRUCT': true
			}
		})
			.then(response => response.json())
			.then((result) => {
        dispatch({
          type: result.success ? ActionTypes.GET_USERS_SUCCESS : ActionTypes.GET_USERS_FAILURE,
          payload: result.data,
          msg: result.success ? result.msg : `Get users failed. <b>${result.msg}</b>`,
          result
        })
			})
			.catch((error) => {
				dispatch({
					type: ActionTypes.GET_USERS_FAILURE,
					payload: null,
          msg: `Get users failed. API issue.`,
          error
				})
			});
	};
}

export function getUserDetail(id) {
  return (dispatch) => {
    const endpoint = `${currentConfig.serverURL}/Admin/user/${id}`;

		dispatch({
			type: ActionTypes.GET_USER_DETAIL_REQUEST,
		})

    fetch(endpoint, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
        'Token': auth.getAccessToken(),
        'RESULTSTRUCT': true
			}
		})
			.then(response => response.json())
			.then((result) => {
        dispatch({
          type: result.success ? ActionTypes.GET_USER_DETAIL_SUCCESS : ActionTypes.GET_USER_DETAIL_FAILURE,
          payload: result.data,
          msg: result.success ? result.msg : `Get user detail failed. <b>${result.msg}</b>`,
          result
        })
			})
			.catch((error) => {
				dispatch({
					type: ActionTypes.GET_USER_DETAIL_FAILURE,
					error,
					payload: null,
          msg: `Get user detail failed. API issue.`
				})
			});
	};
}

export function editUser(form) {
  return (dispatch) => {
    const endpoint = `${currentConfig.serverURL}/Admin/user/edit`;
    const body = {
      "userId": form.userId,
      "email": form.email,
      "userName": form.userName,
      "userStatus": form.userStatus,
      "roles": form.roles,
      "groups": form.groups
    }

		dispatch({
			type: ActionTypes.EDIT_USER_REQUEST,
      body: body
		})

    fetch(endpoint, {
			method: 'PUT',
      body: JSON.stringify(body),
			headers: {
				'Content-Type': 'application/json',
        'Token': auth.getAccessToken(),
        'RESULTSTRUCT': true
			}
		})
			.then(response => response.json())
			.then((result) => {
        dispatch({
          type: result.success ? ActionTypes.EDIT_USER_SUCCESS : ActionTypes.EDIT_USER_FAILURE,
          payload: result.data,
          msg: result.success ? `Edit user <b>${form.email}</b> succeed.` : `Edit user failed. <b>${result.msg}</b>`,
          result
        })
			})
			.catch((error) => {
				dispatch({
					type: ActionTypes.EDIT_USER_FAILURE,
					error,
					payload: [],
          msg: `Edit user failed. API issue.`
				})
			});
	};
}

export function addNewUser(form) {
  return (dispatch) => {
    const endpoint = `${currentConfig.serverURL}/Admin/user/add`;
    const body = {
      "email": form.email,
      "userName": form.userName,
      "userStatus": form.userStatus,
      "roles": form.roles,
      "groups": form.groups
    }

		dispatch({
			type: ActionTypes.ADD_USER_REQUEST,
      body: body
		})

    fetch(endpoint, {
			method: 'POST',
      body: JSON.stringify(body),
			headers: {
				'Content-Type': 'application/json',
        'Token': auth.getAccessToken(),
        'RESULTSTRUCT': true
			}
		})
			.then(response => response.json())
			.then((result) => {
        dispatch({
          type: result.success ? ActionTypes.ADD_USER_SUCCESS : ActionTypes.ADD_USER_FAILURE,
          payload: result.data,
          msg: result.success ? `Add user <b>${form.email}</b> succeed.` : `Add user failed. <b>${result.msg}</b>`,
          result
        })
			})
			.catch((error) => {
				dispatch({
					type: ActionTypes.ADD_USER_FAILURE,
					error,
					payload: [],
          msg: `Add user failed. API issue.`
				})
			});
	};
}

export function clearUserDetail() {
  return (dispatch) => {
    dispatch({
			type: ActionTypes.CLEAR_USER_DETAIL,
		})
	};
}
