import * as ActionTypes from '../constants/ActionTypes';
import _pullAt from 'lodash/pullAt';
import _clone from 'lodash/clone';
import moment from 'moment';

const initialState = {
	msg: null,
	isError: false,
	timestamp: moment().valueOf()
};

const msg = (state = initialState, action) => {
	switch (action.type) {
	case ActionTypes.EDIT_USER_SUCCESS:
	case ActionTypes.EDIT_ROLE_SUCCESS:
	case ActionTypes.EDIT_CLIENT_SUCCESS:
	case ActionTypes.EDIT_SCHEDULE_SUCCESS:
	case ActionTypes.EDIT_CLIENT_GROUP_SUCCESS:
	case ActionTypes.ADD_SCHEDULE_SUCCESS:
	case ActionTypes.ADD_USER_SUCCESS:
	case ActionTypes.ADD_ROLE_SUCCESS:
	case ActionTypes.ADD_CLIENT_SUCCESS:
	case ActionTypes.ADD_CLIENT_GROUP_SUCCESS:
	case ActionTypes.FORGET_SUCCESS:
		return {
			msg: action.msg,
			isError: false,
			timestamp: moment().valueOf()
		};
	case ActionTypes.INIT_FAILURE:
	case ActionTypes.LOGIN_FAILURE:
	case ActionTypes.FORGET_FAILURE:
	case ActionTypes.RESET_FAILURE:
	case ActionTypes.GET_USERS_FAILURE:
	case ActionTypes.GET_ROLES_FAILURE:
	case ActionTypes.GET_GROUPS_FAILURE:
	case ActionTypes.GET_SOURCE_FAILURE:
	case ActionTypes.GET_USER_DETAIL_FAILURE:
	case ActionTypes.GET_ROLE_DETAIL_FAILURE:
	case ActionTypes.GET_CLIENT_NAME_FAILURE:
	case ActionTypes.GET_CLIENT_ID_FAILURE:
	case ActionTypes.GET_DEBTOR_CODE_FAILURE:
	case ActionTypes.EDIT_USER_FAILURE:
	case ActionTypes.EDIT_ROLE_FAILURE:
	case ActionTypes.ADD_USER_FAILURE:
	case ActionTypes.ADD_ROLE_FAILURE:
	case ActionTypes.ADD_CLIENT_FAILURE:
	case ActionTypes.SEARCH_CLIENT_FAILURE:
	case ActionTypes.GET_CLIENT_DETAIL_FAILURE:
	case ActionTypes.EDIT_CLIENT_FAILURE:
	case ActionTypes.RETRIVE_CLIENT_FAILURE:
	case ActionTypes.GET_ALL_CLIENT_GROUP_FAILURE:
	case ActionTypes.GET_REPORT_LIST_FAILURE:
	case ActionTypes.GET_REPORT_PARAM_FAILURE:
	case ActionTypes.GET_SECURITY_CLIENT_GROUP_FAILURE:
	case ActionTypes.GET_SECURITY_CLIENT_FAILURE:
	case ActionTypes.SEARCH_SCHEDULE_FAILURE:
	case ActionTypes.GET_SCHEDULE_DETAIL_FAILURE:
	case ActionTypes.GET_REPORT_VIEWER_FAILURE:
	case ActionTypes.GET_SCHEDULE_OPTIONS_FAILURE:
	case ActionTypes.EDIT_SCHEDULE_FAILURE:
	case ActionTypes.ADD_SCHEDULE_FAILURE:
	case ActionTypes.GET_CLIENT_GROUP_FAILURE:
	case ActionTypes.ADD_CLIENT_GROUP_FAILURE:
	case ActionTypes.EDIT_CLIENT_GROUP_FAILURE:
		return {
			msg: action.msg,
			isError: true,
			timestamp: moment().valueOf()
		};

	case ActionTypes.CLEAR_MSG:
		var clone = _clone(state.msg)
		_pullAt(clone, [action.payload])
		return Object.assign({}, state, {
			msg: clone
		});
	case ActionTypes.CLEAR_ALL_MSG:
		return Object.assign({}, state, {
			msg: null
		});
	default:
		return state;
	}
};

export default msg;
