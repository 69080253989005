import React from 'react';

const PwdRules = () => {
	return (
		<div style={{fontSize:'12px', color:'gray'}}>
		  	The password must:<br/>
			At least 7 characters<br/>
			(include one numeric, one lower &amp; upper case char)<br/>
			Password should not be reused
		</div>
  );
}

export default PwdRules;
