import {combineReducers} from 'redux';
// import * as ActionTypes from '../constants/ActionTypes';

// Import the actions types constant we defined in our actions
import loading from './loading';
import msg from './msg';
import common from './common';
import user from './user';
import role from './role';
import client from './client';
import clientgroup from './clientgroup';
import report from './report';
import schedule from './schedule';

const initialState = {
	// countryCodeList: [],
};

const index = (state = initialState, action) => {
	switch (action.type) {
	// case ActionTypes.GET_COUNTRY_SUCCESS:
	// 	return Object.assign({}, state, {
	// 		countryCodeList: action.payload
	// 	});
	default:
		return state;
	}
};

// Combine all the reducers
const rootReducer = combineReducers({
  index,
  loading,
	msg,
	user,
	role,
	common,
	client,
	clientgroup,
	report,
	schedule,
})

export default rootReducer;
