import * as ActionTypes from '../constants/ActionTypes';

const initialState = {
	pageLoading: false
};

const loading = (state = initialState, action) => {
	switch (action.type) {
	case ActionTypes.LOGIN_REQUEST:
	case ActionTypes.FORGET_REQUEST:
	case ActionTypes.RESET_REQUEST:
	case ActionTypes.INIT_REQUEST:
	case ActionTypes.GET_USER_DETAIL_REQUEST:
	case ActionTypes.GET_ROLE_DETAIL_REQUEST:
	case ActionTypes.EDIT_USER_REQUEST:
	case ActionTypes.EDIT_ROLE_REQUEST:
	case ActionTypes.ADD_USER_REQUEST:
	case ActionTypes.ADD_ROLE_REQUEST:
	case ActionTypes.SEARCH_CLIENT_REQUEST:
	case ActionTypes.GET_CLIENT_DETAIL_REQUEST:
	case ActionTypes.EDIT_CLIENT_REQUEST:
	case ActionTypes.ADD_CLIENT_REQUEST:
	case ActionTypes.RETRIVE_CLIENT_REQUEST:
	case ActionTypes.SEARCH_SCHEDULE_REQUEST:
	case ActionTypes.GET_SCHEDULE_DETAIL_REQUEST:
	case ActionTypes.GET_REPORT_VIEWER_REQUEST:
	case ActionTypes.EDIT_SCHEDULE_REQUEST:
	case ActionTypes.ADD_SCHEDULE_REQUEST:
	case ActionTypes.ADD_CLIENT_GROUP_REQUEST:
	case ActionTypes.EDIT_CLIENT_GROUP_REQUEST:
		return Object.assign({}, state, {
			pageLoading: true
		});
	case ActionTypes.INIT_SUCCESS:
	case ActionTypes.INIT_FAILURE:
	case ActionTypes.LOGIN_SUCCESS:
	case ActionTypes.LOGIN_FAILURE:
	case ActionTypes.FORGET_SUCCESS:
	case ActionTypes.FORGET_FAILURE:
	case ActionTypes.RESET_SUCCESS:
	case ActionTypes.RESET_FAILURE:
	case ActionTypes.GET_USER_DETAIL_SUCCESS:
	case ActionTypes.GET_USER_DETAIL_FAILURE:
	case ActionTypes.GET_ROLE_DETAIL_SUCCESS:
	case ActionTypes.GET_ROLE_DETAIL_FAILURE:
	case ActionTypes.EDIT_USER_SUCCESS:
	case ActionTypes.EDIT_USER_FAILURE:
	case ActionTypes.EDIT_ROLE_SUCCESS:
	case ActionTypes.EDIT_ROLE_FAILURE:
	case ActionTypes.ADD_USER_SUCCESS:
	case ActionTypes.ADD_USER_FAILURE:
	case ActionTypes.ADD_ROLE_SUCCESS:
	case ActionTypes.ADD_ROLE_FAILURE:
	case ActionTypes.SEARCH_CLIENT_SUCCESS:
	case ActionTypes.SEARCH_CLIENT_FAILURE:
	case ActionTypes.GET_CLIENT_DETAIL_SUCCESS:
	case ActionTypes.GET_CLIENT_DETAIL_FAILURE:
	case ActionTypes.EDIT_CLIENT_SUCCESS:
	case ActionTypes.EDIT_CLIENT_FAILURE:
	case ActionTypes.ADD_CLIENT_SUCCESS:
	case ActionTypes.ADD_CLIENT_FAILURE:
	case ActionTypes.RETRIVE_CLIENT_SUCCESS:
	case ActionTypes.RETRIVE_CLIENT_FAILURE:
	case ActionTypes.SEARCH_SCHEDULE_SUCCESS:
	case ActionTypes.SEARCH_SCHEDULE_FAILURE:
	case ActionTypes.GET_SCHEDULE_DETAIL_SUCCESS:
	case ActionTypes.GET_SCHEDULE_DETAIL_FAILURE:
	case ActionTypes.GET_REPORT_VIEWER_SUCCESS:
	case ActionTypes.GET_REPORT_VIEWER_FAILURE:
	case ActionTypes.EDIT_SCHEDULE_SUCCESS:
	case ActionTypes.EDIT_SCHEDULE_FAILURE:
	case ActionTypes.ADD_SCHEDULE_SUCCESS:
	case ActionTypes.ADD_SCHEDULE_FAILURE:
	case ActionTypes.EDIT_CLIENT_GROUP_SUCCESS:
	case ActionTypes.EDIT_CLIENT_GROUP_FAILURE:
	case ActionTypes.ADD_CLIENT_GROUP_SUCCESS:
	case ActionTypes.ADD_CLIENT_GROUP_FAILURE:
		return Object.assign({}, state, {
			pageLoading: false
		});
	default:
		return state;
	}
};

export default loading;
