import moment from 'moment';
import _toNumber from 'lodash/toNumber';

// Jun 05, 2019
export function prettySingleDate(date) {
  return moment(date).format("MMM DD, YYYY")
}

// Jun 05 - Jun 07, 2019
export function prettyDateRange(startDate, endDate) {
  return moment(startDate).format("MMM DD, YYYY")+ ' - '+ moment(endDate).format("MMM DD, YYYY")
}

// MAY 24, 2019 16:33:22
export function prettyDateTime(date) {
  return moment(date).format('DD MMM YYYY kk:mm');
}

// 2019-07-17
export function dateFormatToSend(date) {
  return moment(date).format("YYYY-MM-DD")
}

// 1/12/2019
export function slashDateFormat(date) {
  return moment(date).format('l')
}
// 1/12/2019
export function slashDateFormatToday() {
  return moment().format('l')
}

export function isExpired(date) {
  return moment(date).isBefore(moment(), 'day')
}

export function noOfDays(a, b) {
  var c = moment([
    _toNumber(dateFormatToSend(a).split('-')[0]),
    _toNumber(dateFormatToSend(a).split('-')[1]) - 1,
    _toNumber(dateFormatToSend(a).split('-')[2])
  ]);
  var d = moment([
    _toNumber(dateFormatToSend(b).split('-')[0]),
    _toNumber(dateFormatToSend(b).split('-')[1]) - 1,
    _toNumber(dateFormatToSend(b).split('-')[2])
  ]);
  const days = d.diff(c, 'days')
  return days
}

export function isDateFormat(date) {
  //数组类型的参数值不视为日期，目前都是多选框的结果
  return !Array.isArray(date) && moment(date).isValid()
}
