import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {Dropdown, Media, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faThLarge, faChartBar, faCog, faCalendarAlt, faSignOutAlt} from '@fortawesome/free-solid-svg-icons';
import Auth from '../Auth/Auth';
import history from '../history';
import config from '../config';

import Loading from '../components/Loading';

import _findIndex from 'lodash/findIndex';
import _trimEnd from 'lodash/trimEnd';

const auth = new Auth();

const navInfo = [
	{name: 'Dashboard', navName: 'dashboard', linkTo: process.env.PUBLIC_URL, icon: faThLarge},
	{name: 'Report', navName: 'report', linkTo: `${process.env.PUBLIC_URL}/report`, icon: faChartBar},
	{name: 'Admin', navName: 'admin', icon: faCog,
		subMenu: [
			{
				name: 'User maintenance',
				navName: 'user-maintenance',
				linkTo: `${process.env.PUBLIC_URL}/user-maintenance`
			},
			{
				name: 'Role maintenance',
				navName: 'role-maintenance',
				linkTo: `${process.env.PUBLIC_URL}/role-maintenance`
			},
			{
				name: 'Client maintenance',
				navName: 'client-maintenance',
				linkTo: `${process.env.PUBLIC_URL}/client-maintenance`
			},
			{
				name: 'Client group maintenance',
				navName: 'client-group-maintenance',
				linkTo: `${process.env.PUBLIC_URL}/client-group-maintenance`
			}
		]
	},
	{name: 'Schedule', navName: 'schedule', linkTo: `${process.env.PUBLIC_URL}/schedule`, icon: faCalendarAlt},
]

class MainMenu extends Component {
	constructor(props) {
		super(props);
		this.logout = this.logout.bind(this);
		this.onMenuClick = this.onMenuClick.bind(this);

		this.state = {
			currentNav: '',
		}
	}

	componentDidMount() {
		this.checkCurrentNav();
		//禁用Dashboard时, 登陆后默认显示Report的界面.
		if(_trimEnd(this.props.currentPage,'/') === _trimEnd(navInfo[0].linkTo,'/') && !this.props.globalSettings.enableDashboard){
			history.push(navInfo[1].linkTo)
		}

		// get profile every 10min
			window.setInterval(() => {
				auth.renewSession();
			}, 600000);
	}

	componentDidUpdate(prevProps, prevState) {
		if(prevProps.currentPage !== this.props.currentPage) {
			this.checkCurrentNav();
		}
	}

	onMenuClick(linkTo) {
		this.setState({
			currentNav: linkTo
		})
	}


	logout() {
		auth.logout();
	}

	checkCurrentNav() {
		this.setState({
			currentNav: this.props.currentPage
		})
	}

	render() {
		return (
			<div>
				<nav className="nav top_nav">
					<div className="navbar-brand">
						<OverlayTrigger
							key="main-right"
							placement="bottom"
							overlay={
								<Tooltip id="main-tooltip-right">
									<div>Web version: {config.version}</div>
									<div>Api version: {auth.getApiVersion()}</div>
								</Tooltip>
							}
						>
							<span>
								{this.props.globalSettings.headerLogo ? <img style={{display:'inline-block', height:'52px'}} src={process.env.PUBLIC_URL + "/images/" + this.props.globalSettings.headerLogo} alt="logo" /> : null}
								<p style={{display:'inline-block'}} className="white company-font pt-2 mb-0">
									<span style={{cursor:'pointer'}} onClick={()=>{window.location.href=process.env.PUBLIC_URL +"/"}}>{this.props.globalSettings.headerTitle}</span>
								</p>
							</span>
						</OverlayTrigger>
					</div>

					{navInfo.map((nav, i) => ((nav.name === 'Dashboard' && !this.props.globalSettings.enableDashboard) || 
					(nav.name === 'Schedule' && !this.props.globalSettings.enableSchedule) ||
					(nav.name === 'Admin' && !auth.isAdmin())) ? null :
						(nav.subMenu ?
							<Dropdown className={`nav-item ${_findIndex(nav.subMenu, {'linkTo': this.state.currentNav}) > -1 ? 'active' : ''}`} key={i}>
							  <Dropdown.Toggle className="nav-link" variant="none">
									<div><FontAwesomeIcon icon={nav.icon} /></div>
									{nav.name}
							  </Dropdown.Toggle>
							  <Dropdown.Menu>
									{nav.subMenu.map((sub, i) =>
										<Dropdown.Item as="div" className={`${this.state.currentNav === sub.linkTo ? 'active' : ''}`} key={i}>
											<Link
												className={`dropdown-item ${this.state.currentNav === sub.linkTo ? 'active' : ''}`}
												to={sub.linkTo}
												onClick={() => this.onMenuClick(sub.linkTo)}
												key={i}
											>
												{sub.name}
											</Link>
										</Dropdown.Item>

									)}
							  </Dropdown.Menu>
							</Dropdown> :
							<div className={`nav-item ${this.state.currentNav === nav.linkTo ? 'active' : ''}`} key={i}>
								<Link
									className="nav-link"
									to={nav.linkTo}
									onClick={() => this.onMenuClick(nav.linkTo)}
								>
									<div><FontAwesomeIcon icon={nav.icon} /></div>
									{nav.name}
								</Link>
							</div>
						)
					)}

					<Dropdown alignRight className="nav-item ml-auto">
						<Dropdown.Toggle className="nav-link" variant="none">
							<div>
								<img className="rounded-circle name" src={`https://ui-avatars.com/api/?name=${auth.getName()}`} alt={auth.getName()} />
							</div>
							{auth.getName()}
						</Dropdown.Toggle>
						<Dropdown.Menu>
							<div className="px-4 py-2">{auth.getEmail()}</div>
							<Dropdown.Divider />
							<button className="dropdown-item" onClick={() => this.logout()}>
								<Media>
									<FontAwesomeIcon icon={faSignOutAlt} className="mr-2 mt-1" />
									<div className="text-left">Logout</div>
								</Media>
							</button>
						</Dropdown.Menu>
					</Dropdown>
				</nav>

				{this.props.isLoading && <Loading />}
			</div>
		);
	}
}


MainMenu.propTypes = {
	currentPage: PropTypes.string,
  isError: PropTypes.bool,
};

function mapStateToProps(state) {
	return {
    isError: state.msg.isError,
    isLoading: state.loading.pageLoading,
		globalSettings: state.common.globalSettings
	}
}

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(MainMenu);
